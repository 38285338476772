import React, {Component} from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from "../../../components/UI/Button/Button";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from '@material-ui/data-grid';
import Snackbar from "../../../components/UI/Snackbar/Snackbar";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import classes from "../GetDevices.css";
import Icon from "../../../components/UI/CustomIcon/CustomIcon";
import instance from "../../../axios-spc";
import Modal from "../../../components/UI/Modal/Modal";
import {TextField} from "@material-ui/core";

class DevicesPending extends Component{


    state = {
        devices: [],
        bbkPackages: [],
        headers: [
            {field: 'data_acquisto', headerName: 'Data Acquisto', flex: 1},
            // {field: 'foto', headerName: 'Foto', flex: 1},
            {field: 'id', headerName: 'S.N.', flex: 1},
            {field: 'brand_name', headerName: 'Valore', flex: 1},
            {field: 'model_name', headerName: 'Modello', flex: 1},
            {field: 'memory_gb', headerName: 'Memoria', flex: 1},
            {field: 'valore_acquisto', headerName: 'Valore', flex: 1},
            {field: 'negozio_acquisto', headerName: 'Negozio Acquisto', flex: 1},
            {field: 'stato_device', headerName: 'Stato', flex: 1},
            {field: 'id_stato_device', headerName: 'ID Stato', flex: 1, hide: true},
            {
                field: 'doc',
                headerName: 'Dettagli',
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.openModal(thisRow["id"]);
                    };

                    return <Button clicked={onClick}>Apri</Button>;
                }
            },
            {
                field: 'bb',
                headerName: 'Azione',
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    let disabled = false;
                    let disabledValutazione = false;
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.setDeviceArrivato(thisRow["id"]);
                    };
                    const onValutazione = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        // return this.valutaDevice(thisRow["id"]);
                        return this.openModalValutazione(thisRow["id"]);
                    };
                    disabled = params.getValue("id_stato_device") !== 3;
                    disabledValutazione = params.getValue("id_stato_device") !== 4;
                    if (params.getValue("id_stato_device") <= 3) {
                        return <Button clicked={onClick} disabled={disabled}>Arrivato</Button>;
                    } else {
                        return <Button clicked={onValutazione} disabled={disabledValutazione}>Valuta</Button>;
                    }

                }
            },
        ],
        pageSize: 5,
        idDeviceDettaglio: null,
        visualizzaDocumento: false,
        confirmBuyBack: false,
        isRichiestaBuyBack: false,
        isValutazioneDevice: false,
        valoreDevice: 0,
        noteDevice: null,
        confirm: false,
        confirmValutazione: false

    };


    componentDidMount() {
       this.getDevices();
    }

    getDevices = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio") == 0 ? null : localStorage.getItem("idNegozio"),
            active_status: 1
        };

        instance.post('/getDevice', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].serial_number;
                resp.data[i].data_acquisto = new Date((resp.data[i].data_acquisto)).toLocaleDateString();
            }
            this.setState({devices: resp.data})
        });
    }


    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({confirm: false, confirmValutazione: false});
    };

    handleToogle() {
        let expanded = !this.state.expandedAccordion;
        if (expanded)  this.getDevices();
        this.setState({expandedAccordion: expanded})
    }

    openModalValutazione = (id) => {
        this.setState({isValutazioneDevice: true, idDeviceDettaglio: id})
    };

    valutaDevice = (id) => {
        var postData = {
            serial_number: this.state.idDeviceDettaglio,
            id_operatore: localStorage.getItem("userId"),
            num_times: 1,
            valore_accettazione: this.state.valoreDevice,
            note_accettazione: this.state.noteDevice
        };
        instance.post('/setStatusValutato', postData).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmValutazione: true});
                this.ricercaCancelHandler();
                this.getDevices();
            }
        });
    };

    setDeviceArrivato = (id) => {
        var postData = {
            serial_number: id,
            id_operatore: localStorage.getItem("userId"),
            num_times: 1
        };
        instance.post('/setStatusArrivato', postData).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirm: true});
                this.getDevices();
            }
        });
    };

    inputonChangeHandler = (event, id) => {
        this.setState({valoreDevice: event.target.value});
    };
    inputonChangeNoteHandler = (event, id) => {
        this.setState({noteDevice: event.target.value});
    };
    ricercaCancelHandler = () => {
        this.setState({isValutazioneDevice: false, valoreDevice: 0, noteDevice: null})
    };

    render() {

        let modal = null;

        if (this.state.isValutazioneDevice) {
            modal = (
                <Modal show={this.state.isValutazioneDevice} modalClosed={this.ricercaCancelHandler} title={"Valutazione Device"}>
                    <TextField
                        id="valutazione_device"
                        label="Inserisci valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        value={this.state.valoreDevice}
                        onChange={(e) => this.inputonChangeHandler(e, "valoreDevice")}
                    />
                    <TextField
                        id="valutazione_device"
                        label="Inserisci valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        fullWidth
                        value={this.state.noteDevice}
                        onChange={(e) => this.inputonChangeNoteHandler(e, "noteDevice")}
                    />
                    <br />
                    <Button clicked={this.valutaDevice}>Valuta Device</Button>
                </Modal>
            )
        }

        let confirmAlert = (
            <Snackbar open={this.state.confirm} closed={this.handleClose} severity="success">Device arrivato</Snackbar>
        );

        let sped = (
            <div style={{height: 'auto', width: '100%'}} >
                <DataGrid autoHeight={true} rows={this.state.devices} columns={this.state.headers} pageSize={5} />
            </div>
        );

        return (
            <Auxiliary>
                {confirmAlert}
                {modal}
                <Accordion >
                    <AccordionSummary className={classes.AccordionIcon} onClick={() => this.handleToogle()} expandIcon={this.state.expandedAccordion ? <Icon image="accordion_aperto"/> : <Icon image="accordion_chiuso"/>} style={{textAlign: 'center', display: 'inline-flex'}}>
                        <h2 className={classes.Title}>DEVICES IN ARRIVO</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        {sped}
                    </AccordionDetails>
                </Accordion>
            </Auxiliary>
        );
    }
}



export default (DevicesPending);

import React, {Component} from "react";
import classes from './Bolli.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import {GridApi, GridCellValue} from "@material-ui/data-grid";
import Button from '../../components/UI/Button/Button'
import instance from "../../axios-spc";
import TextField from "@material-ui/core/TextField";

class Bolli extends Component{

    state = {
        bolli: null,
        dataDa: null,
        dataA: null,
        headers: [
            {field: 'id', headerName: 'Id', flex: 1, hide: true},
            {field: 'ragione_sociale', headerName: 'Ragione Sociale', flex: 1},
            {field: 'negozio', headerName: 'Negozio', flex: 1.5},
            {field: 'tipo_distinta', headerName: 'Tipo', flex: 0.5},
            {field: 'data_creazione', headerName: 'Data Creazione', flex: 0.7},
            {field: 'data_pagamento', headerName: 'Data Pagamento', flex: 0.7},
            {field: 'valore', headerName: 'Valore', flex: 0.5},
            {field: 'pagata', headerName: 'Pagata', flex: 0.5},
            {
                field: '',
                headerName: 'Azione',
                sortable: false,
                flex: 0.7,
                renderCell: (params) => {
                    let disabled = false;
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.openModal(thisRow["id"]);
                    };
                    disabled = params.getValue("pagata") == 1;
                    return <Button clicked={onClick} disabled={disabled}>Paga</Button>;
                }
            },
        ]
    };

    componentDidMount() {
        this.setState({dataDa: this.formatDate(new Date().getTime() - 1000*60*60*24), dataA: this.formatDate(new Date())})
    }

    openModal = (id) => {
        this.setState({visualizzaScheda: true, idDistintaDettaglio: id});
    };

    getBolli = () =>  {
        var postData = {
            date_start: this.state.dataDa,
            date_end: this.state.dataA,
        };

        instance.post('/getBolli', postData).then(resp => {
        // axios.post('http://localhost:8989/api/getBolli', postData, axiosConfig).then(resp => {
            this.setState({bolli: resp.data[0]})
        });
    };


    formatDate(value) {
        if (value) {
            let date = new Date(value);
            const day = date.toLocaleString('default', {day: '2-digit'});
            const month = date.toLocaleString('default', {month: '2-digit'});
            const year = date.toLocaleString('default', {year: 'numeric'});
            return year + '-' + month + '-' + day ;
        }
    }

    onChangeDateDa = (event) => {
        this.setState({dataDa: event.target.value});
    };
    onChangeDateA = (event) => {
        this.setState({dataA: event.target.value});
    };

    render() {

        let schedaCompleta = null;

        let authRedirect = null;
        console.log('[Distinte] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }

        let bolli = null;
        if (this.state.bolli) {
            console.log(this.state.bolli);
            bolli = (
                <div>NUMERO BOLLI {this.state.bolli.numero_bolli}, PER UN VALORE TOTALE DI € {this.state.bolli.valore_bolli}</div>
            )
        }
        return (
            <div className={classes.Bolli}>
                {authRedirect}
                {schedaCompleta}
                <h1 className={classes.Title}>Bolli</h1>
                <div style={{height: 'auto', width: '100%'}} >
                    <TextField
                        id="date"
                        label="Data partenza"
                        type="date"
                        defaultValue={this.formatDate(new Date().getTime() - 1000*60*60*24)}
                        value={this.state.dataDa}
                        onChange={this.onChangeDateDa}
                        style = {{width: 150, marginRight: 25}}
                    />
                    <TextField
                        id="date"
                        label="Data arrivo"
                        type="date"
                        defaultValue={this.formatDate(new Date())}
                        value={this.state.dataA}
                        onChange={this.onChangeDateA}
                        style = {{width: 150, marginLeft: 25}}/>
                    <Button clicked={this.getBolli} style={{marginLeft: 50, marginTop: '1%', marginBottom: 50}}>Visualizza bolli</Button>
                    {bolli}
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/bolli"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Bolli);

import React, {Component} from "react";
import classes from './Packaging.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from "@material-ui/data-grid";
import Button from '../../components/UI/Button/Button'
import instance from "../../axios-spc";

class PackagingLogistica extends Component{

    state = {
        richieste: [
        ],
        pageSize: 5,
        visualizzaScheda: false,
        idDistintaDettaglio: null,
        headers: [
            {field: 'id', headerName: 'Id', flex: 1, hide: true},
            {field: 'id_negozio', headerName: 'Id Negozio', flex: 1, hide: true},
            {field: 'ragione_sociale', headerName: 'Ragione Sociale', flex: 1},
            {field: 'negozio', headerName: 'Negozio', flex: 1.5},
            {field: 'operatore', headerName: 'Operatore', flex: 1},
            {field: 'data_richiesta', headerName: 'Data Richiesta', flex: 0.7},
            {field: 'data_evasione', headerName: 'Data Evasione', flex: 0.7},
            {field: 'is_evasa', headerName: 'Evasa', flex: 0.5},
            {
                field: '',
                headerName: 'Azione',
                sortable: false,
                flex: 0.7,
                renderCell: (params) => {
                    let disabled = false;
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.evadiRichiestaPack(thisRow["id_negozio"]);
                    };
                    disabled = params.getValue("is_evasa") == "Si";
                    return <Button clicked={onClick} disabled={disabled}>Evadi</Button>;
                }
            },
        ]
    };

    componentDidMount() {
        this.getPack();
    }
    getPack = () =>  {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio") == 0 ? null : localStorage.getItem("idNegozio"),
        };

        instance.post('/getPackaging', postData).then(resp => {
        // axios.post('http://localhost:8989/api/getPackaging', postData, axiosConfig).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = i;
                resp.data[i].is_evasa = resp.data[i].is_evasa === 0 ? "No" : "Si";

            }
            this.setState({richieste: resp.data})
        });
    };

    evadiRichiestaPack = (id) => {
        var postData = {
            id_negozio: id,
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/setPackaging', postData)
        // axios.post('http://localhost:8989/api/setPackaging', postData, axiosConfig)
            .then(response => {
                if (response.data[0].ret_code === 0) {
                    this.getPack();
                } else {
                    this.setState({error: true})
                }
            });
    };


    formatDate(value) {
        if (value) {
            let date = new Date(value);
            const day = date.toLocaleString('default', {day: '2-digit'});
            const month = date.toLocaleString('default', {month: '2-digit'});
            const year = date.toLocaleString('default', {year: 'numeric'});
            return day + '-' + month + '-' + year;
        }
    }

    visualizzaSchedaCancelHandler = () => {
        this.setState({visualizzaScheda: false, idDistintaDettaglio: null})
    };

    render() {

        let schedaCompleta = null;

        let authRedirect = null;
        console.log('[Distinte] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }
        return (
            <div className={classes.Packaging}>
                {authRedirect}
                {schedaCompleta}
                <h1 className={classes.Title}>RICHIESTE PACKAGING</h1>
                <div style={{height: 'auto', width: '70%'}} >
                    <DataGrid autoHeight={true} rows={this.state.richieste} columns={this.state.headers} pageSize={5} hideFooterSelectedRowCount={true} components={{Toolbar: GridToolbar}}/>
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/packs"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(PackagingLogistica);

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGOUT = 'LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const CREDENZIALI_INSERITE = 'CREDENZIALI_INSERITE';

export const INSERIMENTO_PICEA_START = 'INSERIMENTO_PICEA_START';
export const INSERIMENTO_PICEA_OK = 'INSERIMENTO_PICEA_OK';
export const INSERIMENTO_PICEA_FAIL = 'INSERIMENTO_PICEA_FAIL';
export const INSERIMENTO_PICEA_RESET = 'INSERIMENTO_PICEA_RESET';

import axios from "axios";

const instance = axios.create({
    baseURL: 'https://econet-coll.ecostore.it/spc/spcBack/api',
    headers: {
        'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8'
    }
});

export default instance;

import React, {Component} from "react";
import classes from './Ricevute.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from "@material-ui/data-grid";
import IMAGES from '../../store/images'
import Button from '../../components/UI/Button/Button'
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import instance from "../../axios-spc";

class Ricevute extends Component{

    componentDidMount() {
        this.props.onAuthCheckState();
        if (localStorage.getItem("role") == 2) {
            this.getRicevuteMaster()
        } else {
            this.getRicevute();
        }
    }

    state = {
        ricevute: [
        ],
        headers: [
            {field: 'codice_pratica', headerName: 'Codice', flex: 1},
            {field: 'oggetto', headerName: 'Oggetto', flex: 1},
            {field: 'prezzo_acquisto', headerName: 'Prezzo', flex: 1},
            {field: 'data_acquisto', headerName: 'Data Acquisto', flex: 1},
            {field: 'nome_cedente', headerName: 'Anagrafica', flex: 1},
            {field: 'codice_fiscale_cedente', headerName: 'C.F.', flex: 1},
            {field: 'telefono_cedente', headerName: 'Cell.', flex: 1},
            {field: 'email_cedente', headerName: 'Mail', flex: 1},
            {
                field: 'doc',
                headerName: 'Doc. Completo',
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.openModal(thisRow["codice_pratica"]);
                    };

                    return <Button clicked={onClick}>Apri</Button>;
                }
            },
        ],
        pageSize: 5,
        idRicevutaDettaglio: null,
        visualizzaRicevuta: false
    };

    getRicevute = () => {
        // chiamata a picea
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/getRicevuta', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].codice_pratica;
                resp.data[i].data_acquisto = this.formatDate(resp.data[i].data_acquisto);
            }

            this.setState({ricevute: resp.data})
        });
    }
    getRicevuteMaster = () => {
        // chiamata a picea
        var postData = {
            id_affiliato: localStorage.getItem("idAffiliato"),
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/getRicevuteMaster', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].codice_pratica;
                resp.data[i].data_acquisto = this.formatDate(resp.data[i].data_acquisto);
            }

            this.setState({ricevute: resp.data})
        });
    }

    openModal = (id) => {
        this.setState({visualizzaRicevuta: true, idRicevutaDettaglio: id});
        window.open("https://www.smartphonecash.it/stampaRicevuta?cod=" + id);
        // window.open("http://localhost:3000/stampaRicevuta?cod=" + id);
    };

    visualizzaSchedaCancelHandler = () => {
        this.setState({visualizzaRicevuta: false, idRicevutaDettaglio: null})
    };

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    render() {

        const NewToolbar = () => {
            return (
                <React.Fragment>
                    <GridToolbar style={{display: "inline-block", float: "left"}}/>
                    <a href={IMAGES.docCompleto} download style={{color: '#768828', float: 'right', margin: 10, display: 'inline-block', textDecoration: 'none' }}>DOWNLOAD MODELLLO RICEVUTA</a>
                </React.Fragment>
            );
        };

        let authRedirect = null;
        console.log('[Ricevute] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }
        return (
            <Auxiliary>
                <div className={classes.Ricevute}>
                    {authRedirect}
                    <h1 className={classes.Title}>RICEVUTE</h1>
                    <div style={{height: 'auto', width: '100%'}} >
                        <DataGrid autoHeight={true} rows={this.state.ricevute} columns={this.state.headers} pageSize={5} rowsPerPageOptions={[5, 10, 20, 50]} hideFooterSelectedRowCount={true} components={{Toolbar: NewToolbar}}/>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/ricevute"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Ricevute);

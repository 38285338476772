import React, {Component} from "react";
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import instance from "../../axios-spc";
import {TextField, InputLabel} from "@material-ui/core";
import Button from '../../components/UI/Button/Button'
import MenuItem from "@material-ui/core/MenuItem";
import classes from "../Auth/Auth.css";
import ReCAPTCHA from "react-google-recaptcha";
import Snackbar from "../../components/UI/Snackbar/Snackbar";

class FormContatto extends Component {

    state = {
        isFormContattoVisibile: false,
        formContatto: {
            nome: {
                elementType: 'input',
                elementConfig: {
                    type: 'input',
                    placeholder: 'Nome e cognome',
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'input',
                    placeholder: 'Mail',
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            telefono_cellulare: {
                elementType: 'input',
                elementConfig: {
                    type: 'input',
                    placeholder: 'Telefono',
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            messaggio: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'textarea',
                    placeholder: 'Scrivi qui il tuo messaggio (max 4000 caratteri)',
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            }
        },
        isRecaptchaValid: false,
        tipo_attivita: {
            options: [],
        },
        isFormContattoInviato: false
    };

    componentDidMount() {
        // this.props.onAuthCheckState();
        this.getListaTipoAttivita();
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({isFormContattoInviato: false})
    };

    formChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.formContatto
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedForm[inputIdentifier] = updatedFormElement;

        this.setState({formContatto: updatedForm})
    };

    sendFormContatto = () => {
        let postData = {
            id_contatto: null,
            riferimento: this.state.formContatto.nome.value,
            email: this.state.formContatto.email.value,
            telefono: this.state.formContatto.telefono_cellulare.value,
            id_tipo_attivita: this.state.tipo_attivita.value,
            note_contatto: this.state.formContatto.messaggio.value,
            id_stato_contatto: null,
            id_operatore: localStorage.getItem("userId"),
            id_affiliato: null,
            note_operatore: null
        };
        instance.post('/setFormContatto', postData).then( (resposne) => {
        // axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/setFormContatto', postData, axiosConfig).then( (resposne) => {
            console.log(resposne.data);
            this.setState({isFormContattoVisibile: false, isFormContattoInviato: true})
        });
    };

    tipoAttivitaListChangedHandler = (event, id) => {
        const updatedForm = {
            ...this.state.tipo_attivita
        };
        updatedForm.value = event.target.value;
        this.setState({tipo_attivita: updatedForm});
    };

    setRecaptha = (value) => {
        console.log(value);
        let postData = {
            response: value
        };
        instance.post('/recaptcha', postData).then( (resposne) => {
            console.log(resposne.data);
            let googleResponse = JSON.parse(resposne.data);
            if (googleResponse.success) {
                this.setState({isRecaptchaValid: true});
            } else {
                this.setState({isRecaptchaValid: false});
            }

        });
    };

    getListaTipoAttivita = () => {
        let postData = {
            dummy: null
        };
        instance.post('/getFormContattoTipoAttivita', postData)
            .then(response => {
                const updatedForm = {
                    ...this.state.tipo_attivita
                };
                let newOption = response.data;
                updatedForm.options = newOption;
                this.setState({tipo_attivita: updatedForm})
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {

        let recaptchaButton = null;
        let inputFields = null;
        let formContatto = null;

        const formElementArray = [];
        for (let key in this.state.formContatto) {
            formElementArray.push({
                id: key,
                config: this.state.formContatto[key]
            })
        }

        inputFields = formElementArray.map(el => (
            <Auxiliary>
                <b><label style={{color: "#768828"}}>{el.id.toString().toLocaleUpperCase().replace("_", " ")}</label></b>
                <br />
                <TextField
                    name={el.id}
                    key={el.id}
                    label={el.config.elementConfig.placeholder}
                    variant="outlined"
                    value={el.config.value}
                    // fullWidth
                    type={el.id == "textarea" ? "textarea" : "input" }
                    style={{width: '50%', align: 'center', margin: 10 }}
                    error={!el.config.valid && el.config.touched}
                    helperText={el.config.validationError}
                    touched={el.config.touched}
                    onChange={(e, id) => this.formChangedHandler(e, el.id)}
                />
                <br />
            </Auxiliary>
        ));

        let formTipoAttivita = (
            <Auxiliary>
                <b><label style={{color: "#768828"}}>TIPO ATTIVITÀ</label></b>
                <br />
                <TextField
                    id="tipoAttivita"
                    select
                    variant="outlined"
                    elementType="select"
                    value={this.state.tipo_attivita.value}
                    label="Tipo Attività"
                    style={{width: '50%', align: 'center', margin: 10 }}
                    onChange={(e) => this.tipoAttivitaListChangedHandler(e, "tipoAttivita")}
                >
                    {this.state.tipo_attivita.options.map((option) => (
                        <MenuItem key={option.id_tipo_attivita} value={option.id_tipo_attivita}>
                            {option.attivita}
                        </MenuItem>
                    ))}
                </TextField>
                <br />
            </Auxiliary>
        );


        formContatto = (
            <Auxiliary>
                {inputFields}
                {formTipoAttivita}
            </Auxiliary>
        );

        if (!this.state.isRecaptchaValid) {
            recaptchaButton = (<div className={classes.Captcha}><ReCAPTCHA sitekey="6Ld9tncbAAAAALpHEnWeCmvKXpWpLVEEEP5aS8Qk" onChange={this.setRecaptha}/></div>);
        } else {
            recaptchaButton = (<div><Button clicked={this.sendFormContatto}>Invia</Button></div>);
        }

        let formInviato = null;

        if (this.state.isFormContattoInviato) {
            formInviato = (<Snackbar open={true} closed={this.handleClose} severity="success">Messaggio inviato
                correttamente!</Snackbar>);
        }

        return (
            <form id="recapthca">
                {formContatto}
                {recaptchaButton}
                {formInviato}
            </form>
        )
    }
}

export default FormContatto;

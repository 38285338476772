import React, {Component} from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from "../../../components/UI/Button/Button";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from '@material-ui/data-grid';
import Snackbar from "../../../components/UI/Snackbar/Snackbar";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import classes from "../GetDevices.css";
import Icon from "../../../components/UI/CustomIcon/CustomIcon";
import instance from "../../../axios-spc";
import Modal from "../../../components/UI/Modal/Modal";
import {TextField} from "@material-ui/core";

class DevicesLiquidati extends Component{


    state = {
        devices: [],
        bbkPackages: [],
        headers: [
            {field: 'data_acquisto', headerName: 'Data Acquisto', flex: 1},
            // {field: 'foto', headerName: 'Foto', flex: 1},
            {field: 'id', headerName: 'S.N.', flex: 1},
            {field: 'brand_name', headerName: 'Valore', flex: 1},
            {field: 'model_name', headerName: 'Modello', flex: 1},
            {field: 'memory_gb', headerName: 'Memoria', flex: 1},
            {field: 'valore_acquisto', headerName: 'Valore', flex: 1},
            {field: 'negozio_acquisto', headerName: 'Negozio Acquisto', flex: 1},
            {field: 'stato_device', headerName: 'Stato', flex: 1},
            {field: 'data_pagamento_rimborso', headerName: 'Data Rimborso', flex: 1}
        ],
        pageSize: 5,
        idDeviceDettaglio: null,
        visualizzaDocumento: false,
        confirmBuyBack: false,
        isRichiestaBuyBack: false,
        isValutazioneDevice: false,
        valoreDevice: 0,
        noteDevice: null,
        confirm: false,
        confirmValutazione: false

    };


    componentDidMount() {
        this.getDevices();
    }

    getDevices = () => {
        var postData = {
            // id_negozio: localStorage.getItem("idNegozio") == 0 || localStorage.getItem() ? null : localStorage.getItem("idNegozio"),
            id_negozio: localStorage.getItem("idNegozio") == 0 || localStorage.getItem("role") == 3 ? null : localStorage.getItem("idNegozio"),
            id_stato_device: 6
        };

        instance.post('/getDevice', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].serial_number;
                resp.data[i].data_acquisto = new Date((resp.data[i].data_acquisto)).toLocaleDateString();
                resp.data[i].data_pagamento_rimborso = new Date((resp.data[i].data_pagamento_rimborso)).toLocaleDateString();
            }
            this.setState({devices: resp.data})
        });
    };


    handleToogle() {
        let expanded = !this.state.expandedAccordion;
        if (expanded) {
            this.getDevices();
        }
        this.setState({expandedAccordion: expanded})
    }

    render() {

        let sped = (
            <div style={{height: 'auto', width: '100%'}} >
                <DataGrid autoHeight={true} hideFooterSelectedRowCount={true} rows={this.state.devices} columns={this.state.headers} pageSize={5} components={{Toolbar: GridToolbar}}/>
            </div>
        );

        return (
            <Auxiliary>
                <Accordion >
                    <AccordionSummary className={classes.AccordionIcon} onClick={() => this.handleToogle()} expandIcon={this.state.expandedAccordion ? <Icon image="accordion_aperto"/> : <Icon image="accordion_chiuso"/>} style={{textAlign: 'center', display: 'inline-flex'}}>
                        <h2 className={classes.Title}>DEVICES LIQUIDATI</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        {sped}
                    </AccordionDetails>
                </Accordion>
            </Auxiliary>
        );
    }
}



export default (DevicesLiquidati);

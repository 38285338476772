import React, {Component} from 'react';
import accordionApertoIcon from '../../../assets/icons/sc_icona_accordion_aperto.png';
import accordionChiusoIcon from '../../../assets/icons/sc_icona_accordion_chiuso.png';
import Icon from "@material-ui/core/Icon";


class CustomIcon extends Component {

    render() {

        let image = null;

        switch (this.props.image) {
            case "accordion_aperto" :
                image = <img src={accordionApertoIcon} style={{width: '100%', height: '100%'}}/>;
                break;
            case "accordion_chiuso" :
                image = <img src={accordionChiusoIcon} style={{width: '100%', height: '100%'}}/>;
                break;
            default:
                image = null
        }

        return (
            <Icon>
                {image}
            </Icon>
        );
    }

}


export default CustomIcon;

import React from "react";
import Alert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";

const snackbar = (props) => (
    <Snackbar open={props.open} onClose={props.closed} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
        <Alert onClose={props.closed} severity={props.severity}>
            {props.children}
        </Alert>
    </Snackbar>
);

export default snackbar;

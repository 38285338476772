import React from 'react';
import classes from './Modal.css';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary'
import Backdrop from '../../../components/UI/Backdrop/Backdrop'

const modal = (props) => (
    <Auxiliary>
        <Backdrop show={props.show} clicked={props.modalClosed}/>
        <div className={props.customStyle === "Materiale Packaging" ? classes.Packaging : props.customStyle === "Devices" ? classes.Devices : props.customStyle === "Creazione Anagrafica" ? classes.Anagrafica : classes.Modal} style={{
            transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: props.show ? '1' : '0'
        }}>
            <h1>{props.title === "Devices" ? "" : props.title}</h1>
            {props.children}
        </div>
    </Auxiliary>
);


export default modal;


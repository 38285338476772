import React from 'react';
import Badge from '@material-ui/core/Badge';
import classes from './Badge.css';
import ICONS from "../../../store/icons";

const badge = (props) => (
    <Badge className={classes.Badge} badgeContent={props.notifiche} onClick={props.clicked} color="primary" anchorOrigin={{vertical: 'top', horizontal: 'left'}}>
        {<img src={ICONS.notifiche} style={{width: "80%", height: "80%"}}  title={props.title}/>}
    </Badge>
);

export default badge;

import React, {Component} from "react";
import classes from './Packaging.css'
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import Button from '../../components/UI/Button/Button';
import Snackbar from '../../components/UI/Snackbar/Snackbar';
import instance from "../../axios-spc";

class Packaging extends Component{

    state = {
        dataRichiesta: null,
        operatoreRichiesta: null,
        isRichiestaPending: false,
        dataEvasione: null,
        loading: false,
        confirm: false,
        error: false
    };

    componentDidMount() {
        this.getUltimaRichiesta();
    }

    getUltimaRichiesta = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
        };

        instance.post('/getPackaging', postData).then(response => {
            // axios.post('http://localhost:8989/api/getPackaging', postData, axiosConfig).then(response => {
                if (response.data.length > 0) {
                    let isPending = response.data[0].is_evasa === 0;
                    this.setState({operatoreRichiesta: response.data[0].operatore, dataRichiesta: response.data[0].data_richiesta, isRichiestaPending: isPending, dataEvasione: response.data[0].data_evasione});
                }
            })
    };

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    richiediPackageHandler = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/setPackaging', postData).then(response => {
                if (response.data[0].ret_code === 0) {
                    this.setState({confirm: true});
                    this.getUltimaRichiesta();
                } else {
                    this.setState({error: true})
                }
            });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({confirm: false});
    };

    render() {

        let packInfo = null;

        if (this.state.dataRichiesta) {
            packInfo = (
                <Auxiliary>
                    <p><strong>Ultimo Pack Richiesto</strong></p>
                    <p>{this.formatDate(this.state.dataRichiesta)} da {this.state.operatoreRichiesta}</p>
                </Auxiliary>
            )
        } else {
            packInfo = (<p>Nessuna richiesta di packaging inviata</p>);
        }

        let button = null;
        if (!this.state.isRichiestaPending) {
            button = (
                <div>
                    <p><strong>Evaso in data {this.formatDate(this.state.dataEvasione)}</strong></p>
                    <Button clicked={this.richiediPackageHandler}>RICHIEDI NUOVO MATERIALE</Button>
                </div>
            )
        } else {
            button = <p>Hai già una richiesta di materiale in corso</p>;
        }

        let confirmAlert = (
            <Snackbar open={this.state.confirm} closed={this.handleClose} severity="success">Richiesta di materiale inviata correttamente!</Snackbar>
        );

        let errorAlert = (
            <Snackbar open={this.state.error} closed={this.handleClose} severity="danger">Errore nell'invio della richiesta</Snackbar>
        );

        return (
            <Auxiliary>
                {packInfo}
                {button}
                {confirmAlert}
                {errorAlert}
            </Auxiliary>
        );
    }
}


export default (Packaging);

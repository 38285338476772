import React, {Component} from "react";
import classes from './Coupons.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from "@material-ui/data-grid";
import Button from '../../components/UI/Button/Button'
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import instance from "../../axios-spc";
import Snackbar from "../../components/UI/Snackbar/Snackbar";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Icon from "../../components/UI/CustomIcon/CustomIcon";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';

class Coupons extends Component{

    state = {
        coupons: [
        ],
        headers: [
            {field: 'coupon_serial_number', headerName: 'S.N.', flex: 1},
            {field: 'negozio', headerName: 'Negozio', flex: 1.2},
            {field: 'cliente', headerName: 'Cliente', flex: 1.2},
            {field: 'telefono_cellulare', headerName: 'Tel.', flex: 0.6},
            {field: 'email', headerName: 'Email', flex: 0.6},
            {field: 'data_creazione', headerName: 'Creazione', flex: 0.8},
            {field: 'data_scadenza', headerName: 'Scadenza', flex: 0.8, hide: false},
            {field: 'data_utilizzo', headerName: 'Utilizzo', flex: 0.8, hide: false},
            {field: 'coupon_value', headerName: '€', flex: 0.5},
            {field: 'stato_coupon', headerName: 'Stato', flex: 0.8},
            {
                field: 'doc',
                headerName: 'Utilizza',
                sortable: false,
                flex: 0.7,
                renderCell: (params) => {
                    let disabled = false;
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.burnCoupon(thisRow["coupon_serial_number"]);
                    };
                    disabled = params.getValue("stato_coupon") !== 'VALIDO';
                    return <Button clicked={onClick} disabled={disabled}>Utilizza</Button>;
                }
            },
            {
                field: 'doc2',
                headerName: 'Visualizza',
                sortable: false,
                flex: 0.7,
                renderCell: (params) => {
                    let disabled = false;
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.openModal(thisRow["coupon_serial_number"]);
                    };
                    disabled = params.getValue("stato_coupon") !== 'VALIDO';
                    return <Button clicked={onClick} disabled={disabled}>Ristampa</Button>;
                }
            },
        ],
        pageSize: 5,
        idRicevutaDettaglio: null,
        visualizzaRicevuta: false,
        couponUtilizzato: null,
        confermaUtilizzo: null,
        expandedAccordion: false
    };

    componentDidMount() {
        this.props.onAuthCheckState();
        if (localStorage.getItem("role") == 2) {
            this.getCouponMaster()
        } else {
            this.getCoupon();
        }

    }

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    handleToogle() {
        let expanded = !this.state.expandedAccordion;
        if (localStorage.getItem("role") == 2) {
            this.getCouponMaster()
        } else {
            this.getCoupon();
        }
        this.setState({expandedAccordion: expanded})
    }

    getCoupon = () => {
        // chiamata a picea
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
        };
        instance.post('/getCoupon', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].coupon_serial_number;
                resp.data[i].data_scadenza = this.formatDate(resp.data[i].data_scadenza);
                resp.data[i].data_creazione = this.formatDate(resp.data[i].data_creazione);
                if (resp.data[i].data_utilizzo) resp.data[i].data_utilizzo = this.formatDate(resp.data[i].data_utilizzo)
                else resp.data[i].data_utilizzo = "Non utilizzato";
            }
            const oldHeaders = [...this.state.headers];
            this.setState({coupons: resp.data, headers: oldHeaders})
        });
    };
    getCouponMaster = () => {
        // chiamata a picea
        var postData = {
            id_affiliato: localStorage.getItem("idAffiliato"),
        };
        instance.post('/getCouponsMaster', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].coupon_serial_number;
                resp.data[i].data_scadenza = this.formatDate(resp.data[i].data_scadenza);
                resp.data[i].data_creazione = this.formatDate(resp.data[i].data_creazione);
                if (resp.data[i].data_utilizzo) resp.data[i].data_utilizzo = this.formatDate(resp.data[i].data_utilizzo)
                else resp.data[i].data_utilizzo = "Non utilizzato";
            }
            const oldHeaders = [...this.state.headers];
            this.setState({coupons: resp.data, headers: oldHeaders})
        });
    };

    burnCoupon = (id) => {
        // chiamata a picea
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            id_operatore: localStorage.getItem("userId"),
            coupon_serial_number: id
        };
        instance.post('/burnCoupon', postData).then(resp => {
            this.setState({couponUtilizzato: resp.data[0].ret_code, confermaUtilizzo: resp.data[0].ret_message});
            if (localStorage.getItem("role") == 2) {
                this.getCouponMaster()
            } else {
                this.getCoupon();
            }
        });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({couponUtilizzato: null, confermaUtilizzo: null})
    };

    openModal = (id) => {
        this.setState({visualizzaRicevuta: true, idRicevutaDettaglio: id});
        window.open("https://www.smartphonecash.it/stampaCoupon?sn=" + id);
    };

    visualizzaSchedaCancelHandler = () => {
        this.setState({visualizzaRicevuta: false, idRicevutaDettaglio: null})
    };


    render() {
        let confermaUtilizzoCoupon = null;
        if (this.state.couponUtilizzato === 0) {
            confermaUtilizzoCoupon = (
                <Snackbar open={true} closed={this.handleClose} severity="success">Coupon utilizzato con successo</Snackbar>
            )
        } else if (this.state.couponUtilizzato >= 1 && this.state.couponUtilizzato <= 5) {
            confermaUtilizzoCoupon = (
                <Snackbar open={true} closed={this.handleClose} severity="error">{this.state.confermaUtilizzo}</Snackbar>
            )
        }

        let authRedirect = null;
        console.log('[Coupon] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }

        let coupons = (
            <div style={{height: 'auto', width: '100%'}} >
                <DataGrid autoHeight={true} rows={this.state.coupons} columns={this.state.headers} pageSize={5} rowsPerPageOptions={[5, 10, 20, 50]} hideFooterSelectedRowCount={true} components={{Toolbar: GridToolbar}}/>
            </div>
        )

        return (
            <Auxiliary>
                {authRedirect}
                {confermaUtilizzoCoupon}
                <Accordion>
                    <AccordionSummary className={classes.AccordionIcon} onClick={() => this.handleToogle()} expandIcon={this.state.expandedAccordion ? <Icon image="accordion_aperto"/> : <Icon image="accordion_chiuso"/>} style={{textAlign: 'center', display: 'inline-flex'}}>
                        <h2 className={classes.Title}>LISTA COUPONS</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        {coupons}
                    </AccordionDetails>
                </Accordion>
            </Auxiliary>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/coupon"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Coupons);

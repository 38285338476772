import * as actionTypes from '../actions/actionTypes'
import {updateObject} from "../utility";

const initalState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    negozio: null,
    operatore: null
};

const authStart = (state, action) => {
    return updateObject(state, {error: null, loading: true})
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        userId: action.userId,
        error: null,
        loading: false,
    })
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
};

const logout = (state, action) => {
    return updateObject(state, {token:null, userId: null, negozio: null, operatore: null})
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {
        authRedirectPath: action.path
    })
};

const credenzialiInserite = (state, action) => {
    return updateObject(state, {
        negozio: action.negozio,
        operatore: action.operatore

    })
};

const reducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.LOGOUT: return logout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.CREDENZIALI_INSERITE: return credenzialiInserite(state, action);
        default: return state
    }
};

export default reducer;

import React, {Component} from "react";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary'
import queryString from "query-string";
import instance from "../../../axios-spc";
import classes from "../../TakePhoto/TakePhoto.css";
import IMAGES from "../../../store/images";

class Ddt extends Component {

    state = {
        spedizione: null
    };

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        let idSpedizione = params.id;
        var postData = {
            id_spedizione: idSpedizione

        };
        instance.post('/getDdt', postData)
        // axios.post('http://localhost:8989/api/getDdt', postData, axiosConfig)
            .then(response => {
                this.setState({spedizione: response.data[0]});
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    inserisciDatiDdt = () => {
        let doc = document.getElementById("ddt");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const mittente = svgElement.getElementById("mittente");
        const indirizzo = svgElement.getElementById("indirizzo");
        const cap = svgElement.getElementById("cap");
        const localita = svgElement.getElementById("localita");
        const provincia = svgElement.getElementById("provincia");
        const destinatario = svgElement.getElementById("destinatario");
        const indirizzo_dest = svgElement.getElementById("indirizzo_dest");
        const cap_dest = svgElement.getElementById("cap_dest");
        const localita_dest = svgElement.getElementById("localita_dest");
        const prov_dest = svgElement.getElementById("prov_dest");
        const colli = svgElement.getElementById("colli");
        const peso = svgElement.getElementById("peso");
        const volume = svgElement.getElementById("volume");
        const natura = svgElement.getElementById("natura");
        const id_sped = svgElement.getElementById("id_sped");
        const data_sped = svgElement.getElementById("data_sped");
        const note = svgElement.getElementById("note");
        const tel_seregno = svgElement.getElementById("tel_seregno");
        const mail_seregno = svgElement.getElementById("mail_seregno");
        const tel = svgElement.getElementById("tel");
        const mail = svgElement.getElementById("mail");

        // valore_buono_stampato.innerHTML = this.state.coupon.coupon_value + " €";
        mittente.innerHTML = this.state.spedizione.mittente_ragione_sociale;
        indirizzo.innerHTML = this.state.spedizione.mittente_indirizzo;
        cap.innerHTML = this.state.spedizione.mittente_cap.replace(/./g, '$&    ');
        localita.innerHTML = this.state.spedizione.mittente_citta;
        provincia.innerHTML = "(" + this.state.spedizione.mittente_provincia + ")";
        destinatario.innerHTML = this.state.spedizione.destinatario_ragione_sociale;
        indirizzo_dest.innerHTML = this.state.spedizione.destinatario_indirizzo;
        cap_dest.innerHTML = this.state.spedizione.destinatario_cap.replace(/./g, '$&    ');
        localita_dest.innerHTML = this.state.spedizione.destinatario_citta;
        prov_dest.innerHTML = "(" + this.state.spedizione.destinatario_provincia + ")";
        colli.innerHTML = 1;
        peso.innerHTML = 1;
        tel_seregno.innerHTML = "0362222490";
        mail_seregno.innerHTML = "seregno@fixtofix.it";
        tel.innerHTML = "";
        mail.innerHTML = "";
        // volume.innerHTML = 1;
        natura.innerHTML = this.state.spedizione.natura_merce;
        natura.style.fontSize = "x-small";
        id_sped.innerHTML = this.state.spedizione.numero_ddt;
        note.innerHTML = this.state.spedizione.numero_colli + " pz. - Consegna presso negozio FixToFix";
        data_sped.innerHTML = this.formatDate(this.state.spedizione.data);
        mittente.style.fontSize = "14px";
        indirizzo.style.fontSize = "14px";
        cap.style.fontSize = "14px";
        localita.style.fontSize = "14px";
        provincia.style.fontSize = "14px";
        destinatario.style.fontSize = "14px";
        indirizzo_dest.style.fontSize = "14px";
        cap_dest.style.fontSize = "14px";
        localita_dest.style.fontSize = "14px";
        prov_dest.style.fontSize = "14px";
        colli.style.fontSize = "14px";
        peso.style.fontSize = "14px";
        tel_seregno.style.fontSize = "14px";
        mail_seregno.style.fontSize = "14px";
        tel.style.fontSize = "14px";
        mail.style.fontSize = "14px";
        note.style.fontSize = "14px";
    };

    render() {

        let ddt = null;
        if (this.state.spedizione) {
            ddt = (
                <div className={classes.Print}>
                    <object data={IMAGES.ddt} id="ddt" type="image/svg+xml"
                            onLoad={() => this.inserisciDatiDdt()}/>
                </div>
            );
        }

        return (
            <Auxiliary>
                {ddt}
            </Auxiliary>
        )
    }
}

export default Ddt;

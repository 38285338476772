import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import classes from './App.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from "redux";
import thunk from "redux-thunk";
import authReducer from "./store/reducers/auth";
import buyDeviceReducer from "./store/reducers/buy-device";
import toolbarReducer from "./store/reducers/toolbar";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


// const logger = store => {
//     return next => {
//         return action => {
//             console.log('[Middleware] Dispatching', action);
//             const result = next(action);
//             console.log('[Middleware] next state', store.getState());
//             return result;
//         }
//     }
// };
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    buyDevice: buyDeviceReducer,
    toolbar: toolbarReducer
});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware( thunk)));


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#768828'
        },
        secondary: {
            main: '#c1ca80'
        }
    },
    typography: {
        fontSize: 12
    }

});


const app = (
    <Provider store={store}>
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
            <App className={classes.App}/>
            </MuiThemeProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(
    app,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {Component} from "react";
import classes from './GetDevices.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import FatturePending from "../Fatture/FatturePending/FatturePending";
import DevicesPending from "./DevicesPending/DevicesPending";
import DevicesLiquidati from "./DevicesPending/DevicesLiquidati";
import DevicesValutati from "./DevicesPending/DevicesValutati";

class GetDevices extends Component{

    state = {
        toUpdate: false
    };

    componentDidMount() {
        this.props.onAuthCheckState();
    }

    render() {

        let authRedirect = null;
        console.log('[Fatture] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }

        return (
            <div className={classes.GetDevices}>
                {authRedirect}
                <h5 className={classes.Title}>&nbsp;</h5>
                {/*<FatturePending />*/}
                <DevicesPending />
                <DevicesValutati />
                <DevicesLiquidati />
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/get-devices"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(GetDevices);

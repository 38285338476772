import React, {Component} from 'react';
import classes from './Layout.css'
import Toolbar from '../../components/Navigation/Toolbar/Toolbar'
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer'
import Footer from '../../components/UI/Footer/Footer'
import { connect} from "react-redux";

class Layout extends Component {

    render () {
        return (
            <div>
                <Toolbar className={classes.Toolbar}/>
                {/*<SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler}/>*/}
                <main className={classes.Content}>{this.props.children}</main>
                <Footer />
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        value: state.toolbar.value
    }
};

export default connect(mapStateToProps)(Layout);

import React, {Component} from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SvgIcon from "@material-ui/core/SvgIcon";
import TabContext from "@material-ui/lab/TabContext/TabContext";
import classes from '../../../hoc/Layout/Layout.css'
import { Link } from "react-router-dom";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Logo from '../../Logo/Logo'
import {Route, Switch, withRouter, Redirect } from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import Modal from "../../UI/Modal/Modal";
import Packaging from "../../../containers/Packaging/Packaging";
import Button from '../../UI/Button/Button';
import ICONS from "../../../store/icons";
import Badge from '../../UI/Badge/Badge';
import instance from "../../../axios-spc";
import Bolli from "../../../containers/Bolli/Bolli";
import Snackbar from "../../UI/Snackbar/Snackbar";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import {DatePicker} from "@material-ui/pickers";


class Toolbar extends Component {

    state = {
        value: 0,
        panels: [],
        isRicercaMaterialPackaging: false,
        isVisualizzaDeviceDaSpedire: false,
        isVisualizzaBolli: false,
        notifiche: 0,
        confermaEliminazione: null,
        data: []
    };

    componentDidMount() {

        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
        };
        instance.post('/getSpedizioniPending', postData).then(resp => {
        // axios.post('http://localhost:8989/api/getSpedizioniPending', postData, axiosConfig).then(resp => {
            this.setState({notifiche: resp.data[0].spedizioni_pending})
        });

        if (localStorage.getItem("role") == 3) {
            let newPanels = [
                {label: "Devices in arrivo", url: "/get-devices", value: 0},
                {label: "Magazzino", url: "/magazzino-own", value: 1},
                // {label: "Magazzino Merchant", url: "/magazzino", value: 2},
                {label: "Spedizioni", url: "/spedizioni", value: 3},
                {label: "Ricevute", url: "/ricevute", value: 4},
                {label: "Distinte", url: "/distinte", value: 5},
                {label: "Fatture", url: "/docs", value: 6},
                {label: "Packaging", url: "/packs", value: 7},
                // {label: "Bolli", url: "/bolli", value: 8}
            ];
            this.setState({panels: newPanels});
        } else if (localStorage.getItem("role") == 4) {
            let newPanels = [
                {label: "Devices in arrivo", url: "/get-devices", value: 0},
                {label: "Magazzino", url: "/magazzino-own", value: 1},
                {label: "Magazzino Merchant", url: "/magazzino", value: 2},
                {label: "Spedizioni", url: "/spedizioni", value: 3},
                {label: "Ricevute", url: "/ricevute", value: 4},
                {label: "Distinte", url: "/distinte", value: 5},
                {label: "Fatture", url: "/docs", value: 6},
                {label: "Packaging", url: "/packs", value: 7},
                // {label: "Bolli", url: "/bolli", value: 8}
            ];
            this.setState({panels: newPanels});
        } else {
            let newPanels = [
                {label: "Acquisto", url: "/buy-device", value: 0},
                {label: "Spedizioni", url: "/spedizioni", value: 1},
                // {label: "Distinte", url: "/distinte", value: 5},
                {label: "Magazzino", url: "/magazzino", value: 3},
                {label: "Ricevute", url: "/ricevute", value: 4},
                {label: "Coupon", url: "/coupon", value: 2}
            ];
            if (localStorage.getItem("level") == 1) {
                newPanels.push({label: "Distinte", url: "/distinte", value: 5});
                newPanels.push({label: "Fatture", url: "/docs", value: 6});
            }
            this.setState({panels: newPanels});
        }
    }

    openModalBolli = () => {
        this.setState({isVisualizzaBolli: true})
    };
    openModal = () => {
        this.setState({isRicercaMaterialPackaging: true})
    };
    openDeviceModal = () => {
        this.setState({isVisualizzaDeviceDaSpedire: true, notifiche: 0})
    };
    ricercaPackagingCancelHandler = () => {
        this.setState({isRicercaMaterialPackaging: false, isVisualizzaDeviceDaSpedire: false, isVisualizzaBolli: false})
    };

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', {day: '2-digit'});
        const month = date.toLocaleString('default', {month: '2-digit'});
        const year = date.toLocaleString('default', {year: 'numeric'});
        return day + '-' + month + '-' + year;
    }


    deleteDeviceCollaudo = () => {

        // chiamata a picea
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
        };
        instance.post('/delDeviceCollaudo', postData).then(resp => {
            // axios.post('http://localhost:8989/api/delDeviceCollaudo', postData, axiosConfig).then(resp => {
            this.setState({confermaEliminazione: true})
        });
    };



    getExcel = async () => {
        const columns = [
            { header: 'PROGRESSIVO', key: 'progressivo' },
            { header: 'S.N.', key: 'serial_number' },
            { header: 'BRAND', key: 'brand_name' },
            { header: 'MODELLO', key: 'model_name' },
            { header: 'IMEI', key: 'imei' },
            { header: 'IMEI2', key: 'imei2' },
            { header: 'COLOR', key: 'color' },
            { header: 'MEMORY', key: 'memory_gb' },
            { header: 'REPORT', key: 'report_id' },
            { header: 'VALORE ACQUISTO', key: 'valore_acquisto' },
            { header: 'DATA ACQUISTO', key: 'data_acquisto' },
            { header: 'RAG. SOC. ACQUISTO', key: 'ragione_sociale_acquisto' },
            { header: 'NEGOZIO ACQUISTO', key: 'negozio_acquisto' },
            { header: 'OPERATORE ACQUISTO', key: 'operatore_acquisto' },
            { header: 'NOME CEDENTE', key: 'nome_cedente' },
            { header: 'CODICE FISCALE', key: 'codice_fiscale' },
            { header: 'CITTA', key: 'citta' },
            { header: 'INDIRIZZO', key: 'indirizzo' },
            { header: 'CIVICO', key: 'numero_civico' },
            { header: 'REGIONE', key: 'regione' },
            { header: 'STATO', key: 'stato_device' },
            { header: 'MAGAZZINO ATTUALE', key: 'magazzino_attuale' },
            { header: 'VALORE ACCETTAZIONE', key: 'valore_accettazione' },
            { header: 'OPERATORE ACCETTAZIONE', key: 'operatore_accettazione' },
            { header: 'NOTE ACCETTAZIONE', key: 'note_accettazione' },
        ];

        const workSheetName = 'Worksheet-1';
        const workBookName = 'Device_' + this.formatDate(new Date());
        const workbook = new Excel.Workbook();
        try {
            const fileName = workBookName;

            // creating one worksheet in workbook
            const worksheet = workbook.addWorksheet(workSheetName);

            // add worksheet columns
            // each columns contains header and its mapping key from data
            worksheet.columns = columns;

            // updated the font for first row.
            worksheet.getRow(1).font = { bold: true };

            // loop through all of the columns and set the alignment with width.
            worksheet.columns.forEach(column => {
                column.width = column.header.length + 5;
                column.alignment = { horizontal: 'center' };
            });

            // loop through data and add each one to worksheet
            this.state.data.forEach(singleData => {
                singleData.data_acquisto = this.formatDate(singleData.data_acquisto);
                worksheet.addRow(singleData);
            });

            // loop through all of the rows and set the outline style.
            worksheet.eachRow({ includeEmpty: false }, row => {
                // store each cell to currentCell
                const currentCell = row._cells;

                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach(singleCell => {
                    // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                    const cellAddress = singleCell._address;
                    if (cellAddress.toString().indexOf("1") == 1) {
                        worksheet.getCell(cellAddress).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor:{argb:'c1ca80'}
                        }
                    }
                    // apply border
                    worksheet.getCell(cellAddress).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            });

            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();

            // download the processed file
            saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
    }

    getData = () => {

        instance.post('/excelExport', null).then(resp => {
            // axios.post('http://localhost:8989/api/delDeviceCollaudo', postData, axiosConfig).then(resp => {
            console.log(resp.data);
            this.setState({data: resp.data});
            this.getExcel();

        })
    };


    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({confermaEliminazione: null})
    };

    render() {

        let confermaEliminazioneDevice = null;
        if (this.state.confermaEliminazione) {
            confermaEliminazioneDevice = (
                <Snackbar open={true} closed={this.handleClose} severity="success">Devices eliminati</Snackbar>
            )
        }

        const handleChange = (event, val) => {
            this.setState({value: val})
        };

        const handleLogout = () => {
            this.props.onLogout();
        };

        let modaleBolli = null;
        if (this.state.isVisualizzaBolli) {
            modaleBolli = (
                <Modal show={this.state.isVisualizzaBolli} modalClosed={this.ricercaPackagingCancelHandler}>
                    <Bolli />
                </Modal>
            )
        }

        let modalePackaging = null;
        if (this.state.isRicercaMaterialPackaging) {
            modalePackaging = (
                <Modal show={this.state.isRicercaMaterialPackaging} modalClosed={this.ricercaPackagingCancelHandler} customStyle={"Materiale Packaging"}>
                    <Packaging/>
                </Modal>
            )
        }

        let deviceModal = null;
        if (this.state.isVisualizzaDeviceDaSpedire) {
            if (localStorage.getItem("role") < 3) {
                deviceModal = (
                    <Modal show={this.state.isVisualizzaDeviceDaSpedire} modalClosed={this.ricercaPackagingCancelHandler} customStyle={"Devices"}>
                        <div>
                            <Button clicked={this.ricercaPackagingCancelHandler}>HAI NUOVI DEVICES DA SPEDIRE</Button>
                        </div>
                    </Modal>
                )
            } else {
                deviceModal = (
                    <Modal show={this.state.isVisualizzaDeviceDaSpedire} modalClosed={this.ricercaPackagingCancelHandler} customStyle={"Devices"}>
                        <div>
                            <Button clicked={this.ricercaPackagingCancelHandler}>HAI NUOVI DEVICES IN ARRIVO</Button>
                        </div>
                    </Modal>
                )
            }
        }

        const nomeOperatore = localStorage.getItem("matricola");
        const nomeNegozio = localStorage.getItem("nomeNegozio");
        const idOperatore = localStorage.getItem("userId");
        const role = localStorage.getItem("role");

        let deviceTitle = null;
        if (localStorage.getItem("role") < 3) {
            deviceTitle = "Devices da spedire";
        } else {
            deviceTitle = "Devices in arrivo";
        }

        const titleToolbar = (
            <div className={classes.TopContainer}>
                <Tabs className={classes.IconTabs}
                      indicatorColor="none"
                      textColor="primary"
                      aria-label="icon tabs example"
                      centered
                >
                    {/*{nomeNegozio !== null ? <Tab icon={<img src={ICONS.delete} style={{width: '15%'}}  title="Elimina Devices" />} onClick={this.deleteDeviceCollaudo} aria-label="favorite" style={{ maxWidth: 90}} className={classes.Divider}/> : null}*/}
                    {nomeNegozio !== null && role >= 3 ? <Tab icon={<img src={ICONS.excel} style={{width: '35%'}} title="Excel" />} onClick={this.getData} aria-label="excel" style={{ minWidth: 90}} className={classes.Divider}/> : null}
                    {nomeNegozio !== null ? <Tab icon={<Badge notifiche={this.state.notifiche} title={deviceTitle}/>} onClick={this.openDeviceModal} aria-label="phone" style={{ minWidth: 90}} className={classes.Divider}/> : null}
                    {nomeNegozio !== null && role < 3 ? <Tab icon={<img src={ICONS.packaging} style={{width: '25%'}}  title="Richiesta Packaging" />} onClick={this.openModal} aria-label="favorite" style={{ minWidth: 90}} className={classes.Divider}/> : null}
                    {nomeNegozio !== null && role >= 3 ? <Tab icon={<img src={ICONS.packaging} style={{width: '25%'}}  title="Bolli" />} onClick={this.openModalBolli} aria-label="favorite" style={{ minWidth: 90}} className={classes.Divider}/> : null}
                    {/*<Tab icon={<img src={ICONS.ekventi} style={{width: '25%'}} title="Passa ad Ekventi" />} aria-label="person" style={{ minWidth: 90}} className={classes.Divider}/>*/}
                    <Tab icon={<img src={ICONS.logout} style={{width: '25%'}} title="Logout" />} aria-label="person" style={{ minWidth: 90}} onClick={() => handleLogout()}/>
                </Tabs>
            </div>
        );

        let menuToolbar = (
            <div className={classes.ContainerSmall}>
                <Logo logoSmall={true}/>
            </div>
        );
        if (nomeNegozio !== null) {
            menuToolbar = (
                <div className={classes.Container}>
                    <Logo id={idOperatore} negozio={nomeNegozio} operatore={nomeOperatore}/>
                    <TabContext className={classes.Inline} value={this.state.value.toString()}>
                        <Tabs className={classes.Tabs}
                              value={this.state.value}
                              onChange={handleChange}
                              indicatorColor="none"
                              textColor="primary"
                              centered
                        >
                            {this.state.panels.map((panel, i, array) => {
                                if (array.length === i + 1) return <Tab key={panel.value} value={panel.value} label={panel.label} component={Link} to={panel.url} style={{minWidth: 90}}/>
                                else return <Tab key={panel.value} value={panel.value} label={panel.label} component={Link} to={panel.url} style={{minWidth: 90}} className={classes.Divider}/>
                            })}
                        </Tabs>
                    </TabContext>
                </div>
            )
        }

        return (
            <Auxiliary>
                {confermaEliminazioneDevice}
                {titleToolbar}
                {menuToolbar}
                {modaleBolli}
                {modalePackaging}
                {deviceModal}
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        value: state.toolbar.value,
        panels: state.toolbar.panels,
        operatore: state.auth.operatore,
        negozio: state.auth.negozio,
        isAuth: state.auth.token !== null
    }
};


const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Toolbar));

import React, {Component} from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from "../../../components/UI/Button/Button";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from '@material-ui/data-grid';
import Snackbar from "../../../components/UI/Snackbar/Snackbar";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import classes from "../Spedizioni.css";
import Icon from "../../../components/UI/CustomIcon/CustomIcon";
import instance from "../../../axios-spc";

class SpedizioniPending extends Component{


    state = {
        spedizioni: [
        ],
        headers: [
            {field: 'id', headerName: 'ID', flex: 1},
            {field: 'num_times', headerName: 'NumTimes', flex: 1},
            {field: 'brand_name', headerName: 'Valore', flex: 1},
            {field: 'model_name', headerName: 'Modello', flex: 1},
            {field: 'memory_gb', headerName: 'Memoria', flex: 1},
            {field: 'valore_acquisto', headerName: 'Valore', flex: 1},
            {field: 'color', headerName: 'Colore', flex: 1},
            {
                field: 'conferma',
                headerName: 'Conferma',
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.confermaSpedizione(thisRow["id"], thisRow["num_times"]);
                    };

                    return <Button clicked={onClick}>Conferma</Button>;
                }
            },
        ],
        pageSize: 5,
        confirm: false,
        expandedAccordion: false
    };


    componentDidMount() {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio")
        };

        instance.post('/getSpedizioniPending', postData).then(resp => {
            let spedData = {
                id_spedizione_magcent: resp.data[0].id_spedizione_magcent
            };
            instance.post('/getSpedizioniContenuto', spedData).then(resp => {
                for (let i in resp.data) {
                    resp.data[i].id = resp.data[i].serial_number
                }
                this.setState({spedizioni: resp.data})
            });
        });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({confirm: false});
    };

    handleToogle() {
        let expanded = !this.state.expandedAccordion;
        this.setState({expandedAccordion: expanded})
    }

    confermaSpedizione = (id, numTimes) => {
        var postData = {
            serial_number: id,
            num_times: numTimes,
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/setSpedito', postData).then(resp => {
            if (resp.data[0].ret_code === 0) {
                let idx = this.state.spedizioni.findIndex( (el) => el.id === id );
                const updatedState = {...this.state};
                const updatedSpedizione = [...updatedState.spedizioni];
                updatedSpedizione.splice(idx, 1);
                this.setState({spedizioni: updatedSpedizione, confirm: true});
            }
        });
    }

    render() {

        let confirmAlert = (
            <Snackbar open={this.state.confirm} closed={this.handleClose} severity="success">Spedizione chiusa correttamente!</Snackbar>
        );

        let sped = (
            <div style={{height: 'auto', width: '100%'}} >
                <DataGrid autoHeight={true} rows={this.state.spedizioni} columns={this.state.headers} pageSize={5} />
            </div>
        );

        return (
            <Auxiliary>
                {confirmAlert}
            <Accordion >
                <AccordionSummary className={classes.AccordionIcon} onClick={() => this.handleToogle()} expandIcon={this.state.expandedAccordion ? <Icon image="accordion_aperto"/> : <Icon image="accordion_chiuso"/>} style={{textAlign: 'center', display: 'inline-flex'}}>
                    <h2 className={classes.Title}>CONTENUTO SPEDIZIONI IN ATTESA</h2>
                </AccordionSummary>
                <AccordionDetails>
                    {sped}
                </AccordionDetails>
            </Accordion>
            </Auxiliary>
        );
    }
}



export default (SpedizioniPending);

import React, { Component } from 'react';
import classes from './App.css';
import Auth from "./containers/Auth/Auth";
import Credenziali from "./containers/Credenziali/Credenziali";
import BuyDevice from "./containers/BuyDevice/BuyDevice";
import PackagingLogistica from "./containers/Packaging/PackagingLogistica";
import Distinte from "./containers/Distinte/Distinte";
import Ricevute from "./containers/Ricevute/Ricevute";
import Packaging from "./containers/Packaging/Packaging";
import Magazzino from "./containers/Magazzino/Magazzino";
import Spedizioni from "./containers/Spedizioni/Spedizioni";
import Ricevuta from "./containers/Ricevute/Ricevuta/Ricevuta";
import Fattura from "./containers/Fatture/Fattura/Fattura";
import Bolli from "./containers/Bolli/Bolli";
import Fatture from "./containers/Fatture/Fatture";
import GetDevices from "./containers/GetDevices/GetDevices";
import Coupon from "./containers/Coupons/Coupon/Coupon";
import Impersonificazione from "./containers/Impersonificazione/Impersonificazione";
import {Route, Switch, withRouter, Redirect } from "react-router-dom";
import Logout from "./containers/Auth/Logout/Logout";
import {connect} from "react-redux";
import * as actions from './store/actions/index'
import Layout from "./hoc/Layout/Layout";
import TakePhoto from "./containers/TakePhoto/TakePhoto";
import Ddt from "./containers/Spedizioni/DDT/Ddt";
import CouponContainer from "./containers/Coupons/CouponContainer";

class App extends Component {
    state = {
        show: true
    };

    componentDidMount() {
        this.props.onAuthCheckState();
    }

    render() {

        let routes = (
            <Switch>
                <Route path="/login" exact component={Auth} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/takePhoto" exact component={TakePhoto} />
                <Route path="/stampaRicevuta" exact component={Ricevuta} />
                <Route path="/stampaCoupon" exact component={Coupon} />
                <Route path="/stampaDdt" exact component={Ddt} />
                <Route path="/stampaFattura" exact component={Fattura} />
                <Redirect to="/login" />
            </Switch>
        );

        if (this.props.isAuth) {
            routes = (

                <Switch>
                    <Route path="/logout"  component={Logout} />
                    <Route path="/login"  component={Auth} />
                    <Route path="/credentials"  component={Credenziali}/>
                    <Route path="/imperson"  component={Impersonificazione}/>
                    <Route path="/takePhoto" exact component={TakePhoto} />
                    <Route path="/stampaRicevuta" exact component={Ricevuta} />
                    <Route path="/stampaCoupon" exact component={Coupon} />
                    <Route path="/stampaDdt" exact component={Ddt} />
                    <Route path="/stampaFattura" exact component={Fattura} />
                    <Layout>
                        <Route path="/buy-device"  component={BuyDevice}/>
                        <Route path="/spedizioni"  component={Spedizioni}/>
                        <Route path="/distinte"  component={Distinte}/>
                        <Route path="/ricevute"  component={Ricevute}/>
                        <Route path="/magazzino"  component={Magazzino}/>
                        <Route path="/packaging"  component={Packaging}/>
                        <Route path="/coupon"  component={CouponContainer}/>
                        <Route path="/get-devices"  component={GetDevices}/>
                        <Route path="/magazzino-own"  component={Magazzino}/>
                        <Route path="/docs"  component={Fatture}/>
                        <Route path="/packs"  component={PackagingLogistica}/>
                        <Route path="/bolli"  component={Bolli}/>
                    </Layout>
                    <Redirect to="/login" />
                </Switch>

            );

        }

        return (
            <div className={classes.App}>
                {routes}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuth: state.auth.token != null,
        negozio: state.auth.negozio
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

const ICONS = {
    ekventi: require('../assets/icons/sc_icona_ekventi.png').default,
    login: require('../assets/icons/sc_icona_login.png').default,
    logout: require('../assets/icons/sc_icona_logout.png').default,
    notifiche: require('../assets/icons/sc_icona_notifiche.png').default,
    packaging: require('../assets/icons/sc_icona_packaging.png').default,
    spc: require('../assets/icons/sc_icona_smartphonecash.png').default,
    statistiche: require('../assets/icons/sc_icona_statistiche.png').default,
    pdf: require('../assets/icons/pdfIcon.png').default,
    delete: require('../assets/icons/delete-icon.png').default,
    excel: require('../assets/icons/excel.png').default,
};

export default ICONS;

import * as actionTypes from './actionTypes';

export const inserimentoPiceaStart = () => {
    return {
        type: actionTypes.INSERIMENTO_PICEA_START
    }
};

export const inserimentoPiceaOk = (piceaData) => {
    return {
        type: actionTypes.INSERIMENTO_PICEA_OK,
        loading: false,
        piceaData: piceaData
    }
};

export const inserimentoPiceaFail = (error) => {
    return {
        type: actionTypes.INSERIMENTO_PICEA_FAIL,
        error: error
    }
};

export const inserimentoPiceaReset = () => {
    return {
        type: actionTypes.INSERIMENTO_PICEA_RESET,
        loading: false,
        error: false
    }
};

export const inserimentoPicea = (piceaLink) => {
    return dispatch => {
        dispatch(inserimentoPiceaStart());
        dispatch(inserimentoPiceaOk("APPLE IPHONE 8"))
    }
};

export const resetLinkPicea = () => {
    return dispatch => {
        dispatch(inserimentoPiceaStart());
        dispatch(inserimentoPiceaReset());
    }
};

import React, {Component} from "react";
import classes from './BuyDevice.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from '../../components/UI/Button/Button'
import Modal from '../../components/UI/Modal/Modal';
// import TextField from "../../components/UI/TextField/TextField";
import {TextField, Radio} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import instance from "../../axios-spc";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Spinner from "../../components/UI/Spinner/Spinner";
import QRCode from 'qrcode.react';
import MenuItem from '@material-ui/core/MenuItem';
import crypto from 'crypto'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from "../../components/UI/Snackbar/Snackbar";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Error from '@material-ui/icons/Error';


class BuyDevice extends Component{

    state = {
        piceaLinkInserted: false,
        piceaLink: "",
        piceaData: null,
        piceaSupervalutazione: null,
        piceaImei: null,
        piceaImeiDescription: null,
        piceaPriceChange: 0,
        couponTotalPrice: 0,
        piceaImeiInvalid: false,
        modalitaPagamento: {options: [

            ], value: 0},
        tipologiaDocumento: {options: [{key: 0, value: 0, displayValue: "Seleziona documento identificazione", disabled: true}, {key: 1, value: 1, displayValue: "Carta Identità", disabled: false}, {key: 2, value: 2, displayValue: "Passaporto", disabled: false}, {key: 3, value: 3, displayValue: "Patente", disabled: false}], value: 0},
        tipologiaSupervalutazione: {options: [{key: 0, value: 0, displayValue: "È presente un telefono acquistato con supervalutazione?", disabled: true}, {key: 1, value: 1, displayValue: "Si, è presente una supervalutazione", disabled: false}, {key: 2, value: 2, displayValue: "No, nessuna supervalutazione", disabled: false}], value: 0},
        marcaSupervalutazione: {options: [{key: 0, value: 0, displayValue: "Seleziona la marca del dispositivo acquistato in supervalutazione", disabled: true},
                {key: "Samsung", value: "Samsung", displayValue: "Samsung", disabled: false},
                {key: "Xiaomi", value: "Xiaomi", displayValue: "Xiaomi", disabled: false},
                {key: "Apple", value: "Apple", displayValue: "Apple", disabled: false},
                {key: "Honor", value: "Honor", displayValue: "Honor", disabled: false},
                {key: "Motorola", value: "Motorola", displayValue: "Motorola", disabled: false},
                {key: "OPPO", value: "OPPO", displayValue: "OPPO", disabled: false},
                {key: "Realme", value: "Realme", displayValue: "Realme", disabled: false}], value: 0},
        nazione: {options: []},
        ricercaAnagrafica: {value: '', validation: {minLength: 3}, valid: false, touched: false, validationError: 'Inserire almeno 3 caratteri'},
        numeroDocumento: {value: '', validation: {required: true}, valid: false, touched: false,},
        isRicercaAnagrafica: false,
        isCreazioneAnagrafica: false,
        isModificaAnagrafica: false,
        nuovaAnagrafica: {
            cognome: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Cognome',}, value: '', validation: {required: true}, valid: false, touched: false},
            nome: {elementType: 'input', elementConfig: {type: 'input',placeholder: 'Nome',}, value: '', validation: {required: true}, valid: false, touched: false},
            codice_fiscale: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Codice Fiscale',}, value: '', validation: {required: true}, validationError: '', valid: false, touched: false},
            email: {elementType: 'input', elementConfig: {type: 'input',placeholder: 'Mail',}, value: '', validation: {required: true}, valid: false, touched: false},
            telefono_cellulare: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Cellulare',}, value: '', validation: {required: true}, valid: false, touched: false},
            citta: {elementType: 'input', elementConfig: {type: 'input',placeholder: 'Citta',}, value: '', validation: {required: true}, valid: false, touched: false},
            indirizzo: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Indirizzo',}, value: '', validation: {required: true}, valid: false, touched: false},
            numero_civico: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Civico',}, value: '', validation: {required: true}, valid: false, touched: false},
            cap: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Cap',}, value: '', validation: {required: true}, valid: false, touched: false},
            provincia: {elementType: 'input', elementConfig: {type: 'input', placeholder: 'Provincia',}, value: '', validation: {maxLength: 2}, validationError: 'Massimo 2 caratteri', valid: false, touched: false}
        },
        formAnagraficaIsValid: false,
        page: 0,
        rowsPerPage: 3,
        listaAnagrafiche: [],
        loading: false,
        clienteSelezionato: null,
        clienteDaModificare: null,
        documentoInserito: false,
        nazioneEsteraChecked: false,
        numTimes: 0,
        idRicevuta: 0,
        isStampaDocumento: false,
        isDocumentoFirmato: false,
        loadingPicea: false,
        isProcessoPending: false,
        snToDelete: null,
        isSupervalutazione: false,
        supervalutazioneInserita: false,
        valoreSupervalutazione: null,
        isAffiliatoAttivoSupervalutazione: false,
        isAffiliatoListinoRidotto: false,
        imeiSupervalutazione: {value: '', validation: {required: true}, valid: false, touched: false},
        highestPrice: 0,
        highestPriceLimit: 0,
        isNecessariaRicevuta: false,
        isRicevutaAcquisita: false

    };

    componentDidMount() {
        this.props.onAuthCheckState();
        let newOptions = [
            {key: 0, value: 0, displayValue: "Seleziona", disabled: true},
            {key: 1, value: 1, displayValue: "Buono", disabled: false},
            {key: 2, value: 2, displayValue: "Contanti", disabled: false},
            {key: 3, value: 3, displayValue: "Stripe", disabled: false}
        ];
        if (localStorage.getItem("idAffiliato") == 589) {
            newOptions = [
                {key: 0, value: 0, displayValue: "Seleziona", disabled: true},
                {key: 1, value: 1, displayValue: "Buono", disabled: false},
            ]
        }
        let updateOption = {...this.state.modalitaPagamento};
        updateOption.options = newOptions;
        this.setState({modalitaPagamento: updateOption});

        let idAffiliato = localStorage.getItem("idAffiliato");
        let affiliatiAttiviList = window.config.idAffiliatiAttiviSupervalutazione;
        let affiliatiAttiviArray = [];
        if (affiliatiAttiviList.length > 0) {
            affiliatiAttiviArray = affiliatiAttiviList.split(",");
        }
        let isAffiliatoAttivo = false;
        for (let i = 0; i < affiliatiAttiviArray.length && !isAffiliatoAttivo; ++i) {
            isAffiliatoAttivo = affiliatiAttiviArray[i] === idAffiliato
        }

        let affiliatiListinoRidottoList = window.config.idAffiliatiListinoRidotto;
        let affiliatiListinoRidottoArray = [];
        if (affiliatiListinoRidottoList.length > 0) {
            affiliatiListinoRidottoArray = affiliatiListinoRidottoList.split(",");
        }
        let isAffiliatoListinoRidotto = false;
        for (let i = 0; i < affiliatiListinoRidottoArray.length && !isAffiliatoListinoRidotto; ++i) {
            isAffiliatoListinoRidotto = affiliatiListinoRidottoArray[i] === idAffiliato
        }

        let highestPriceLimit = window.config.limiteValorePerRicevuta;

        this.setState({isAffiliatoAttivoSupervalutazione: isAffiliatoAttivo, isAffiliatoListinoRidotto: isAffiliatoListinoRidotto, highestPriceLimit: highestPriceLimit});
    }

    submitHandler = (event) => {
        if (!this.state.piceaData) {

            // let apiKey = "BmzmWX4gd7yMsK3htPupFQZ5";
            // let clientId = "1B08721A-88DB-4A13-920F-3B57DA5A8C75";

            // clientId = "D39A3924-4DF9-4A58-B46F-20D08933C85B";
            // apiKey = "BWyb8yTVUR5egEm7nPspQkUJ";

            let clientId = localStorage.getItem("clientId");
            let apiKey = localStorage.getItem('clientKey');

            let body = '{"session_id" : "XXX"}';
            let bodyReport = '{"uid": "XXX"}';
            let updatedBody = body.replace("XXX", this.state.piceaLink);
            let updatedBodyReport = bodyReport.replace("XXX", this.state.piceaLink);
            let dataToHash = apiKey + clientId + updatedBody;
            let dataToHashReport = apiKey + clientId + updatedBodyReport;
            let signature    = crypto.createHash('sha256').update(dataToHash).digest('hex');
            let signatureReport    = crypto.createHash('sha256').update(dataToHashReport).digest('hex');
            // chiamata a picea
            var postData = {
                body: updatedBody,
                apiKey: apiKey,
                clientId: clientId,
                signature: signature
            };
            this.setState({loadingPicea: true});
            instance.post('/getPiceaData', postData)
                .then(response => {
                    const result = (JSON.parse(response.data));
                    console.log(result);
                    let report = result.report;

                    if (result.status !== 0) {
                        var postDataReport = {
                            body: updatedBodyReport,
                            apiKey: apiKey,
                            clientId: clientId,
                            signature: signatureReport
                        };

                        instance.post('/getPiceaDataReport', postDataReport)
                            .then(response => {
                                const result = (JSON.parse(response.data));
                                console.log(result);
                                let report = result.report;
                                if (result.status !== 0) {
                                    this.setState({piceaData: "Nessun Report Trovato", loadingPicea: false})
                                } else {
                                    let tr;

                                    const tradingOperation = report.operation.workflow_details.trading;
                                    if (tradingOperation !== undefined) {
                                        const tradingId = tradingOperation.uid;
                                        let price = 0;
                                        let highestPrice = 0;
                                        if (tradingOperation.trading_details.deal_accepted === true) {
                                            price = tradingOperation.trading_details.price_details.price_string;
                                            highestPrice = tradingOperation.trading_details.highest_price_details.price;
                                            this.setState({highestPrice: highestPrice})
                                            if (this.state.isAffiliatoListinoRidotto) price = (+price * 0.8).toFixed(2);
                                            let campaignPrice = 0;
                                            let imeiSupervalutazione = null;
                                            let price_change = 0;
                                            let imei = null;
                                            let total_price = 0;
                                            // if (tradingOperation.trading_details.price_details.campaign_info) {
                                            //     campaignPrice = (+tradingOperation.trading_details.price_details.campaign_info.campaign_price.substring(1, tradingOperation.trading_details.price_details.campaign_info.campaign_price.indexOf(" EUR")));
                                            //     if (tradingOperation.trading_details.price_details.campaign_info.campaign.input_data[0]) {
                                            //         imeiSupervalutazione = "IMEI acquistato: " + tradingOperation.trading_details.price_details.campaign_info.campaign.input_data[0].input_value +
                                            //             " - Device: " + tradingOperation.trading_details.price_details.campaign_info.campaign.name +
                                            //             " - Extra Valutazione: " + tradingOperation.trading_details.price_details.campaign_info.campaign.price_change + " €";
                                            //         price_change = tradingOperation.trading_details.price_details.campaign_info.campaign.price_change;
                                            //         imei = tradingOperation.trading_details.price_details.campaign_info.campaign.input_data[0].input_value;
                                            //         total_price = tradingOperation.trading_details.price_details.campaign_info.total_price;
                                            //         console.log(total_price);
                                            //     }
                                            // }
                                            // let customQuestion = Object.values(tradingOperation.trading_details.answers[0])[0][0].answer[0].value;
                                            const deviceData = {
                                                serial_number: report.operation.device.serial,
                                                brand_name: report.operation.device.manufacturer,
                                                model_name: report.operation.device.model_name,
                                                model_code: report.operation.device.model_code,
                                                region_code: null,
                                                imei: report.operation.device.imei,
                                                imei2: report.operation.device.imei2,
                                                meid: report.operation.device.meid,
                                                color: (report.operation.device.color == null || false) ? 'Various' : report.operation.device.color,
                                                memory_gb: report.operation.device.memory_rom,
                                                report_id: this.state.piceaLink,
                                                evaluation_id: tradingId,
                                                valore_iniziale: 0,
                                                valore_acquisto: price,
                                                id_operatore: localStorage.getItem("userId"),
                                                id_negozio: localStorage.getItem("idNegozio"),
                                                // grado: customQuestion != null ? customQuestion : "N.D."
                                            };
                                            instance.post('/setNewDevice', deviceData).then(resp => {
                                                if (resp.data[0].ret_code === 0) {
                                                    this.setState({
                                                        piceaData: deviceData,
                                                        numTimes: resp.data[0].num_times,
                                                        loadingPicea: false,
                                                        isProcessoPending: false,
                                                        piceaSupervalutazione: campaignPrice,
                                                        piceaImeiDescription: imeiSupervalutazione,
                                                        piceaImei: imei,
                                                        piceaPriceChange: price_change,
                                                        couponTotalPrice: total_price,
                                                        isNecessariaRicevuta: +highestPrice > +this.state.highestPriceLimit
                                                    });

                                                    // if (this.state.piceaSupervalutazione > 0) {
                                                    //     let samsungInfo = {
                                                    //         imei: this.state.piceaImei,
                                                    //     };
                                                    //     axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/checkSamsungImei', samsungInfo).then(resp => {
                                                    //         let data = resp.data;
                                                    //
                                                    //         if (data.imei_used === false && data.status === 'IMEI_FOUND') {
                                                    //             console.log(data);
                                                    //         } else {
                                                    //             this.setState({piceaImeiInvalid: true});
                                                    //         }
                                                    //     })
                                                    // }

                                                } else if (resp.data[0].ret_code === 3) {
                                                    this.setState({
                                                        piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                                                        loadingPicea: false,
                                                        isProcessoPending: true,
                                                        isNecessariaRicevuta: false,
                                                        snToDelete: deviceData.serial_number
                                                    })
                                                } else {
                                                    this.setState({
                                                        piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                                                        loadingPicea: false,
                                                        isProcessoPending: false
                                                    })
                                                }
                                                // this.setState({piceaData: deviceData, numTimes: 1})
                                            });
                                        } else {
                                            this.setState({
                                                piceaData: "Telefono non idoneo per il trading",
                                                loadingPicea: false
                                            })
                                        }
                                    } else {
                                        this.setState({piceaData: "Non risulta essere un report di TRADING", loadingPicea: false})
                                    }
                                }
                            })
                    } else {
                        let tr;
                        let customQuestion;
                        for (let x =0; x < report.operations.length; ++x) {
                            if (report.operations[x].type_string === 'Trading') {
                                tr = report.operations[x]
                            }
                            if (report.operations[x].type_string === 'CustomQuestion') {
                                customQuestion = report.operations[x]
                            }

                        }
                        const tradingOperation = tr;
                        if (tradingOperation !== undefined) {
                            const tradingId = tradingOperation.uid;
                            let price = 0;
                            let campaignPrice = 0;
                            let imeiSupervalutazione = null;
                            let price_change = 0;
                            let imei = null;
                            let total_price = 0;
                            let highestPrice = 0;
                            if (tradingOperation.trading_details.deal_accepted === true) {
                                price = tradingOperation.trading_details.price_details.price_string;
                                highestPrice = tradingOperation.trading_details.highest_price_details.price;
                                if (this.state.isAffiliatoListinoRidotto) price = (+price * 0.8).toFixed(2);
                                this.setState({highestPrice: highestPrice})

                                // if (tradingOperation.trading_details.price_details.campaign_info) {
                                //     campaignPrice = (+tradingOperation.trading_details.price_details.campaign_info.campaign_price.substring(1, tradingOperation.trading_details.price_details.campaign_info.campaign_price.indexOf(" EUR")));
                                //     if (tradingOperation.trading_details.price_details.campaign_info.campaign.input_data[0]) {
                                //         imeiSupervalutazione = "IMEI acquistato: " + tradingOperation.trading_details.price_details.campaign_info.campaign.input_data[0].input_value +
                                //             " - Device: " + tradingOperation.trading_details.price_details.campaign_info.campaign.name +
                                //             " - Extra Valutazione: " + tradingOperation.trading_details.price_details.campaign_info.campaign.price_change + " €";
                                //         price_change = tradingOperation.trading_details.price_details.campaign_info.campaign.price_change;
                                //         imei = tradingOperation.trading_details.price_details.campaign_info.campaign.input_data[0].input_value;
                                //         total_price = tradingOperation.trading_details.price_details.campaign_info.total_price;
                                //         console.log(total_price);
                                //     }
                                // }


                                // let grado = "N.D.";
                                // if (customQuestion) {
                                //     grado = (customQuestion.custom_questions_details.answers[0].answer[0].value);
                                // }
                                const deviceData = {
                                    serial_number: report.device.serial,
                                    brand_name: report.device.manufacturer,
                                    model_name: report.device.model_name,
                                    model_code: report.device.model_code,
                                    region_code: null,
                                    imei: report.device.imei,
                                    imei2: report.device.imei2,
                                    meid: report.device.meid,
                                    color: (report.device.color == null || false) ? 'Various' : report.device.color,
                                    memory_gb: report.device.memory_rom,
                                    report_id: this.state.piceaLink,
                                    evaluation_id: tradingId,
                                    valore_iniziale: 0,
                                    valore_acquisto: price,
                                    id_operatore: localStorage.getItem("userId"),
                                    id_negozio: localStorage.getItem("idNegozio"),
                                    // grado: grado
                                };
                                instance.post('/setNewDevice', deviceData).then(resp => {
                                    // axios.post('http://localhost:8989/api/setNewDevice', deviceData).then(resp => {
                                    if (resp.data[0].ret_code === 0) {
                                        this.setState({
                                            piceaData: deviceData,
                                            numTimes: resp.data[0].num_times,
                                            loadingPicea: false,
                                            isProcessoPending: false,
                                            piceaSupervalutazione: campaignPrice,
                                            piceaImeiDescription: imeiSupervalutazione,
                                            piceaImei: imei,
                                            piceaPriceChange: price_change,
                                            couponTotalPrice: total_price,
                                            isNecessariaRicevuta: +highestPrice > +this.state.highestPriceLimit
                                        });



                                    } else if (resp.data[0].ret_code === 3) {
                                        this.setState({
                                            piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                                            loadingPicea: false,
                                            isProcessoPending: true,
                                            isNecessariaRicevuta: false,
                                            snToDelete: deviceData.serial_number
                                        })
                                    } else {
                                        this.setState({
                                            piceaData: resp.data[0].ret_message.replaceAll("_", " "),
                                            loadingPicea: false,
                                            isProcessoPending: false
                                        })
                                    }
                                });
                            } else {
                                this.setState({piceaData: "Telefono non idoneo per il trading", loadingPicea: false})
                            }
                        } else {
                            this.setState({piceaData: "Non risulta essere un report di TRADING", loadingPicea: false})
                        }

                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({loadingPicea: false});
                });

        } else {
            this.props.onResetLinkPicea();
            const updatedForm = {
                ...this.state.modalitaPagamento
            };
            updatedForm.value = 0;
            const updatedFormDoc = {
                ...this.state.numeroDocumento
            };
            updatedFormDoc.value = "";
            const updatedFormCliente = {
                ...this.state.ricercaAnagrafica
            };
            updatedFormCliente.value = "";
            const updatedFormTipoDoc = {
                ...this.state.tipologiaDocumento
            };
            updatedFormTipoDoc.value = "";
            this.setState({highestPrice: 0,piceaData: null, modalitaPagamento: updatedForm, clienteSelezionato: null, documentoInserito: false, numeroDocumento: updatedFormDoc, ricercaAnagrafica: updatedFormCliente, tipologiaDocumento: updatedFormTipoDoc, piceaSupervalutazione: null, piceaImei: null, piceaPriceChange: 0, piceaImeiDescription: null, couponTotalPrice: 0, piceaImeiInvalid: false, isNecessariaRicevuta: false, isRicevutaAcquisita: false});
        }
    };

    openModalRicercaCliente = (value) => {
        console.log(value);
        this.setState({loading: true});
        let clienti = [];
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            cognome: value,
            codice_fiscale: null,
            ragione_sociale: null,
            partita_iva: null
        };

        instance.post('/getClientiByCognome', postData)
        // axios.post('http://localhost:8989/api/getClientiByCognome', postData)
            .then(response => {
                for (let key in response.data) {
                    clienti.push({...response.data[key], id: key})
                }
                this.setState({listaAnagrafiche: clienti, loading: false});
                console.log(clienti.length)
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
        this.setState({isRicercaAnagrafica: true})
    };

    openModalCreazioneAnagrafica = () => {
        this.getListaNazioni();
        this.setState({isCreazioneAnagrafica: true})
    };

    ricercaCancelHandler = () => {
        this.setState({isRicercaAnagrafica: false, isCreazioneAnagrafica: false, isModificaAnagrafica: false, clienteDaModificare: null})
    };

    selezionaAnagrafica = (cliente) => {
        this.setState({isRicercaAnagrafica: false, clienteSelezionato: cliente})
    };

    modificaAnagrafica = (cliente) => {
        this.setState({isRicercaAnagrafica: false, isModificaAnagrafica: true, clienteDaModificare: cliente})
    };

    annullaModalitaPagamento = () => {
        const updatedForm = {
            ...this.state.modalitaPagamento
        };
        updatedForm.value = 0;
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        const updatedFormCliente = {
            ...this.state.ricercaAnagrafica
        };
        updatedFormCliente.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaDocumento
        };
        updatedFormTipoDoc.value = "";
        this.setState({modalitaPagamento: updatedForm, clienteSelezionato: null, documentoInserito: false, numeroDocumento: updatedFormDoc, ricercaAnagrafica: updatedFormCliente, tipologiaDocumento: updatedFormTipoDoc});
    }

    annullaCliente = () => {
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        const updatedFormCliente = {
            ...this.state.ricercaAnagrafica
        };
        updatedFormCliente.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaDocumento
        };
        updatedFormTipoDoc.value = "";
        this.setState({clienteSelezionato: null, documentoInserito: false, numeroDocumento: updatedFormDoc, ricercaAnagrafica: updatedFormCliente, tipologiaDocumento: updatedFormTipoDoc});
    };

    annullaDoc = () => {
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaDocumento
        };
        updatedFormTipoDoc.value = "";
        this.setState({documentoInserito: false, numeroDocumento: updatedFormDoc, tipologiaDocumento: updatedFormTipoDoc});
    };

    annullaTelefono = () => {
        const updatedFormDoc = {
            ...this.state.imeiSupervalutazione
        };
        updatedFormDoc.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaSupervalutazione
        };
        updatedFormTipoDoc.value = 0;
        const updatedFormMarcaSupervalutazione = {
            ...this.state.marcaSupervalutazione
        };
        updatedFormMarcaSupervalutazione.value = 0;

        let updatedCoupon = {
            serial_number: this.state.piceaData.serial_number,
            coupon_value: +this.state.piceaData.valore_acquisto
        };
        instance.post('/updateCoupon', updatedCoupon).then(function (resp) {
            console.log(resp)
        });

        this.setState({valoreSupervalutazione: null, supervalutazioneInserita: false, isSupervalutazione: false, imeiSupervalutazione: updatedFormDoc, tipologiaSupervalutazione: updatedFormTipoDoc, piceaImeiInvalid: false, marcaSupervalutazione: updatedFormMarcaSupervalutazione});
    };

    annullaNumeroDoc = () => {
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        this.setState({documentoInserito: false, numeroDocumento: updatedFormDoc});
    };

    annullaImei = () => {
        const updatedFormDoc = {
            ...this.state.imeiSupervalutazione
        };
        updatedFormDoc.value = "";
        this.setState({supervalutazioneInserita: false, imeiSupervalutazione: updatedFormDoc, valoreSupervalutazione: null});
    };

    handleChange = (event) => {
        console.log("onChange");
        const value = event.target.value;
        const oldState = {...this.state};
        const oldForm = {...oldState.nuovaAnagrafica};
        oldForm[event.target.name].value = value;
        this.setState({nuovaAnagrafica: oldForm})
    };

    handleChangeModifica = (event) => {
        console.log("onChange");
        const value = event.target.value;
        const oldAnagrafica = {...this.state.clienteDaModificare};
        oldAnagrafica[event.target.name] = value;
        this.setState({clienteDaModificare: oldAnagrafica})
    };

    handleChangeAnagrafica = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.nuovaAnagrafica
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        if (inputIdentifier === 'codice_fiscale') {
            updatedFormElement.valid = updatedFormElement.value.match(/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/);
            !updatedFormElement.valid ? updatedFormElement.validationError = 'Codice Fiscale non valido' : updatedFormElement.validationError = '';
            if (this.state.nazioneEsteraChecked) {
                updatedFormElement.valid = true;
                updatedFormElement.validationError = '';
            }
        } else if (inputIdentifier === 'email') {
            updatedFormElement.valid = updatedFormElement.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            !updatedFormElement.valid ? updatedFormElement.validationError = 'Indirizzo mail non valido' : updatedFormElement.validationError = '';
        } else {
            updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
            !updatedFormElement.valid ? updatedFormElement.validationError = 'Campo obbligatorio' : updatedFormElement.validationError = '';
            if (inputIdentifier === 'provincia') updatedFormElement.validationError = 'Massimo 2 caratteri'
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputId in updatedForm) {
            formIsValid = updatedForm[inputId].valid && formIsValid
        }
        if (this.state.nazioneEsteraChecked) {
            formIsValid = true;
        }
        this.setState({nuovaAnagrafica: updatedForm, formAnagraficaIsValid: formIsValid});
    };

    creaAnagrafica = () => {
        var postData = {
            id_cliente: null,
            nome: this.state.nuovaAnagrafica.nome.value,
            cognome: this.state.nuovaAnagrafica.cognome.value,
            codice_fiscale: this.state.nuovaAnagrafica.codice_fiscale.value,
            ragione_sociale: null,
            partita_iva: null,
            codice_destinatario_b2b: null,
            pec_destinatario_b2b: null,
            is_associazione: null,
            indirizzo: this.state.nuovaAnagrafica.indirizzo.value,
            numero_civico: this.state.nuovaAnagrafica.numero_civico.value,
            citta: this.state.nuovaAnagrafica.citta.value,
            cap: this.state.nuovaAnagrafica.cap.value,
            email: this.state.nuovaAnagrafica.email.value,
            sigla_provincia: this.state.nuovaAnagrafica.provincia.value,
            telefono_cellulare: this.state.nuovaAnagrafica.telefono_cellulare.value,
            nazione: this.state.nuovaAnagrafica.nazione,
            id_negozio: localStorage.getItem("idNegozio"),
        };
        if (this.state.clienteDaModificare) {
            let sigla = this.state.clienteDaModificare.provincia;
            this.state.clienteDaModificare.sigla_provincia = sigla;
            this.state.clienteDaModificare.id_negozio = localStorage.getItem("idNegozio");
            postData = this.state.clienteDaModificare;
        }
        instance.post('/setCliente', postData)
        // axios.post('http://localhost:8989/api/setCliente', postData)
            .then(response => {
                if (response.data[0].ret_code === 0) {
                    let nuovoCliente = {...postData};
                    nuovoCliente.id_cliente = response.data[0].id_cliente;
                    this.setState({clienteSelezionato: nuovoCliente, isCreazioneAnagrafica: false, isModificaAnagrafica: false})
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
        this.setState({isCreazioneAnagrafica: false})
    };

    inputonChangeHandler = (event, id) => {
        switch (id) {
            case "modalitaPagamento": {
                const updatedForm = {
                    ...this.state.modalitaPagamento
                };
                updatedForm.value = event.target.value;
                this.setState({modalitaPagamento: updatedForm});
                break;
            }
            case "ricercaAnagrafica": {
                const updatedForm = {
                    ...this.state.ricercaAnagrafica
                };
                updatedForm.value = event.target.value;
                updatedForm.valid = this.checkValidity(updatedForm.value, updatedForm.validation);
                updatedForm.touched = true;
                this.setState({ricercaAnagrafica: updatedForm});
                break;
            }
            case "numeroDocumento": {
                const updatedForm = {
                    ...this.state.numeroDocumento
                };
                updatedForm.value = event.target.value;
                updatedForm.valid = this.checkValidity(updatedForm.value, updatedForm.validation);
                updatedForm.touched = true;
                this.setState({numeroDocumento: updatedForm});
                break;
            }
            case "tipologiaDocumento": {
                const updatedForm = {
                    ...this.state.tipologiaDocumento
                };
                updatedForm.value = event.target.value;
                this.setState({tipologiaDocumento: updatedForm});
                break;
            }
            case "tipologiaSupervalutazione": {
                const updatedForm = {
                    ...this.state.tipologiaSupervalutazione
                };
                updatedForm.value = event.target.value;
                this.setState({tipologiaSupervalutazione: updatedForm});
                break;
            }
            case "imeiSupervalutazione": {
                const updatedForm = {
                    ...this.state.imeiSupervalutazione
                };
                updatedForm.value = event.target.value;
                this.setState({imeiSupervalutazione: updatedForm});
                break;
            }
            case "marcaSupervalutazione": {
                const updatedForm = {
                    ...this.state.marcaSupervalutazione
                };
                updatedForm.value = event.target.value;
                this.setState({marcaSupervalutazione: updatedForm});
                break;
            }
            case "piceaLink": {
                this.setState({piceaLink: event.target.value});
                break;
            }
            case "valoreSupervalutazione": {
                this.setState({valoreSupervalutazione: event.target.value});
                break;
            }
            default: {
                const oldState = {
                    ...this.state
                };
                this.setState({state: oldState});
                break;
            }
        }

    };

    checkValidity(value, rules) {
        let isValid = true;
        if (rules) {
            if (rules.required) {
                isValid = value.trim() !== '' && isValid;
            }
            if (rules.minLength) {
                isValid = value.length >= rules.minLength && isValid
            }
            if (rules.maxLength) {
                isValid = value.length <= rules.maxLength && isValid
            }
        }
        return isValid
    }

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: +event.target.value, page: 0})
    };
    handleChangeCheckBox = (event) => {
        this.setState({nazioneEsteraChecked: event.target.checked})
    };
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        const updatedFormMarcaSupervalutazione = {
            ...this.state.marcaSupervalutazione
        };
        updatedFormMarcaSupervalutazione.value = 0;
        this.setState({isStampaDocumento: false, piceaImeiInvalid: false, marcaSupervalutazione: updatedFormMarcaSupervalutazione, valoreSupervalutazione: null})
    };

    confermaSupervalutazione = () => {
        console.log("é stata inserito un imei per la supervalutazione " + this.state.imeiSupervalutazione);
        this.setState({supervalutazioneInserita: true});

        let supervalutazioneValore = this.state.valoreSupervalutazione.replaceAll(",", ".");
        let updatedCoupon = {
            serial_number: this.state.piceaData.serial_number,
            coupon_value: +this.state.piceaData.valore_acquisto + +supervalutazioneValore
        };

        let valoreAcquisto = this.state.piceaData.valore_acquisto;

        let samsungInfo = {
            imei: this.state.imeiSupervalutazione.value,
            // imei: '350221512428069',
        };

        if (this.state.marcaSupervalutazione.value === 'Samsung') {


            instance.post('/checkSamsungImei', samsungInfo).then(resp => {
                let data = resp.data;

                if (data.imei_used === false && data.status === 'IMEI_FOUND') {
                    this.setState({piceaImeiInvalid: false});
                    instance.post('/updateCoupon', updatedCoupon).then(function (resp) {
                        if (resp.data[0].ret_code === 0) {
                            let imeiSupervalutazione = {
                                serial_number: updatedCoupon.serial_number,
                                imei_acquistato: data.imei,
                                sku: data.sku
                            };
                            instance.post('/setImeiSupervalutazione', imeiSupervalutazione).then(function (r) {
                                console.log(r);
                                let samsungInfo = {
                                    imei: data.imei,
                                    // imei: '350221512428069',
                                    marketing_name: 'SmartphoneCash',
                                    brand_name: "Eco Store",
                                    device_value: +valoreAcquisto,
                                    voucher_value: (+updatedCoupon.coupon_value),
                                    store_name: localStorage.getItem("ragione_sociale") != null ? localStorage.getItem("ragione_sociale") : 'Eco Store',
                                    store_city: localStorage.getItem("nomeNegozio").substring(0, localStorage.getItem("nomeNegozio").indexOf("-")),
                                    store_province: "PV"
                                };
                                console.log(samsungInfo);
                                instance.post('/setSamsungImei', samsungInfo).then(resp => {
                                    console.log(resp.data);
                                }).catch(err => {
                                    console.log(err)
                                })
                            })
                        }
                    });
                } else {
                    this.setState({piceaImeiInvalid: true});
                }
            })
        } else {
            console.log("Scelto Altro");
            this.setState({piceaImeiInvalid: false});
            let otherImei = this.state.imeiSupervalutazione.value;
            let sku = this.state.marcaSupervalutazione.value;
            instance.post('/updateCoupon', updatedCoupon).then(function (resp) {
                if (resp.data[0].ret_code === 0) {
                    let imeiSupervalutazione = {
                        serial_number: updatedCoupon.serial_number,
                        imei_acquistato: otherImei,
                        sku: sku + '-SKU'
                    };
                    instance.post('/setImeiSupervalutazione', imeiSupervalutazione).then(function (r) {
                        console.log(r);
                    }).catch(err => {
                        console.log(err)
                    })
                }
            });
        };
    };


    confermaDocumento = () => {

        if (this.state.modalitaPagamento.value === 1) {
            let couponData = {
                serial_number: this.state.piceaData.serial_number,
                num_times: this.state.numTimes,
            };
            instance.post('/setCoupon', couponData).then(resp => {
                // axios.post('http://localhost:8989/api/setCoupon', couponData).then(resp => {
                let postData = {
                    serial_number: this.state.piceaData.serial_number,
                    num_times: this.state.numTimes,
                    id_negozio: localStorage.getItem("idNegozio"),
                    id_operatore: localStorage.getItem("userId"),
                    id_cliente: this.state.clienteSelezionato.id_cliente,
                    id_tipo_documento: this.state.tipologiaDocumento.value,
                    numero_documento: this.state.numeroDocumento.value,
                    is_coupon: 1,
                    coupon_serial_number: resp.data[0].coupon_serial_number,
                };
                instance.post('/setRicevuta', postData)
                // axios.post('http://localhost:8989/api/setRicevuta', postData)
                    .then(response => {
                        this.setState({documentoInserito: true})
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });


            // if (this.state.piceaSupervalutazione > 0) {
            //     let updatedCoupon = {
            //         serial_number: this.state.piceaData.serial_number,
            //         coupon_value: +this.state.piceaData.valore_acquisto + this.state.valoreSupervalutazione
            //     };
            //     let imeiSupervalutazione = {
            //         serial_number: this.state.piceaData.serial_number,
            //         imei_acquistato: this.state.piceaImei,
            //         sku: 'SKU'
            //     };
            //     if (this.state.isSupervalutazione && this.state.supervalutazioneInserita && this.state.valoreSupervalutazione > 0) {
            //         axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/updateCoupon', updatedCoupon).then(function (resp) {
            //             if (resp.data[0].ret_code === 0) {
            //                 axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/setImeiSupervalutazione', imeiSupervalutazione).then(function (r) {
            //                     console.log(r)
            //                 })
            //             }
            //         });
            //     }
            //
            //     // let samsungInfo = {
            //     //     imei: this.state.piceaImei,
            //     //     // imei: '350221512428069',
            //     //     marketing_name: 'SmartphoneCash',
            //     //     brand_name: "Eco Store",
            //     //     device_value: +this.state.piceaData.valore_acquisto,
            //     //     voucher_value: (+this.state.piceaData.valore_acquisto + this.state.piceaPriceChange),
            //     //     store_name: localStorage.getItem("ragione_sociale") != null ? localStorage.getItem("ragione_sociale") : 'Eco Store',
            //     //     store_city: localStorage.getItem("nomeNegozio").substring(0, localStorage.getItem("nomeNegozio").indexOf("-")),
            //     //     store_province: "PV"
            //     // };
            //     // axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/setSamsungImei', samsungInfo).then(resp => {
            //     //     let data = resp.data;
            //     //
            //     //
            //     //
            //     // })
            // }
        } else {
            let postData = {
                serial_number: this.state.piceaData.serial_number,
                num_times: this.state.numTimes,
                id_negozio: localStorage.getItem("idNegozio"),
                id_operatore: localStorage.getItem("userId"),
                id_cliente: this.state.clienteSelezionato.id_cliente,
                id_tipo_documento: this.state.tipologiaDocumento.value,
                numero_documento: this.state.numeroDocumento.value,
                is_coupon: 0,
                coupon_serial_number: null,
            };
            instance.post('/setRicevuta', postData)
            // axios.post('http://localhost:8989/api/setRicevuta', postData)
                .then(response => {
                    this.setState({documentoInserito: true})
                })
                .catch(error => {
                    console.log(error);
                });
        }

    };

    getListaNazioni = () => {
        let postData = {
            id_paese: null
        };
        instance.post('/getNazioni', postData)
        // axios.post('http://localhost:8989/api/getNazioni', postData)
            .then(response => {
                const updatedForm = {
                    ...this.state.nazione
                };
                let newOption = response.data;
                updatedForm.options = newOption;
                this.setState({nazione: updatedForm})
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
        this.setState({isCreazioneAnagrafica: false})
    };

    delOldDevice = () => {
        let postData = {
            serial_number: this.state.snToDelete,
        };
        instance.post('/delPendingDevice', postData)
        // axios.post('http://localhost:8989/api/delPendingDevice', postData)
            .then(response => {
                if (response.data[0].ret_code === 0) {
                    this.setState({snToDelete: null, isProcessoPending: false});
                    this.submitHandler();
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({snToDelete: null, isProcessoPending: false});
            });
    };

    // closeImeiAlertHandler = () => {
    //     // this.annullaImei();
    //     // this.annullaTelefono();
    //     console.log("close")
    // };

    checkRicevutaFirmata = () => {
        this.setState({isStampaDocumento: true});
        let postData = {
            serial_number: this.state.piceaData.serial_number,
            num_times: this.state.numTimes,
            id_negozio: localStorage.getItem("idNegozio"),
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/getRicevuta', postData)
            .then(response => {
                if (response.data[0].firma_privacy !== null) {
                    this.setState({isDocumentoFirmato: true});
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
    };

    handleChangeSupervalutazione = (e) => {
        let supervalutazione = e.target.value === "true";
        this.setState({isSupervalutazione: supervalutazione});
    };

    ricevutaPresente = () => {
        this.setState({isRicevutaAcquisita: true, isNecessariaRicevuta: false});
    }

    annullaInserimentoDevice = () => {
        let postData = {
            serial_number: this.state.piceaData.serial_number,
            num_times: this.state.numTimes,
            id_negozio: localStorage.getItem("idNegozio"),
            id_operatore: localStorage.getItem("userId")
        };
        instance.post('/deleteDevicePending', postData)
            .then(response => {
                this.setState({loading: false});
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
        const updatedForm = {
            ...this.state.modalitaPagamento
        };
        updatedForm.value = 0;
        const updatedFormDoc = {
            ...this.state.numeroDocumento
        };
        updatedFormDoc.value = "";
        const updatedFormCliente = {
            ...this.state.ricercaAnagrafica
        };
        updatedFormCliente.value = "";
        const updatedFormTipoDoc = {
            ...this.state.tipologiaDocumento
        };
        updatedFormTipoDoc.value = "";
        this.setState({highestPrice: 0,piceaData: null, modalitaPagamento: updatedForm, clienteSelezionato: null, documentoInserito: false, numeroDocumento: updatedFormDoc, ricercaAnagrafica: updatedFormCliente, tipologiaDocumento: updatedFormTipoDoc, piceaSupervalutazione: null, piceaImei: null, piceaPriceChange: 0, piceaImeiDescription: null, couponTotalPrice: 0, piceaImeiInvalid: false, isNecessariaRicevuta: false, isRicevutaAcquisita: false});
        console.log("Annullamento del telefono");
    }

    render() {

        const closeImeiAlertHandler = () => {
            this.annullaImei();
            this.annullaTelefono();
        };

        const ricevutaPresenteHandler = () => {
            this.ricevutaPresente()
        }
        const ricevutaAssenteHandler = () => {
            this.annullaInserimentoDevice();
        }

        let authRedirect = null;
        console.log('[Buy-Device] isAuth? ' + this.props.isAuth);
        // if (!this.props.isAuth) {
        //     authRedirect = <Redirect to="/login" />
        // }

        let piceaForm = (
            <TextField
                id="picea_link"
                label="Inserisci link Picea"
                variant="outlined"
                style={{margin: 10}}
                fullWidth
                value={this.props.piceaLink}
                onChange={(e) => this.inputonChangeHandler(e, "piceaLink")}
            />
        );

        let spinnerPicea = null;
        if (this.state.loadingPicea) {
            spinnerPicea = <Spinner/>
        }

        let processoPending = null;
        if (this.state.isProcessoPending) {
            processoPending = (
                <Auxiliary>
                    <p>Chiudere il processo e permettere l'inserimento del device?</p>
                    <Button clicked={this.delOldDevice}>Si, chiudi il processo e permetti inserimento device</Button>
                </Auxiliary>
            );
        }
        if (this.state.piceaData && this.state.piceaData.brand_name != null) {
            piceaForm = (
                <div>
                    <p>Link picea correttamente inserito per il device: {this.state.piceaData.brand_name + " " + this.state.piceaData.model_name}</p>
                    <p>Valore Acquisto: {this.state.piceaData.valore_acquisto} €</p>
                </div>
            )
        } else if (this.state.piceaData) {
            piceaForm = (
                <Auxiliary>
                    <p>{this.state.piceaData}</p>
                    {processoPending}
                </Auxiliary>)
        }

        let ricevutaNecessaria = null;
        if (this.state.isNecessariaRicevuta) {
            ricevutaNecessaria =  (
                <Dialog
                    open={this.state.isNecessariaRicevuta}
                    // onClose={handleClose}
                >
                    <DialogTitle style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                        <Error style={{color:'red'}}/>
                        ATTENZIONE
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Il dispositivo che si sta acquistando ha una valutazione massima <b>superiore ai 400 €</b><br/>
                            Confermi di essere in possesso della relativa ricevuta d'acquisto?<br/><br/>
                            In caso di assenza della prova d'acquisto il processo verrà interrotto<br/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button clicked={ricevutaPresenteHandler}>
                            SI, è presente la ricevuta d'acquisto
                        </Button>
                        <Button clicked={ricevutaAssenteHandler}>
                            NO, la ricevuta è assente
                        </Button>
                    </DialogActions>
                </Dialog>)
        }

        let piceaSupervalutazione = null;
        if (this.state.piceaSupervalutazione > 0) {
            piceaSupervalutazione = (
                <div>
                    <p>{this.state.piceaImeiDescription } </p>
                </div>
            )
        }

        let modalitaPagamento = null;
        if (this.state.piceaData && this.state.piceaData.brand_name != null) {
            modalitaPagamento = (
                <div>
                    <p>Seleziona la modalità di pagamento</p>
                    <TextField
                        id="modalitaPagamento"
                        select
                        fullWidth
                        variant="outlined"
                        elementType="select"
                        value={this.state.modalitaPagamento.value}
                        label="Modalità pagamento"
                        onChange={(e) => this.inputonChangeHandler(e, "modalitaPagamento")}
                    >
                        {this.state.modalitaPagamento.options.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                                {option.displayValue}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            )
        }
        if (this.state.modalitaPagamento.value > 0) {
            modalitaPagamento = (
                <div>
                    <p>Modalità pagamento scelta: {this.state.modalitaPagamento.options[this.state.modalitaPagamento.value].displayValue}<HighlightOffIcon style={{fontSize: 'x-large', color: '#768828', marginLeft: '5px'}} onClick={this.annullaModalitaPagamento} /></p>
                </div>
            )
        }

        let formAnagrafica = null;
        if (this.state.modalitaPagamento.value > 0) {
            formAnagrafica = (
                <div className={classes.BuyDevice}>
                    <TextField
                        id="ricercaAnagrafica"
                        elementType="input"
                        variant="outlined"
                        value={this.state.ricercaAnagrafica.value}
                        label="Ricerca anagrafica"
                        style={{margin: 10}}
                        fullWidth
                        helperText={this.state.ricercaAnagrafica.validationError}
                        error={!this.state.ricercaAnagrafica.valid && this.state.ricercaAnagrafica.touched}
                        onChange={(e) => this.inputonChangeHandler(e, "ricercaAnagrafica")}
                    />

                    <Button clicked={(e) => this.openModalRicercaCliente(this.state.ricercaAnagrafica.value)} disabled={!this.state.ricercaAnagrafica.valid || this.state.ricercaAnagrafica === ""}>Ricerca</Button>
                    <h5>oppure</h5>
                    <Button clicked={this.openModalCreazioneAnagrafica}>Inserisci Anagrafica</Button>
                </div>
            )
        }

        let modal = null;
        if (this.state.isRicercaAnagrafica) {
            modal = (
                <Modal show={this.state.isRicercaAnagrafica} modalClosed={this.ricercaCancelHandler} title={"Clienti Trovati"}>
                    {(this.state.loading ? <Spinner/> :
                            <Auxiliary>
                                <TableContainer>
                                    <Table stickyHeader size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nome / Rag. Soc.</TableCell>
                                                <TableCell>C.F. / P. Iva</TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                (this.state.listaAnagrafiche)
                                                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                    .map(cliente => (
                                                        <TableRow key={cliente.id_cliente}>
                                                            <TableCell component="th" scope="row">{cliente.cognome === "N.D." ? cliente.ragione_sociale : cliente.cognome + " " + cliente.nome}</TableCell>
                                                            <TableCell>{cliente.codice_fiscale === "N.D." ? cliente.partita_iva : cliente.codice_fiscale}</TableCell>
                                                            <TableCell><Button clicked={() => this.modificaAnagrafica(cliente)}>Modifica</Button></TableCell>
                                                            <TableCell><Button clicked={() => this.selezionaAnagrafica(cliente)}>Scegli</Button></TableCell>
                                                        </TableRow>
                                                    ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[3, 5, 10]}
                                    component="div"
                                    count={this.state.listaAnagrafiche.length - 1}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Auxiliary>
                    )}

                </Modal>
            )
        }

        if (this.state.isCreazioneAnagrafica) {
            const formElementArray = [];
            for (let key in this.state.nuovaAnagrafica) {
                formElementArray.push({
                    id: key,
                    config: this.state.nuovaAnagrafica[key]
                })
            }

            if (this.state.nazioneEsteraChecked) {
                // delete formElementArray[7];
                delete formElementArray[8];
                delete formElementArray[9];
            }

            let inputFields = formElementArray.map(el => (
                <TextField
                    name={el.id}
                    key={el.id}
                    label={el.config.elementConfig.placeholder}
                    variant="outlined"
                    value={el.config.value}
                    fullWidth
                    type={el.id == "password" ? "password" : "input" }
                    style={{width: '50%', align: 'center', margin: 10 }}
                    error={!el.config.valid && el.config.touched}
                    helperText={el.config.validationError}
                    touched={el.config.touched}
                    onChange={(e, id) => this.handleChangeAnagrafica(e, el.id)}
                />
            ));

            let sceltaNazioneField = (
                <div>
                    <FormControlLabel
                        control={<Checkbox checked={this.state.nazioneEsteraChecked} onChange={this.handleChangeCheckBox} />}
                        label="Stato Estero?"
                    />
                    <br/>
                    {this.state.nazioneEsteraChecked ? (
                        <TextField
                            id="modalitaPagamento"
                            select
                            fullWidth
                            variant="outlined"
                            elementType="select"
                            value={this.state.nazione.value}
                            label="Nazione"
                            style={{width: '50%', align: 'center', margin: 10 }}
                            // options={this.state.modalitaPagamento.options}
                            onChange={(e) => this.inputonChangeHandler(e, "nazione")}
                        >
                            {this.state.nazione.options.map((option) => (
                                <MenuItem key={option.sigla_nazione} value={option.sigla_nazione}>
                                    {option.paese}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : null}
                </div>
            );

            modal = (
                <Modal show={this.state.isCreazioneAnagrafica} modalClosed={this.ricercaCancelHandler} title={"Creazione Anagrafica"} customStyle={"Creazione Anagrafica"}>

                    <div style={{columns: 2}}>
                        {sceltaNazioneField}
                        {inputFields}
                    </div>
                    <Button clicked={this.creaAnagrafica} disabled={!this.state.formAnagraficaIsValid}>Crea Anagrafica</Button>
                </Modal>
            )
        }

        if (this.state.isModificaAnagrafica) {
            const formElementArray = [];
            for (let key in this.state.nuovaAnagrafica) {
                formElementArray.push({
                    id: key,
                    config: this.state.nuovaAnagrafica[key]
                })
            }
            let inputFields = formElementArray.map(el => (
                <TextField
                    name={el.id}
                    key={el.id}
                    label={el.config.elementConfig.placeholder}
                    variant="outlined"
                    value={this.state.clienteDaModificare[el.id]}
                    // fullWidth
                    type={el.id == "password" ? "password" : "input" }
                    style={{width: '50%', align: 'center', margin: 10 }}
                    error={!el.config.valid && el.config.touched}
                    helperText={el.config.validationError}
                    touched={el.config.touched}
                    onChange={(e) => this.handleChangeModifica(e)}
                />
            ));


            modal = (
                <Modal show={this.state.isModificaAnagrafica} modalClosed={this.ricercaCancelHandler} title={"Modifica Anagrafica"} customStyle={"Creazione Anagrafica"}>

                    <div style={{columns: 2}}>
                        {inputFields}
                    </div>
                    <Button clicked={this.creaAnagrafica}>Modifica</Button>
                </Modal>
            )
        }

        let infoCliente = null;
        if (this.state.clienteSelezionato !== null) {
            formAnagrafica = null;
            infoCliente = (
                <div>
                    <p>{this.state.clienteSelezionato.cognome + " " + this.state.clienteSelezionato.nome}<HighlightOffIcon style={{fontSize: 'x-large', color: '#768828', marginLeft: '5px'}} onClick={this.annullaCliente} /></p>
                    <p>{this.state.clienteSelezionato.codice_fiscale}</p>
                    <p>{this.state.clienteSelezionato.indirizzo + " " + this.state.clienteSelezionato.numero_civico + ", " + this.state.clienteSelezionato.citta}</p>
                </div>
            );
        }

        let tipologiaDocumento = null;
        if (this.state.clienteSelezionato !== null) {
            tipologiaDocumento = (
                <TextField
                    id="tipologiaDocumento"
                    elementType="select"
                    select
                    fullWidth
                    variant="outlined"
                    value={this.state.tipologiaDocumento.value}
                    label="Tipologia Documento Identificazione"
                    options={this.state.tipologiaDocumento.options}
                    onChange={(e) => this.inputonChangeHandler(e, "tipologiaDocumento")}
                >
                    {this.state.tipologiaDocumento.options.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.displayValue}
                        </MenuItem>
                    ))}
                </TextField>
            )
        }
        if (this.state.tipologiaDocumento.value > 0) {
            tipologiaDocumento = <p>Documento identificazione: {this.state.tipologiaDocumento.options[this.state.tipologiaDocumento.value].displayValue}<HighlightOffIcon style={{fontSize: 'x-large', color: '#768828', marginLeft: '5px'}} onClick={this.annullaDoc} /></p>
        }

        let tipologiaSupervalutazione = null;
        if (this.state.tipologiaSupervalutazione.value == 1) {
            tipologiaSupervalutazione = <p>Supervalutazione: {this.state.tipologiaSupervalutazione.options[this.state.tipologiaSupervalutazione.value].displayValue}<HighlightOffIcon style={{fontSize: 'x-large', color: '#768828', marginLeft: '5px'}} onClick={this.annullaTelefono} /></p>
        }

        let numeroDocumento = null;
        if (this.state.tipologiaDocumento.value > 0) {
            numeroDocumento = (
                <Auxiliary>
                    <TextField
                        id="numeroDocumento"
                        elementType="input"
                        fullWidth
                        value={this.state.numeroDocumento.value}
                        label="Numero Documento"
                        variant="outlined"
                        style={{margin: 10}}
                        onChange={(e) => this.inputonChangeHandler(e, "numeroDocumento")}
                    />
                    <Button clicked={this.confermaDocumento} disabled={this.state.numeroDocumento.value.length < 7}>Conferma Documento</Button>
                </Auxiliary>
            )
        }

        let supervalutazione = null;
        if (this.state.documentoInserito && this.state.tipologiaSupervalutazione.value === 0 && this.state.isAffiliatoAttivoSupervalutazione) {
            supervalutazione = (
                <Auxiliary>
                    <div>
                        <TextField
                            id="tipologiaSupervalutazione"
                            elementType="select"
                            select
                            fullWidth
                            variant="outlined"
                            value={this.state.tipologiaSupervalutazione.value}
                            label="Supervalutazione"
                            options={this.state.tipologiaSupervalutazione.options}
                            onChange={(e) => this.inputonChangeHandler(e, "tipologiaSupervalutazione")}
                        >
                            {this.state.tipologiaSupervalutazione.options.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.displayValue}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </Auxiliary>
            )
        }

        let imeiSupervalutazione = null;
        if (this.state.tipologiaSupervalutazione.value == 1 && !this.state.supervalutazioneInserita) {
            imeiSupervalutazione = (
                <Auxiliary>
                    <div>
                        <TextField
                            id="marcaSupervalutazione"
                            elementType="select"
                            select
                            fullWidth
                            variant="outlined"
                            value={this.state.marcaSupervalutazione.value}
                            label="Marca telefono in supervalutazione"
                            options={this.state.marcaSupervalutazione.options}
                            onChange={(e) => this.inputonChangeHandler(e, "marcaSupervalutazione")}
                        >
                            {this.state.marcaSupervalutazione.options.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.displayValue}
                                </MenuItem>
                            ))}
                        </TextField>
                        <br />
                        <TextField
                            id="imeiSupervalutazione"
                            elementType="input"
                            fullWidth
                            value={this.state.imeiSupervalutazione.value}
                            label="IMEI Telefono Acquistato"
                            variant="outlined"
                            style={{margin: 10}}
                            onChange={(e) => this.inputonChangeHandler(e, "imeiSupervalutazione")}
                        />
                        <TextField
                            id="valoreSupervalutazione"
                            elementType="input"
                            // fullWidth
                            value={this.state.valoreSupervalutazione}
                            label="Valore Supervalutazione"
                            variant="outlined"
                            style={{margin: 10}}
                            onChange={(e) => this.inputonChangeHandler(e, "valoreSupervalutazione")}
                        />
                        <br />
                        <Button clicked={this.confermaSupervalutazione} disabled={this.state.imeiSupervalutazione.value.length < 7 || this.state.valoreSupervalutazione <= 0 || this.state.marcaSupervalutazione.value === 0}>Conferma IMEI</Button>
                    </div>
                </Auxiliary>
            )
        }

        let imeiNonValido = null;
        if (this.state.piceaImeiInvalid) {
            // imeiNonValido = <Auxiliary><Error style={{color: 'red', fontSize: 'xx-large'}}/><span>L'IMEI del telefono acquistato non è valido per la promozione</span></Auxiliary>
            imeiNonValido = (
                <Dialog
                    open={this.state.piceaImeiInvalid}
                    // onClose={handleClose}
                >
                    <DialogTitle style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                        <Error style={{color:'red'}}/>
                        ATTENZIONE
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            L'IMEI del telefono acquistato non è valido per la promozione<br/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button clicked={closeImeiAlertHandler} autoFocus>
                            CHIUDI
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        }

        let imeiInserito = null;
        if (this.state.supervalutazioneInserita) {

            imeiInserito =
                <Auxiliary><p>IMEI telefono acquistato: {this.state.imeiSupervalutazione.value.toLocaleUpperCase()}</p>
                    <p>Valore supervalutazione: {this.state.valoreSupervalutazione} €
                        <HighlightOffIcon style={{fontSize: 'x-large', color: '#768828', marginLeft: '5px'}} onClick={this.annullaImei} /></p>
                    {imeiNonValido}
                </Auxiliary>
        }

        let qrCode = null;
        let buttonStampaRicevuta = null;
        if (this.state.documentoInserito) {
            numeroDocumento = <p>Numero documento: {this.state.numeroDocumento.value.toLocaleUpperCase()}<HighlightOffIcon style={{fontSize: 'x-large', color: '#768828', marginLeft: '5px'}} onClick={this.annullaNumeroDoc} /></p>;
        }

        let showQrCode = (this.state.supervalutazioneInserita && this.state.documentoInserito) || ((this.state.tipologiaSupervalutazione.value === 2 && this.state.documentoInserito)) || (!this.state.isAffiliatoAttivoSupervalutazione && this.state.documentoInserito);
        if (showQrCode && !this.state.piceaImeiInvalid) {
            qrCode = (
                <div style={{marginTop: '1%'}}>
                    <QRCode
                        value={"https://www.smartphonecash.it/takePhoto?cf=" + this.state.clienteSelezionato.codice_fiscale + "&device=" + this.state.piceaData.serial_number + "&negozio=" + localStorage.getItem("idNegozio") + "&idc=" + this.state.clienteSelezionato.id_cliente + "&nt=" + this.state.numTimes}/>
                    <p>Inquadra il QR Code dal cellulare per scattare le foto al documento</p>
                </div>
            );

            buttonStampaRicevuta = (
                <Button clicked={this.checkRicevutaFirmata}>STAMPA RICEVUTA FIRMATA</Button>
            )
        }
        let confermaDocumento = null;
        if (this.state.isStampaDocumento && this.state.isDocumentoFirmato) {
            const codDocumento = this.state.piceaData.serial_number + "-" + this.state.numTimes;
            window.open("https://www.smartphonecash.it/stampaRicevuta?cod=" + codDocumento);
            // let axiosConfig = {
            //     headers: {
            //         'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8'
            //     }
            // };

            // axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/getMail', {id_email_tipo: 25})
            //     .then(response => {
            //         console.log(response);
            //         const mailData = {
            //             from: response.data[0].email_from,
            //             to: response.data[0].replyto,
            //             cc: '',
            //             body: response.data[0].body,
            //             object: response.data[0].subject,
            //         };
            //         axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/sendMail', mailData).then(resp => {
            //             console.log(resp);
            //         })
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         this.setState({loading: false});
            //     });

            confermaDocumento = (
                <Snackbar open={true} closed={this.handleClose} severity="success">Ricevuta firmata correttamente!</Snackbar>
            )
        } else if (this.state.isStampaDocumento && !this.state.isDocumentoFirmato) {
            confermaDocumento = (
                <Snackbar open={true} closed={this.handleClose} severity="error">La ricevuta digitale non è ancora stata completata</Snackbar>
            )
        }

        let resetButton = (
            <Button clicked={this.submitHandler}>{this.state.piceaData ? "RESET" : "CONFERMA"}</Button>
        );
        if (this.state.documentoInserito) {
            resetButton = <Button clicked={this.submitHandler}>TORNA ALL'INSERIMENTO DEVICE</Button>
        }


        return (
            <Auxiliary className={classes.BuyDevice}>
                <div className={classes.BuyDevice}>
                    {authRedirect}
                    <h1 className={classes.Title}> ACQUISTO DEVICE </h1>
                </div>
                <form onSubmit={e => e.preventDefault()}>
                    <div className={classes.BuyDevice}>
                        {piceaForm}
                        {piceaSupervalutazione}
                        {spinnerPicea}
                        {ricevutaNecessaria}
                        {modalitaPagamento}
                    </div>
                    {formAnagrafica}
                    {modal}
                    <div className={classes.BuyDevice}>
                        {/*<div><Error style={{color: 'red', fontSize: 'xx-large'}}/><span>L'IMEI del telefono acquistato non è valido per la promozione</span></div>*/}
                        {confermaDocumento}
                        {infoCliente}
                        {tipologiaDocumento}
                        {numeroDocumento}
                        {supervalutazione}
                        {tipologiaSupervalutazione}
                        {imeiSupervalutazione}
                        {imeiInserito}
                        {qrCode}
                        {buttonStampaRicevuta}
                    </div>
                    {resetButton}
                </form>
            </Auxiliary>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState()),
        onCheckLinkPicea: (event) => dispatch(actions.inserimentoPicea(event)),
        onResetLinkPicea: () => dispatch(actions.resetLinkPicea())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        // authRedirectPath: "/buy-device",
        piceaData: state.buyDevice.piceaData,
        negozio: state.auth.negozio
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(BuyDevice);

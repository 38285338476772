import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import ICONS from "../../../store/icons";
import axios from "axios";
import Excel from "exceljs";
import {saveAs} from "file-saver";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';

const defaultToolbarStyles = {
    iconButton: {
        float: 'left'
    },
};

class CustomToolbar extends React.Component {

    state = {
        data: null
    };

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    handleClick = () => {
        console.log("clicked on icon!");
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        };
        var postData = {
            id_affiliato: localStorage.getItem("idAffiliato"),
            data_inizio: null,
            data_fine: null
        };
        axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/exportSpedizioni', postData, axiosConfig).then(resp => {
            // axios.post('http://localhost:8989/api/delDeviceCollaudo', postData, axiosConfig).then(resp => {
            console.log(resp.data);
            this.setState({data: resp.data});
            this.getExcel();

        })
    };

    getExcel = async () => {
        const columns = [
            { header: 'ID', key: 'id_spedizione_magcent' },
            { header: 'NEGOZIO ACQUISTO', key: 'negozio_acquisto' },
            { header: 'DATA', key: 'data_richiesta_spedizione_magcent' },
            { header: 'BRAND', key: 'brand_name' },
            { header: 'MODELLO', key: 'model_name' },
            { header: 'IMEI', key: 'imei' },
            { header: 'COLOR', key: 'color' },
            { header: 'MEMORY', key: 'memory_gb' },
            { header: 'VALORE ACQUISTO', key: 'valore_acquisto' },
        ];

        const workSheetName = 'Worksheet-1';
        const workBookName = 'Spedizioni_' + this.formatDate(new Date());
        const workbook = new Excel.Workbook();
        try {
            const fileName = workBookName;

            // creating one worksheet in workbook
            const worksheet = workbook.addWorksheet(workSheetName);

            // add worksheet columns
            // each columns contains header and its mapping key from data
            worksheet.columns = columns;

            // updated the font for first row.
            worksheet.getRow(1).font = { bold: true };

            // loop through all of the columns and set the alignment with width.
            worksheet.columns.forEach(column => {
                column.width = column.header.length + 5;
                column.alignment = { horizontal: 'center' };
            });

            // loop through data and add each one to worksheet
            this.state.data.forEach(singleData => {
                singleData.data_richiesta_spedizione_magcent = this.formatDate(singleData.data_richiesta_spedizione_magcent);
                worksheet.addRow(singleData);
            });

            // loop through all of the rows and set the outline style.
            worksheet.eachRow({ includeEmpty: false }, row => {
                // store each cell to currentCell
                const currentCell = row._cells;

                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach(singleCell => {
                    // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                    const cellAddress = singleCell._address;

                    if (cellAddress.toString().indexOf("1") == 1) {
                        worksheet.getCell(cellAddress).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor:{argb:'c1ca80'}
                        }
                    }
                    // apply border
                    worksheet.getCell(cellAddress).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            });

            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();

            // download the processed file
            saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Auxiliary>
            <React.Fragment>
                    <IconButton className={classes.iconButton} onClick={this.handleClick}>
                        <img src={ICONS.excel}/>
                    </IconButton>
            </React.Fragment>
            </Auxiliary>
        );
    }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);

import React, {Component} from "react";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary'
import queryString from "query-string";
import instance from "../../../axios-spc";
import classes from "../../TakePhoto/TakePhoto.css";
import IMAGES from "../../../store/images";
import JsBarcode from "jsbarcode";

class Coupon extends Component {

    state = {
        coupon: null
    };

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        let couponSerialNumber = params.sn;
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            coupon_serial_number: couponSerialNumber,

        };
        instance.post('/getCoupon', postData)
        // axios.post('http://localhost:8989/api/getCoupon', postData, axiosConfig)
            .then(response => {
                this.setState({coupon: response.data[0]});
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
            });
    }

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    inserisciDatiBuono = () => {
        let doc = document.getElementById("buono");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const valore_buono_stampato = svgElement.getElementById("valore_buono_stampato");
        const seriale_buono_stampato = svgElement.getElementById("seriale_buono_stampato");
        const data_scadenza_stampato = svgElement.getElementById("data_scadenza_stampato");
        const data_emissione_stampato = svgElement.getElementById("data_emissione_stampato");
        let frase_affiliato = svgElement.getElementById("frase_affiliato");
        const frase_affiliato_2 = svgElement.getElementById("frase_affiliato_2");

        valore_buono_stampato.innerHTML = this.state.coupon.coupon_value + " €";
        seriale_buono_stampato.innerHTML = this.state.coupon.coupon_serial_number;
        data_scadenza_stampato.innerHTML = this.formatDate(this.state.coupon.data_scadenza);
        data_emissione_stampato.innerHTML = this.formatDate(this.state.coupon.data_creazione);
        let frasi = "presso i punti vendita di " + this.state.coupon.ragione_sociale;
        frase_affiliato.textContent = frasi;
        if (frase_affiliato.textContent.length > 70) {
            let parti = frase_affiliato.textContent.split(",");
            frase_affiliato.textContent = parti[0];
            frase_affiliato_2.textContent = parti[1];
        }


        var barcode = svgElement.getElementById("barcode");
        JsBarcode(barcode, this.state.coupon.coupon_serial_number, {
            displayValue: false
        });
        barcode.setAttributeNS(null, 'x','314.867');
        barcode.setAttributeNS(null, 'y','358.496');
        barcode.setAttributeNS(null, 'width','174.93');
        barcode.setAttributeNS(null, 'height','65.66');

        barcode = svgElement.getElementById("barcodeMarca");
        JsBarcode(barcode, this.state.coupon.brand_name, {
            // displayValue: true
            text: 'Marca: ' + this.state.coupon.brand_name
        });
        barcode.setAttributeNS(null, 'x','84.867');
        barcode.setAttributeNS(null, 'y','328.496');
        barcode.setAttributeNS(null, 'width','174.93');
        barcode.setAttributeNS(null, 'height','45.66');

        barcode = svgElement.getElementById("barcodeModello");
        JsBarcode(barcode, this.state.coupon.model_name, {
            // displayValue: true
            text: 'Modello: ' + this.state.coupon.model_name
        });
        barcode.setAttributeNS(null, 'x','84.867');
        barcode.setAttributeNS(null, 'y','378.496');
        barcode.setAttributeNS(null, 'width','174.93');
        barcode.setAttributeNS(null, 'height','45.66');

        barcode = svgElement.getElementById("barcodeImei");
        JsBarcode(barcode, this.state.coupon.imei, {
            // displayValue: true
            text: 'Imei: ' + this.state.coupon.imei
        });
        barcode.setAttributeNS(null, 'x','84.867');
        barcode.setAttributeNS(null, 'y','428.496');
        barcode.setAttributeNS(null, 'width','174.93');
        barcode.setAttributeNS(null, 'height','45.66');


        var barcode_prezzo = svgElement.getElementById("barcode_prezzo");
        JsBarcode(barcode_prezzo, this.state.coupon.coupon_value, {
            displayValue: false
        });
        barcode_prezzo.setAttributeNS(null, 'x','335.38');
        barcode_prezzo.setAttributeNS(null, 'y','220.80287');
        barcode_prezzo.setAttributeNS(null, 'width','74.93');
        barcode_prezzo.setAttributeNS(null, 'height','44.66');
    };

    render() {

        let couponCompleto = null;
        if (this.state.coupon) {
             couponCompleto = (
                <div className={classes.Print}>
                    <object data={IMAGES.doc9} id="buono" type="image/svg+xml"
                            onLoad={() => this.inserisciDatiBuono()}/>
                </div>
            );
        }

        return (
            <Auxiliary>
                {couponCompleto}
            </Auxiliary>
        )
    }
}

export default Coupon;

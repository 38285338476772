import * as actionTypes from './actionTypes';
import instance from '../../axios-spc'
import crypto from 'crypto'

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (token, userId, path) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        userId: userId,
        path: path
    }
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const logout = () => {
    localStorage.clear()
    return {
        type: actionTypes.LOGOUT
    }
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
};

export const auth = (email, password, isSignUp) => {
    return dispatch => {
        dispatch(authStart());
        let psw    = crypto.createHash('md5').update(password).digest('hex');
        const authData = {
            user_name: email,
            password: psw
        };
        if (!isSignUp) {
            instance.post("/login", authData)
                .then(response => {
                    const expirationDate = new Date(new Date().getTime()  + (1000 * 60 * 60));
                    console.log(response);
                    if (response.data.ret_code === 0) {
                        localStorage.setItem('expirationDate', expirationDate);
                        localStorage.setItem('idNegozio', response.data.id_negozio);
                        localStorage.setItem('idAffiliato', response.data.id_affiliato);
                        localStorage.setItem('nomeNegozio', response.data.negozio);
                        localStorage.setItem('userId', response.data.id_operatore);
                        localStorage.setItem('level', response.data.level);
                        localStorage.setItem('ragione_sociale', response.data.ragione_sociale);
                        localStorage.setItem('matricola', response.data.matricola);
                        localStorage.setItem('token', Math.random().toString().substr(2));
                        localStorage.setItem('role', response.data.id_role);
                        dispatch(authSuccess("token", response.data.id_operatore));

                        let keyData = {
                            id_affiliato: response.data.id_affiliato
                        };
                        instance.post('/getPiceaKeys', keyData).then(function (res) {
                            localStorage.setItem("clientId", res.data[0].client_id);
                            localStorage.setItem("clientKey", res.data[0].client_key);
                            if (response.data.id_role === 3) {
                                dispatch(setAuthRedirectPath("/docs"));
                                localStorage.setItem('idNegozio', 0);
                            } else if (response.data.id_role === 4) {
                                dispatch(setAuthRedirectPath("/imperson"));
                            } else if (response.data.id_affiliato !== 364 && response.data.id_role === 1) {
                                dispatch(setAuthRedirectPath("/credentials"));
                            } else {
                                dispatch(setAuthRedirectPath("/buy-device"));
                            }
                        })
                    } else {
                        dispatch(authFail(response.data.ret_message))
                    }
                })
                .catch(error => {
                    console.log(error);
                    dispatch(authFail(error))
                })
        }
    }
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
            dispatch(setAuthRedirectPath("/login"))
        }
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate < new Date())
                dispatch(logout());
            else {
                const expirationDate = new Date(new Date().getTime()  + (1000 * 60 * 60));
                localStorage.setItem('expirationDate', expirationDate);
                dispatch(authSuccess(token, localStorage.getItem('userId')));
            }
        }
    }
};

export const credenzialiInserite = (negozio, operatore) => {
    return {
        type: actionTypes.CREDENZIALI_INSERITE,
        negozio: negozio,
        operatore: operatore
    }
};

export const authSetOperatoreNegozio = (negozio, operatore) => {
    return dispatch => {
        dispatch(credenzialiInserite(negozio, operatore));
        localStorage.setItem('negozio', negozio);
        localStorage.setItem('userId', operatore);
        dispatch(setAuthRedirectPath("/buy-device"));
    }
};

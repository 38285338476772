import React, {Component} from 'react';
import Input from '../../components/UI/Input/Input'
import Button from '../../components/UI/Button/Button'
import classes from '../Auth/Auth.css'
import * as actions from '../../store/actions/index'
import {connect} from "react-redux";
import produce from "immer";
import instance from "../../axios-spc";
import {Redirect} from 'react-router-dom';
import TextField from "../../components/UI/TextField/TextField";
import Auxiliary from '../../hoc/Auxiliary/Auxiliary'


class Impersonificazione extends Component {

    state = {
        formOperatoreEsistente: {
            controls: {
                affiliato: {
                    elementType: 'select',
                    elementConfig: {
                        options: [],
                        placeholder: 'Affiliato'
                    },
                    value: '0',
                    validation: {},
                    valid: false,
                    touched: false,
                },
                negozio: {
                    elementType: 'select',
                    elementConfig: {
                        options: [],
                        placeholder: 'Negozio'
                    },
                    value: '0',
                    validation: {},
                    valid: false,
                    touched: false,
                }
            }
        },
        formOperatoreEsistenteIsValid: false,
        formNuovoOperatoreIsValid: false,
        negozi: []
    };

    componentDidMount() {

        let negozi = [];
        const authData = {
            id_affiliato: null
        };

        instance.post('/getListaNegoziSys', authData).then(response => {
        // axios.post('http://localhost:8989/api/getListaNegoziSys', authData, axiosConfig).then(response => {
            if (response.data.length > 0) {
                for (let key in response.data) {
                    negozi.push({...response.data, id: response.data[key].id_negozio})
                }
            }

            const formOpEsistente = this.generaFormHelper("formOperatoreEsistente", negozi);
            this.setState({formOperatoreEsistente: formOpEsistente});
        }).catch(error => {
                console.log(error);
        })

    }

    generaFormHelper (formName, negozi, operatori) {
        let negoziOption = negozi
            .filter(n => {
                return n.id > 0;
            }).map((n, index) => {
                    return {key: n.id, value: (index + 1), displayValue: n[index].negozio, disabled: false}
            });
        let newOptions = {options: [{key: 0, value: 0, displayValue: "NESSUN NEGOZIO", disabled: false}], placeholder: 'Negozio'};
        for (let obj in negoziOption) {
            newOptions['options'].push(negoziOption[obj]);
        }

        return produce(this.state[formName], draft => {
            draft.controls.negozio.elementConfig = newOptions;
        });
    };

    checkValidity(value, rules) {
        let isValid = true;
        if (rules) {
            if (rules.required) {
                isValid = value.trim() !== '' && isValid;
            }
            if (rules.minLength) {
                isValid = value.length >= rules.minLength && isValid
            }
            if (rules.maxLength) {
                isValid = value.length <= rules.maxLength && isValid
            }
        }
        return isValid
    }

    inputChangedOpEsistenteHandler = (event, inputIdentifier, formName) => {
        const updatedOpEsistenteFormObject = this.inputChangedHelper(event, inputIdentifier, formName);
        this.setState({formOperatoreEsistente: updatedOpEsistenteFormObject.updatedForm, formOperatoreEsistenteIsValid: updatedOpEsistenteFormObject.formIsValid})
    };

    inputChangedNuovoOpHandler = (event, inputIdentifier, formName) => {
        const updatedNuovoOpFormObject = this.inputChangedHelper(event, inputIdentifier, formName);
        this.setState({formNuovoOperatore: updatedNuovoOpFormObject.updatedForm, formNuovoOperatoreIsValid: updatedNuovoOpFormObject.formIsValid})
    };

    inputChangedHelper = (event, inputIdentifier, formName) => {
        const updatedForm = {
            ...this.state[formName]
        };
        const updatedControls = {
            ...updatedForm["controls"]
        };
        const updatedFormElement = {
            ...updatedControls[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedControls[inputIdentifier] = updatedFormElement;
        updatedForm["controls"] = updatedControls;

        let formIsValid = true;
        for (let inputId in updatedForm["controls"]) {
            formIsValid = updatedForm.controls[inputId].valid && formIsValid
        }
        return {
            updatedForm,
            formIsValid
        }
    };

    submitOperatoreEsistenteHandler = (event) => {
        const nomeNegozio = this.state.formOperatoreEsistente.controls.negozio.elementConfig.options[this.state.formOperatoreEsistente.controls.negozio.value];
        localStorage.setItem("nomeNegozio", nomeNegozio.displayValue);
        localStorage.setItem("idNegozio", nomeNegozio.key);
        localStorage.setItem("idAffiliato", this.state.formOperatoreEsistente.controls.negozio.elementConfig.options[this.state.formOperatoreEsistente.controls.negozio.value].id_affiliato);
        this.props.history.push('/get-devices');
    };

    handleChange = (event) => {
        console.log("changed");
        const value = event.target.value;
        const oldState = {...this.state};
        const oldForm = {...oldState.nuovaAnagrafica};
        oldForm[event.target.name].value = value;
        this.setState({nuovaAnagrafica: oldForm})
    };

    render() {
        const formElementOperatoreEsistente = [];
        for (let key in this.state.formOperatoreEsistente.controls) {
            formElementOperatoreEsistente.push({
                id: key,
                config: this.state.formOperatoreEsistente.controls[key]
            })
        }
        const formElementArray = [];
        for (let key in this.state.nuovaAnagrafica) {
            formElementArray.push({
                id: key,
                config: this.state.nuovaAnagrafica[key]
            })
        }

        let formOperatoreEsistente = (
            <Auxiliary>
                {/*<div>*/}
                {/*    <TextField*/}
                {/*        id="affiliatoForm"*/}
                {/*        elementType="select"*/}
                {/*        value={this.state.formOperatoreEsistente.controls.affiliato.value}*/}
                {/*        label="Affiliato"*/}
                {/*        options={this.state.formOperatoreEsistente.controls.affiliato.elementConfig.options}*/}
                {/*        changed={(e) => this.inputChangedOpEsistenteHandler(e, "affiliato", "formOperatoreEsistente")}*/}
                {/*    >*/}
                {/*    </TextField>*/}
                {/*</div>*/}
                <div>
                    <TextField
                        id="negozioForm"
                        elementType="select"
                        value={this.state.formOperatoreEsistente.controls.negozio.value}
                        label="Negozio"
                        options={this.state.formOperatoreEsistente.controls.negozio.elementConfig.options}
                        changed={(e) => this.inputChangedOpEsistenteHandler(e, "negozio", "formOperatoreEsistente")}
                    >
                    </TextField>
                </div>
            </Auxiliary>
        );

        let errorMessage = null;
        if (this.props.error) {
            errorMessage = (
                <p>{this.props.error.message}</p>
            )
        }

        let authRedirect = null;

        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }

        return (
            <div className={classes.Auth}>
                {authRedirect}
                <h1>Scegli negozio da impersonificare</h1>
                {errorMessage}
                <form>
                    {formOperatoreEsistente}
                    {/*<Button clicked={this.submitOperatoreEsistenteHandler} disabled={!this.state.formOperatoreEsistenteIsValid}>Accedi</Button>*/}
                    <Button clicked={this.submitOperatoreEsistenteHandler}>Accedi</Button>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuth: state.auth.token !== null,
        operatore: state.auth.operatore,
        negozio: state.auth.negozio
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (negozio, operatore) => dispatch(actions.authSetOperatoreNegozio(negozio, operatore)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Impersonificazione);

import React, {Component} from "react";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import axios from "axios";
import queryString from "query-string";
import IMAGES from '../../../store/images'
import classes from '../../TakePhoto/TakePhoto.css'
import jsPDF from 'jspdf';
import Button from "../../../components/UI/Button/Button"
import domtoimage from 'dom-to-image';
import JsBarcode from "jsbarcode";
import Spinner from '../../../components/UI/Spinner/Spinner'
import instance from "../../../axios-spc";


class Ricevuta extends Component {

    state = {
        device: null,
        ricevuta: null,
        fronte: null,
        retro: null,
        firma_uno: null,
        firma_due: null,
        firma_privacy: null,
        isMailInvita: false,
        loading: false
    };


    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        let codicePratica = params.cod;
        let query = codicePratica.split("-");
        var postData = {
            id_negozio: localStorage.getItem("idNegozio") == 0 ? null : localStorage.getItem("idNegozio"),
            serial_number: query[0],
            num_times: query[1],
            id_operatore: localStorage.getItem("userId")

        };
        instance.post('/getRicevuta', postData)
            .then(response => {
                this.setState({ricevuta: response.data[0]});
                var postData = {
                    serial_number:  query[0],
                    id_negozio: localStorage.getItem("idNegozio") == 0 ? null : localStorage.getItem("idNegozio"),
                };
                instance.post('/getDevice', postData).then(response =>{
                    this.setState({device: response.data[0]});
                    var img = {
                        fronte: '/opt/media/spc/ricevute/' + this.state.ricevuta.fronte_documento + ".png",
                        retro: '/opt/media/spc/ricevute/' + this.state.ricevuta.retro_documento + ".png",
                        firma_uno: '/opt/media/spc/ricevute/' + this.state.ricevuta.firma_contratto_uno + ".png",
                        firma_due: '/opt/media/spc/ricevute/' + this.state.ricevuta.firma_contratto_due + ".png",
                        firma_privacy: '/opt/media/spc/ricevute/' + this.state.ricevuta.firma_privacy + ".png",
                    };
                    instance.post('/getImage', img).then(resp => {
                        console.log(resp);
                        this.setState({fronte: resp.data.fronte, retro: resp.data.retro, firma_uno: resp.data.firma_uno, firma_due: resp.data.firma_due, firma_privacy: resp.data.firma_privacy})
                    });
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    savePdf = () => {
        let that = this;
        let mailCliente = this.state.ricevuta.email_cedente;
        this.setState({isMailInvita: true, loading: true});

        const pages = [];
        let doc = document.getElementById("pagina_uno");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        pages.push(svgElement);
        doc = document.getElementById("ricevuta_acquisto");
        svgDoc = doc.contentDocument;
        svgElement = svgDoc.getElementById("Livello_1");
        pages.push(svgElement);
        doc = document.getElementById("pagina_tre");
        svgDoc = doc.contentDocument;
        svgElement = svgDoc.getElementById("Livello_1");
        pages.push(svgElement);
        doc = document.getElementById("ricevuta_acquisto_firma");
        svgDoc = doc.contentDocument;
        svgElement = svgDoc.getElementById("Livello_1");
        pages.push(svgElement);
        doc = document.getElementById("ricevuta_acquisto_firma_due");
        svgDoc = doc.contentDocument;
        svgElement = svgDoc.getElementById("Livello_1");
        pages.push(svgElement);
        doc = document.getElementById("pagina_sei");
        svgDoc = doc.contentDocument;
        svgElement = svgDoc.getElementById("Livello_1");
        pages.push(svgElement);
        doc = document.getElementById("pagina_sette");
        svgDoc = doc.contentDocument;
        svgElement = svgDoc.getElementById("Livello_1");
        pages.push(svgElement);
        doc = document.getElementById("scansioni");
        svgDoc = doc.contentDocument;
        svgElement = svgDoc.getElementById("Livello_1");
        pages.push(svgElement);
        if (this.state.ricevuta.is_coupon) {
            doc = document.getElementById("buono");
            svgDoc = doc.contentDocument;
            svgElement = svgDoc.getElementById("Livello_1");
            pages.push(svgElement);
        }

        const newDiv = document.createElement("div");
        newDiv.id = 'pdf';
        newDiv.setAttribute("style", "background:#fff");


        var img1 = document.createElement('img');
        var img2 = document.createElement('img');
        var img3 = document.createElement('img');
        var img4 = document.createElement('img');
        var img5 = document.createElement('img');
        var img6 = document.createElement('img');
        var img7 = document.createElement('img');
        var img8 = document.createElement('img');
        var img9 = document.createElement('img');


        let svgStrings = [];
        for (let i in pages) {
            console.log(i);
            svgStrings.push(btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(pages[i])))));
        }

        img1.setAttribute('src', "data:image/svg+xml;base64," + svgStrings[0]);
        img2.setAttribute('src', "data:image/svg+xml;base64," + svgStrings[1]);
        img3.setAttribute('src', "data:image/svg+xml;base64," + svgStrings[2]);
        img4.setAttribute('src', "data:image/svg+xml;base64," + svgStrings[3]);
        img5.setAttribute('src', "data:image/svg+xml;base64," + svgStrings[4]);
        img6.setAttribute('src', "data:image/svg+xml;base64," + svgStrings[5]);
        img7.setAttribute('src', "data:image/svg+xml;base64," + svgStrings[6]);
        img8.setAttribute('src', "data:image/svg+xml;base64," + svgStrings[7]);
        img9.setAttribute('src', "data:image/svg+xml;base64," + svgStrings[8]);

        img1.setAttribute("style", "background:#fff");
        img2.setAttribute("style", "background:#fff");
        img3.setAttribute("style", "background:#fff");
        img4.setAttribute("style", "background:#fff");
        img5.setAttribute("style", "background:#fff");
        img6.setAttribute("style", "background:#fff");
        img7.setAttribute("style", "background:#fff");
        img8.setAttribute("style", "background:#fff");
        img9.setAttribute("style", "background:#fff");
        img1.id = '1';
        img2.id = '2';
        img3.id = '3';
        img4.id = '4';
        img5.id = '5';
        img6.id = '6';
        img7.id = '7';
        img8.id = '8';
        img9.id = '9';

        newDiv.appendChild(img1);
        newDiv.appendChild(img2);
        newDiv.appendChild(img3);
        newDiv.appendChild(img4);
        newDiv.appendChild(img5);
        newDiv.appendChild(img6);
        newDiv.appendChild(img7);
        newDiv.appendChild(img8);
        newDiv.appendChild(img9);

        const currentDiv = document.getElementById("content");
        currentDiv.parentNode.insertBefore(newDiv, currentDiv);

        var pagina = new jsPDF();
        var options = {
            quality: 0.25
        };
        domtoimage.toJpeg(document.getElementById('1'), options).then(function (dataUrl) {
            pagina.addImage(dataUrl, 'JPEG', 0, 0, 210, 300);
            pagina.addPage();
        });
        domtoimage.toJpeg(document.getElementById('2'), options).then(function (dataUrl) {
            pagina.addImage(dataUrl, 'JPEG', 0, 0, 210, 300);
            pagina.addPage();
        });
        domtoimage.toJpeg(document.getElementById('3'), options).then(function (dataUrl) {
            pagina.addImage(dataUrl, 'JPEG', 0, 0, 210, 300);
            pagina.addPage();
        });
        domtoimage.toJpeg(document.getElementById('4'), options).then(function (dataUrl) {
            pagina.addImage(dataUrl, 'JPEG', 0, 0, 210, 300);
            pagina.addPage();
        });
        domtoimage.toJpeg(document.getElementById('5'), options).then(function (dataUrl) {
            pagina.addImage(dataUrl, 'JPEG', 0, 0, 210, 300);
            pagina.addPage();
        });
        domtoimage.toJpeg(document.getElementById('6'), options).then(function (dataUrl) {
            pagina.addImage(dataUrl, 'JPEG', 0, 0, 210, 300);
            pagina.addPage();
        });
        domtoimage.toJpeg(document.getElementById('7'), options).then(function (dataUrl) {
            pagina.addImage(dataUrl, 'JPEG', 0, 0, 210, 300);
            pagina.addPage();
        });
        domtoimage.toJpeg(document.getElementById('8'), options).then(function (dataUrl) {
            pagina.addImage(dataUrl, 'JPEG', 0, 0, 210, 300);
            pagina.addPage();
        });

        domtoimage.toJpeg(document.getElementById('9'), options).then(function (dataUrl) {
            pagina.addImage(dataUrl, 'JPEG', 0, 0, 210, 300);

            instance.post('/getMail', {id_email_tipo: 55}).then(function (resp) {
                let mailData = resp.data[0];
                let data = {
                    from: mailData.email_from,
                    // to: 'shaba.roberto@gmail.com',
                    to: mailCliente,
                    object: mailData.subject,
                    body: mailData.message_body,
                    attach_name: 'ricevuta_acquisto.pdf',
                    attach_body: pagina.output('dataurlstring'),
                };
                console.log(data);
                instance.post('/sendMail', data).then(resp => {
                    console.log(resp);
                    that.setState({isMailInvita: true, loading: false});
                }).then(err => {
                    console.log(err)
                });
            })
        });

    };

    stampaRicevuta = () => {
        window.print();
    };

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    compila = (ricevuta) => {
        let doc = document.getElementById("ricevuta_acquisto");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const NS = "http://www.w3.org/2000/svg";

        const nome = svgElement.getElementById("nome");
        const device = svgElement.getElementById("device");
        const color = svgElement.getElementById("color");
        const memory = svgElement.getElementById("memory");
        const seriale = svgElement.getElementById("seriale");
        const numero_pratica = svgElement.getElementById("numero_pratica");
        const data_acquisto = svgElement.getElementById("data_acquisto");
        const data_generazione = svgElement.getElementById("data_generazione");
        const prezzo = svgElement.getElementById("prezzo");
        const modello = svgElement.getElementById("modello");
        const indirizzo = svgElement.getElementById("indirizzo");
        const telefono = svgElement.getElementById("telefono");
        const mail = svgElement.getElementById("mail");
        const documento = svgElement.getElementById("documento");
        const cognome = svgElement.getElementById("cognome");
        const codice_fiscale = svgElement.getElementById("codice_fiscale");
        const imei = svgElement.getElementById("imei");
        const progressivo = svgElement.getElementById("progressivo");

        var i = ricevuta.nome_cedente.indexOf(' ');
        const anagrafica = [ricevuta.nome_cedente.substring(0, i), ricevuta.nome_cedente.substring(i+1)];

        nome.innerHTML = anagrafica[0];
        device.innerHTML = this.state.ricevuta.oggetto;
        progressivo.innerHTML = +localStorage.getItem("role") >= 3 ? ' - Nr. ' + this.state.ricevuta.progressivo : '';

        numero_pratica.innerHTML = this.state.ricevuta.codice_pratica;
        data_acquisto.innerHTML = this.formatDate(this.state.ricevuta.data_acquisto);
        data_generazione.innerHTML = this.formatDate(new Date().toJSON().slice(0,10));
        prezzo.innerHTML = this.state.ricevuta.prezzo_acquisto;

        indirizzo.innerHTML = this.state.ricevuta.indirizzo_cedente;
        telefono.innerHTML = this.state.ricevuta.telefono_cedente;
        mail.innerHTML = this.state.ricevuta.email_cedente;
        documento.innerHTML = this.state.ricevuta.tipo_documento_cedente.replace("_", " ") + " - " + this.state.ricevuta.numero_documento_cedente;
        codice_fiscale.innerHTML = this.state.ricevuta.codice_fiscale_cedente;
        cognome.innerHTML = anagrafica[1];


        if (this.state.ricevuta.id_tipo_contratto === 1 || this.state.ricevuta.id_tipo_contratto === 2) {
            svgElement.getElementById("bollo1").style.display = "none";
        }
        if (this.state.ricevuta.id_tipo_contratto === 3 || this.state.ricevuta.id_tipo_contratto === 4) {
            svgElement.getElementById("bollo1").style.display = "none";
            svgElement.getElementById("bollo2").style.display = "none";
            svgElement.getElementById("bollo3").style.display = "none";
            svgElement.getElementById("bollo4").style.display = "none";
        }

        color.innerHTML = this.state.device.color;
        memory.innerHTML = this.state.device.memory_gb;
        seriale.innerHTML = this.state.device.serial_number;
        modello.innerHTML = this.state.device.model_name;
        imei.innerHTML = this.state.device.imei;
    };

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    inserisciDatiBuono = () => {
        let doc = document.getElementById("buono");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const NS = "http://www.w3.org/2000/svg";

        const valore_buono_stampato = svgElement.getElementById("valore_buono_stampato");
        const seriale_buono_stampato = svgElement.getElementById("seriale_buono_stampato");
        const data_scadenza_stampato = svgElement.getElementById("data_scadenza_stampato");
        const data_emissione_stampato = svgElement.getElementById("data_emissione_stampato");
        let frase_affiliato = svgElement.getElementById("frase_affiliato");
        const frase_affiliato_2 = svgElement.getElementById("frase_affiliato_2");

        valore_buono_stampato.innerHTML = this.state.ricevuta.coupon_value + " €";
        seriale_buono_stampato.innerHTML = this.state.ricevuta.coupon_serial_number;
        data_scadenza_stampato.innerHTML = this.formatDate(this.state.ricevuta.data_scadenza_coupon);
        data_emissione_stampato.innerHTML = this.formatDate(this.state.ricevuta.data_acquisto);
        let frasi = this.state.ricevuta.coupon_text.split('<LI>');
        frase_affiliato.textContent = frasi[7].split("esclusivamente")[1].split("</LI>")[0];
        if (frase_affiliato.textContent.length > 70) {
            let parti = frase_affiliato.textContent.split(",");
            frase_affiliato.textContent = parti[0];
            frase_affiliato_2.textContent = parti[1];
        }


        var barcode = svgElement.getElementById("barcode");
        JsBarcode(barcode, this.state.ricevuta.coupon_serial_number, {
            displayValue: false
        });
        barcode.setAttributeNS(null, 'x','314.867');
        barcode.setAttributeNS(null, 'y','358.496');
        barcode.setAttributeNS(null, 'width','174.93');
        barcode.setAttributeNS(null, 'height','65.66');

        var barcode_prezzo = svgElement.getElementById("barcode_prezzo");
        JsBarcode(barcode_prezzo, this.state.ricevuta.coupon_value, {
            displayValue: false
        });
        barcode_prezzo.setAttributeNS(null, 'x','335.38');
        barcode_prezzo.setAttributeNS(null, 'y','220.80287');
        barcode_prezzo.setAttributeNS(null, 'width','79.93');
        barcode_prezzo.setAttributeNS(null, 'height','44.66');

        barcode = svgElement.getElementById("barcodeMarca");
        JsBarcode(barcode, this.state.device.brand_name, {
            // displayValue: true
            text: 'Marca: ' + this.state.device.brand_name
        });
        barcode.setAttributeNS(null, 'x','84.867');
        barcode.setAttributeNS(null, 'y','328.496');
        barcode.setAttributeNS(null, 'width','174.93');
        barcode.setAttributeNS(null, 'height','45.66');

        barcode = svgElement.getElementById("barcodeModello");
        JsBarcode(barcode, this.state.device.model_name, {
            // displayValue: true
            text: 'Modello: ' + this.state.device.model_name
        });
        barcode.setAttributeNS(null, 'x','84.867');
        barcode.setAttributeNS(null, 'y','378.496');
        barcode.setAttributeNS(null, 'width','174.93');
        barcode.setAttributeNS(null, 'height','45.66');

        barcode = svgElement.getElementById("barcodeImei");
        JsBarcode(barcode, this.state.device.imei, {
            // displayValue: true
            text: 'Imei: ' + this.state.device.imei
        });
        barcode.setAttributeNS(null, 'x','84.867');
        barcode.setAttributeNS(null, 'y','428.496');
        barcode.setAttributeNS(null, 'width','174.93');
        barcode.setAttributeNS(null, 'height','45.66');

    };

    compilaFirmaUno = () => {
        let doc = document.getElementById("ricevuta_acquisto_firma");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const NS = "http://www.w3.org/2000/svg";
        var firma_uno = document.createElementNS(NS, "image");
        firma_uno.setAttributeNS('http://www.w3.org/1999/xlink', "href", this.state.firma_uno);
        firma_uno.setAttributeNS(null,'height','37.42');
        firma_uno.setAttributeNS(null,'width','192.76');
        firma_uno.setAttributeNS(null,'x','363.35');
        firma_uno.setAttributeNS(null,'y','600.38');
        firma_uno.setAttributeNS(null, 'visibility', 'visible');
        svgElement.appendChild(firma_uno);
    };

    compilaFirmaDue = (ricevuta) => {
        let doc = document.getElementById("ricevuta_acquisto_firma_due");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const NS = "http://www.w3.org/2000/svg";
        var firma_uno = document.createElementNS(NS, "image");
        firma_uno.setAttributeNS('http://www.w3.org/1999/xlink', "href", this.state.firma_due);
        firma_uno.setAttributeNS(null,'height','37.42');
        firma_uno.setAttributeNS(null,'width','192.76');
        firma_uno.setAttributeNS(null,'x','359.7');
        firma_uno.setAttributeNS(null,'y','227.07');
        firma_uno.setAttributeNS(null, 'visibility', 'visible');
        svgElement.appendChild(firma_uno);

        const luogo_firma = svgElement.getElementById("luogo_firma_due");
        luogo_firma.innerHTML = ricevuta.luogo_acquisto;
        const data_firma = svgElement.getElementById("data_firma_due");
        data_firma.innerHTML = this.formatDate(ricevuta.data_acquisto);
    };


    compilaFirma = (ricevuta) => {
        let doc = document.getElementById("ricevuta_acquisto_firma");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const NS = "http://www.w3.org/2000/svg";

        if (this.state.ricevuta.is_coupon) {
            const punto_contanti = svgElement.getElementById("punto_contanti").style.display = "none";
            const frase_contanti = svgElement.getElementById("frase_contanti").style.display = "none";
            const valore_contanti = svgElement.getElementById("valore_contanti").style.display = "none";
            const oppure = svgElement.getElementById("oppure").style.display = "none";
            const importo_contanti = svgElement.getElementById("importo_contanti").style.display = "none";
            const valore_buono = svgElement.getElementById("valore_buono").innerHTML = "";
            const importo_buono = svgElement.getElementById("importo_buono").innerHTML = ricevuta.prezzo_acquisto;
            const data_scadenza_buono = svgElement.getElementById("data_scadenza_buono").innerHTML = this.formatDate(this.state.ricevuta.data_scadenza_coupon.substring(0, 10));
            const punto_buono_finale = svgElement.getElementById("punto_buono_finale").innerHTML = "";
            const punto_virgola = svgElement.getElementById("punto_virgola").style.display = "none";
        } else {
            const punto_buono = svgElement.getElementById("punto_buono").style.display = "none";
            const buono_riga_uno = svgElement.getElementById("buono_riga_uno").style.display = "none";
            const buono_scadenza = svgElement.getElementById("buono_scadenza").style.display = "none";
            const buono_riga_due = svgElement.getElementById("buono_riga_due").style.display = "none";
            const valore_buono = svgElement.getElementById("valore_buono").innerHTML = "";
            const oppure = svgElement.getElementById("oppure").style.display = "none";
            const importo_buono = svgElement.getElementById("importo_buono").innerHTML = "";
            const data_scadenza_buono = svgElement.getElementById("data_scadenza_buono").innerHTML = "";
            const punto_buono_finale = svgElement.getElementById("punto_buono_finale").innerHTML = "";
            const importo_contanti = svgElement.getElementById("importo_contanti");
            importo_contanti.innerHTML = ricevuta.prezzo_acquisto;
        }

        const data_firma = svgElement.getElementById("data_firma");
        data_firma.innerHTML = this.formatDate(ricevuta.data_acquisto);
        const luogo_firma = svgElement.getElementById("luogo_firma");
        luogo_firma.innerHTML = ricevuta.luogo_acquisto;
    };

    inserisciScansioni = (ricevuta) => {
        let doc = document.getElementById("scansioni");
        let svgDoc = doc.contentDocument;
        let svgElement = svgDoc.getElementById("Livello_1");
        const NS = "http://www.w3.org/2000/svg";

        var scansioneUno = document.createElementNS(NS, "image");
        scansioneUno.setAttributeNS('http://www.w3.org/1999/xlink', "href", this.state.fronte);
        scansioneUno.setAttributeNS(null,'height','288.18');
        scansioneUno.setAttributeNS(null,'width','214.37');
        scansioneUno.setAttributeNS(null,'x','89.56');
        scansioneUno.setAttributeNS(null,'y','478.56');
        scansioneUno.setAttributeNS(null, 'visibility', 'visible');
        svgElement.appendChild(scansioneUno);

        var scansioneDue = document.createElementNS(NS, "image");
        scansioneDue.setAttributeNS('http://www.w3.org/1999/xlink', "href", this.state.retro);
        scansioneDue.setAttributeNS(null,'height','288.18');
        scansioneDue.setAttributeNS(null,'width','214.37');
        scansioneDue.setAttributeNS(null,'x','346.14');
        scansioneDue.setAttributeNS(null,'y','478.56');
        scansioneDue.setAttributeNS(null, 'visibility', 'visible');
        svgElement.appendChild(scansioneDue);

        var firma_privacy = document.createElementNS(NS, "image");
        firma_privacy.setAttributeNS('http://www.w3.org/1999/xlink', "href", this.state.firma_privacy);
        firma_privacy.setAttributeNS(null,'height','37.42');
        firma_privacy.setAttributeNS(null,'width','192.76');
        firma_privacy.setAttributeNS(null,'x','143.7');
        firma_privacy.setAttributeNS(null,'y','388.22');
        firma_privacy.setAttributeNS(null, 'visibility', 'visible');
        svgElement.appendChild(firma_privacy);

        var privacy_due_si = svgDoc.getElementById("privacy_due_si");
        var privacy_due_no = svgDoc.getElementById("privacy_due_no");
        var privacy_tre_si = svgDoc.getElementById("privacy_tre_si");
        var privacy_tre_no = svgDoc.getElementById("privacy_tre_no");

        privacy_due_si.removeAttribute('onclick');
        privacy_due_no.removeAttribute('onclick');
        privacy_tre_si.removeAttribute('onclick');
        privacy_tre_no.removeAttribute('onclick');

        if (this.state.ricevuta.secondo_flag === 1) {
            privacy_due_si.textContent = "x";
            privacy_due_no.textContent = " ";
        } else {
            privacy_due_si.textContent = " ";
            privacy_due_no.textContent = "x";
        }

        if (this.state.ricevuta.terzo_flag === 1) {
            privacy_tre_si.textContent = "x";
            privacy_tre_no.textContent = " ";
        } else {
            privacy_tre_si.textContent = " ";
            privacy_tre_no.textContent = "x";
        }


    };

    render() {

        let ricevutaCompleta = null;

        let spinner = null;
        if (this.state.loading) {
            spinner = <Spinner />
        }

        if (this.state.firma_privacy) {
            ricevutaCompleta = (
                <Auxiliary className={classes.Centered}>
                    {spinner}
                    <div className={classes.NoPrint}>
                        <Button clicked={this.savePdf.bind(this)} disabled={this.state.isMailInvita} >INVIA RICEVUTA VIA MAIL</Button>
                        <Button clicked={this.stampaRicevuta.bind(this)} style={{marginLeft: 20}}>STAMPA RICEVUTA</Button>
                    </div>
                    <div className={classes.Print} id="content">
                        <object data={IMAGES.doc1} id="pagina_uno" type="image/svg+xml"/>
                        <object data={IMAGES.doc2} id="ricevuta_acquisto" type="image/svg+xml" onLoad={() => this.compila(this.state.ricevuta)}/>
                        <object data={IMAGES.doc3} id="pagina_tre" type="image/svg+xml" onLoad={() => this.compilaFirmaUno(this.state.ricevuta)}/>
                        <object data={IMAGES.doc4} id="ricevuta_acquisto_firma" type="image/svg+xml" onLoad={() => this.compilaFirma(this.state.ricevuta)}/>
                        <object data={IMAGES.doc5} id="ricevuta_acquisto_firma_due" type="image/svg+xml" onLoad={() => this.compilaFirmaDue(this.state.ricevuta)} />
                        <object data={IMAGES.doc6} id="pagina_sei" type="image/svg+xml"/>
                        <object data={IMAGES.doc7} id="pagina_sette" type="image/svg+xml"/>
                        <object data={IMAGES.doc8} id="scansioni" type="image/svg+xml" onLoad={() => this.inserisciScansioni()}/>
                        {this.state.ricevuta.is_coupon ? <object data={IMAGES.doc9} id="buono" type="image/svg+xml" onLoad={() => this.inserisciDatiBuono()} /> : null }
                    </div>
                </Auxiliary>
            );

        }

        return (
            <Auxiliary>
                {ricevutaCompleta}
            </Auxiliary>
        )
    }
}

export default Ricevuta;

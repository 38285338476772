import React from 'react'
import { TextField } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";

const textField = (props) => {

    let inputElement = null;
    let validationError = null;

    // if (props.invalid && props.shouldValidate && props.touched) {
    //     inputClasses.push(classes.Invalid);
    //     validationError = <p className={classes.ValidationError}>{props.validationError}</p>;
    // }
    // if (!props.invalid && props.shouldValidate && props.touched) {
    //     inputClasses.push(classes.Valid);
    // }

    switch (props.elementType) {
        case ('input'):
            inputElement = <TextField
                name={props.name}
                fullWidth
                style={props.style}
                id={props.id}
                input
                variant="outlined"
                value={props.value}
                label={props.label}
                type={props.type}
                {...props.elementConfig}
                onChange={props.changed} />;
            break;
        // case ('textarea'):
        //     inputElement = <TextField inputComponent="textarea" {...props.elementConfig} value={props.value} onChange={props.changed}/>;
        //     break;
        case ('select'):
            inputElement = (
                <TextField
                    id={props.id}
                    style={{margin: 10}}
                    select
                    fullWidth
                    variant="outlined"
                    value={props.value}
                    label={props.label}
                    {...props.elementConfig}
                    onChange={props.changed} >
                    {props.options.map(op => (
                        <MenuItem key={op.value} value={op.value} disabled={op.disabled}>{op.displayValue}</MenuItem>
                    ))}
                </TextField>);
            break;
        default:
            inputElement = <TextField
                fullWidth
                name={props.name}
                style={props.style}
                id={props.id}
                input
                variant="outlined"
                value={props.value}
                label={props.label}
                type={props.type}
                {...props.elementConfig}
                onChange={props.changed} />;
    }

    return (
        <div>
            {inputElement}
            {validationError}
        </div>
    )
};

export default textField;

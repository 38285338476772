import React, {Component} from "react";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import instance from "../../../axios-spc";

class Device extends Component {

    state = {
        device: [],
        reclamoUno: null,
        reclamoDue: null
    };

    componentDidMount() {
        // chiamata a picea
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            serial_number: this.props.id
        };
        instance.post('/getDevice', postData).then(resp => {
            let result = [];
            for (let i in resp.data[0]) {
                result.push([(<strong> {i.replace("_", " ") + ": "} </strong>), resp.data[0][i]])
            }
            this.setState({device: result})
        });

        // var img = {
        //     reclamo_uno: '/opt/media/spc/reclami/reclamo_uno_' + this.props.id + ".png",
        //     reclamo_due: '/opt/media/spc/reclami/reclamo_due_' + this.props.id + ".png",
        // };
        // axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/getReclamoImage', img, axiosConfig).then(resp => {
        //     console.log(resp);
        //     this.setState({reclamoUno: resp.data.fronte, reclamoDue: resp.data.retro})
        // });
    }

    render() {
        let image1 = null;
        let image2 = null;
        let device = null;
        // if (this.state.reclamoUno !== 'data:image/png;base64,') {
        //     image1 = (<img style={{maxWidth: '200px'}} src={this.state.reclamoUno}/>)
        // }
        // if (this.state.reclamoDue !== 'data:image/png;base64,') {
        //     image2 = (<img style={{maxWidth: '200px'}} src={this.state.reclamoDue}/>)
        // }
        if (this.state.device.length > 0) {
            device = this.state.device.map((item, key) => (

                <li key={key}>{item}</li>
            ));
        }
        return (
            <Auxiliary>
                <div style={{columns: 2}}>
                    <ul>{device}</ul>
                </div>
                <div style={{columns: 2}}>
                    {image1}
                    {image2}
                </div>


            </Auxiliary>
        );
    }

}

export default Device

import React, {Component} from "react";
import classes from './../Coupons.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../../store/actions";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import instance from "../../../axios-spc";
import Snackbar from "../../../components/UI/Snackbar/Snackbar";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Icon from "../../../components/UI/CustomIcon/CustomIcon";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {TextField} from "@material-ui/core";
import Button from '../../../components/UI/Button/Button'

class BurnCoupon extends Component{

    state = {
        couponUtilizzato: null,
        confermaUtilizzo: null,
        codiceCoupon: null,
        expandedAccordion: true
    };

    componentDidMount() {
        this.props.onAuthCheckState();
    }

    handleToogle() {
        let expanded = !this.state.expandedAccordion;
        this.setState({expandedAccordion: expanded})
    }

    burnCoupon = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            id_operatore: localStorage.getItem("userId"),
            coupon_serial_number: this.state.codiceCoupon
        };
        instance.post('/burnCoupon', postData).then(resp => {
            // axios.post('http://localhost:8989/api/burnCoupon', postData, axiosConfig).then(resp => {
            this.setState({couponUtilizzato: resp.data[0].ret_code, confermaUtilizzo: resp.data[0].ret_message.replaceAll("_", " "), codiceCoupon: null});
        });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({couponUtilizzato: null, confermaUtilizzo: null})
    };


    inputonChangeHandler = (event, id) => {
        this.setState({codiceCoupon: event.target.value});
    };

    render() {
        let confermaUtilizzoCoupon = null;
        if (this.state.couponUtilizzato === 0) {
            confermaUtilizzoCoupon = (
                <Snackbar open={true} closed={this.handleClose} severity="success">Coupon utilizzato con successo</Snackbar>
            )
        } else if (this.state.couponUtilizzato >= 1 && this.state.couponUtilizzato <= 5) {
            confermaUtilizzoCoupon = (
                <Snackbar className={classes.CouponSnackbar} open={true} closed={this.handleClose} severity="error">{this.state.confermaUtilizzo}</Snackbar>
            )
        }

        let authRedirect = null;
        console.log('[Coupon] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }

        let couponForm = (
            <div className={classes.CouponField}>
                <TextField
                    id="coupon"
                    label="Inserisci codice coupon da utilizzare"
                    variant="outlined"
                    style={{margin: 10, display: 'block'}}
                    fullWidth
                    value={this.state.codiceCoupon}
                    onChange={(e) => this.inputonChangeHandler(e)}
                />
                <Button clicked={this.burnCoupon}>Utilizza Coupon</Button>
            </div>
        );


        return (
            <Auxiliary>
                {authRedirect}
                {confermaUtilizzoCoupon}
                <Accordion expanded={this.state.expandedAccordion}>
                    <AccordionSummary className={classes.AccordionIcon} onClick={() => this.handleToogle()} expandIcon={this.state.expandedAccordion ? <Icon image="accordion_aperto"/> : <Icon image="accordion_chiuso"/>} style={{textAlign: 'center', display: 'inline-flex'}}>
                        <h2 className={classes.Title}>UTILIZZA COUPON</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        {couponForm}
                    </AccordionDetails>
                </Accordion>
            </Auxiliary>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/coupon"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(BurnCoupon);

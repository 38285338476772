import React, {Component} from 'react';
import Button from '../../components/UI/Button/Button'
import classes from './Auth.css'
import * as actions from '../../store/actions/index'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {TextField} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import IMAGES from "../../store/images";
import instance from "../../axios-spc";
import Snackbar from "../../components/UI/Snackbar/Snackbar";
import Modal from "../../components/UI/Modal/Modal";
import FormContatto from "../FromContatto/FormContatto";

class Auth extends Component {

    state = {
        controls: {
            username: {
                elementType: 'input',
                elementConfig: {
                    type: 'username',
                    placeholder: 'Username',
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false,
                validationError: ''
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false,
                validationError: ''
            }
        },
        formIsValid: false,
        isSignUp: false,
        isFormContattoVisibile: false,
        isLoginError: false
    };

    componentDidMount() {
        this.props.onAuthCheckState();
    }

    showFormContatto() {
        let oldValue = {...this.state};
        let newValue = !oldValue.isFormContattoVisibile;
        this.setState({isFormContattoVisibile: newValue})
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({isFormContattoInviato: false})
    };

    checkValidity(value, rules) {
        let isValid = true;
        if (rules) {
            if (rules.required) {
                isValid = value.trim() !== '' && isValid;
            }
            if (rules.minLength) {
                isValid = value.length >= rules.minLength && isValid
            }
            if (rules.maxLength) {
                isValid = value.length <= rules.maxLength && isValid
            }
        }
        return isValid
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.controls
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        if (!updatedFormElement.valid) {
            if (inputIdentifier == 'username') updatedFormElement.validationError = '';
        } else {
            updatedFormElement.validationError = '';
        }
        updatedForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputId in updatedForm) {
            formIsValid = updatedForm[inputId].valid && formIsValid
        }
        this.setState({controls: updatedForm, formIsValid: formIsValid})
    };

    submitHandler = (event) => {
        this.props.onAuth(this.state.controls.username.value, this.state.controls.password.value, this.state.isSignUp)
    };


    ricercaCancelHandler = () => {
        this.setState({isFormContattoVisibile: false, isLoginError: false})
    };

    sendFormContatto = () => {
        let postData = {
            id_contatto: null,
            riferimento: this.state.formContatto.nome.value,
            email: this.state.formContatto.email.value,
            telefono: this.state.formContatto.telefono_cellulare.value,
            id_tipo_attivita: this.state.tipo_attivita.value,
            note_contatto: this.state.formContatto.messaggio.value,
            id_stato_contatto: null,
            id_operatore: localStorage.getItem("userId"),
            id_affiliato: null,
            note_operatore: null
        };
        instance.post('/setFormContatto', postData).then(resposne => {
            // axios.post('http://localhost:8989/api/setFormContatto', postData, axiosConfig).then( (resposne) => {
            console.log(resposne.data);
            this.setState({isFormContattoVisibile: false, isFormContattoInviato: true})
        });
    };

    render() {
        const formElementArray = [];
        for (let key in this.state.controls) {
            formElementArray.push({
                id: key,
                config: this.state.controls[key]
            })
        }

        let form = formElementArray.map(el => (

            <TextField
                key={el.id}
                label={el.config.elementConfig.placeholder}
                variant="outlined"
                value={el.config.value}
                // fullWidth
                type={el.id == "password" ? "password" : "input" }
                style={{backgroundColor: 'white', margin: 10 }}
                error={!el.config.valid && el.config.touched}
                helperText={el.config.validationError}
                touched={el.config.touched}
                size={"small"}
                onChange={(e, id) => this.inputChangedHandler(e, el.id)}
            />
        ));

        let errorMessage = null;
        if (this.props.error) {
            errorMessage = (
                <Snackbar open={this.props.error != null} closed={this.props.onAuthFail} severity="error">{this.props.error.replaceAll("_", " ")}</Snackbar>
            )
        }

        let authRedirect = null;
        console.log(this.props);
        if (this.props.isAuth) {
            authRedirect = <Redirect to="/credentials" />
        }

        let formContatto = (
            <Modal show={this.state.isFormContattoVisibile} modalClosed={this.ricercaCancelHandler} customStyle={"Creazione Anagrafica"}>
                <FormContatto />
            </Modal>
        );


        let buttonStyle = {
            marginTop: '2%',
            maxHeight: '34px'
        };


        return (
            <Auxiliary>
                <header style={{maxHeight: '55px'}}>
                    <AppBar style={{maxHeight: '55px'}} classes={classes.HPToolbar}><Toolbar style={{maxHeight: '55px'}} classes={classes.HPToolbar}>
                        <form style={{display: 'flex', marginLeft: '70%'}} onSubmit={this.submitHandler}>
                            {form}
                            <Button clicked={this.submitHandler} disabled={!this.state.formIsValid} style={buttonStyle}>Log In</Button>
                        </form>
                    </Toolbar></AppBar>
                </header>
                <div className={classes.DocContainer}>
                    {authRedirect}
                    {errorMessage}
                    <object data={IMAGES.homePage} type="image/svg+xml" />
                </div>
                <hr />
                <div>
                    <Button clicked={() => this.showFormContatto()}>CONTATTACI</Button>
                    <br />
                    <div>
                        {formContatto}
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuth: state.auth.token !== null
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, isSignUp) => dispatch(actions.auth(email, password, isSignUp)),
        onAuthCheckState: () => dispatch(actions.authCheckState()),
        onAuthFail: () => dispatch(actions.authFail(null))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);

import React, {Component} from "react";
import classes from './Distinte.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from "@material-ui/data-grid";
import Button from '../../components/UI/Button/Button'
import Modal from "../../components/UI/Modal/Modal";
import Distinta from '../Distinte/Distinta/Distinta'
import instance from "../../axios-spc";
import Snackbar from "../../components/UI/Snackbar/Snackbar";

class Distinte extends Component{

    state = {
        distinte: [
        ],
        pageSize: 5,
        confirm: false,
        visualizzaScheda: false,
        idDistintaDettaglio: null,
        headers: [
            {field: 'id', headerName: 'Id', flex: 1, hide: true},
            {field: 'ragione_sociale', headerName: 'Ragione Sociale', flex: 1},
            {field: 'negozio', headerName: 'Negozio', flex: 1.5},
            {field: 'tipo_distinta', headerName: 'Tipo', flex: 0.5},
            {field: 'data_creazione', headerName: 'Data Creazione', flex: 0.7},
            {field: 'data_pagamento', headerName: 'Data Pagamento', flex: 0.7},
            {field: 'valore', headerName: 'Valore', flex: 0.5},
            {field: 'pagata', headerName: 'Pagata', flex: 0.5},
            {
                field: '',
                headerName: 'Azione',
                sortable: false,
                flex: 0.7,
                hide: localStorage.getItem("role") < 3,
                renderCell: (params) => {
                    let disabled = false;
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.setDistintaPagata(thisRow["id"], thisRow["tipo_distinta"]);
                    };
                    disabled = params.getValue("pagata") == 1;
                    if (localStorage.getItem("role") >= 3)
                        return <Button clicked={onClick} disabled={disabled}>Paga</Button>;
                }
            },
        ]
    };

    componentDidMount() {
        this.getDistinte();
    }

    setDistintaPagata = (id, tipo) => {
        let postData = {
            id_distinta: id,
            is_rimborso: tipo == 'rimborso' ? 1 : 0,
            id_operatore: localStorage.getItem("userId")
        };

        instance.post('/setDistintaPagata', postData).then(resp => {
            // axios.post('http://localhost:8989/api/setDistintaPagata', postData, axiosConfig).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.getDistinte();
                this.setState({confirm: true})
            }
        });
    };

    getDistinte = () =>  {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio")
        };

        instance.post('/getDistinte', postData).then(resp => {
        // axios.post('http://localhost:8989/api/getDistinte', postData, axiosConfig).then(resp => {
            let filteredResponse = resp.data.filter( distinta => distinta.id_distinta !== null);
            for (let i in filteredResponse) {
                filteredResponse[i].id = filteredResponse[i].id_distinta;
                filteredResponse[i].pagata = filteredResponse[i].pagata == 1 ? "Si" : "No";
                filteredResponse[i].data_creazione = this.formatDate(filteredResponse[i].data_creazione);
                filteredResponse[i].data_pagamento = this.formatDate(filteredResponse[i].data_pagamento);

            }
            this.setState({distinte: filteredResponse})
        });
    };


    formatDate(value) {
        if (value) {
            let date = new Date(value);
            const day = date.toLocaleString('default', {day: '2-digit'});
            const month = date.toLocaleString('default', {month: '2-digit'});
            const year = date.toLocaleString('default', {year: 'numeric'});
            return day + '-' + month + '-' + year;
        }
    }

    visualizzaSchedaCancelHandler = () => {
        this.setState({visualizzaScheda: false, idDistintaDettaglio: null})
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({confirm: false});
    };

    render() {

        let confirmAlert = (
            <Snackbar open={this.state.confirm} closed={this.handleClose} severity="success">Distinta pagata correttamente!</Snackbar>
        );

        let schedaCompleta = null;
        if (this.state.visualizzaScheda) {
            schedaCompleta = (
                <Modal show={this.state.visualizzaScheda} modalClosed={this.visualizzaSchedaCancelHandler} title={"Distinta " + this.state.idDistintaDettaglio} >
                    <Distinta id={this.state.idDistintaDettaglio} />
                </Modal>
            )
        }

        let authRedirect = null;
        console.log('[Distinte] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }

        return (
                <div className={classes.Distinte}>
                    {authRedirect}
                    {schedaCompleta}
                    {confirmAlert}
                    <h1 className={classes.Title}>DISTINTE</h1>
                    <div style={{height: 'auto', width: '100%'}} >
                        <DataGrid autoHeight={true} rows={this.state.distinte} columns={this.state.headers} pageSize={5} hideFooterSelectedRowCount={true} components={{Toolbar: GridToolbar}}/>
                    </div>
                </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/distinte"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Distinte);

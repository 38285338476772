import * as actionTypes from '../actions/actionTypes'
import {updateObject} from "../utility";

const initialState = {
    error: null,
    loading: false,
    piceaData: null
};


const inserimentoPiceaStart = (state, action) => {
    return updateObject(state, {error: null, loading: true})
};

const inserimentoPiceaOk = (state, action) => {
    return updateObject(state, {
        piceaData: action.piceaData,
        error: null,
        loading: false
    })
};

const inserimentoPiceaFail = (state, action) => {
    return updateObject(state, {error: true, loading: true})
};

const inserimentoPiceaReset = (state, action) => {
    return updateObject(state, {
        piceaData: null,
        error: null,
        loading: false
    })
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INSERIMENTO_PICEA_OK: return inserimentoPiceaOk(state, action);
        case actionTypes.INSERIMENTO_PICEA_START: return inserimentoPiceaStart(state, action);
        case actionTypes.INSERIMENTO_PICEA_FAIL: return inserimentoPiceaFail(state, action);
        case actionTypes.INSERIMENTO_PICEA_RESET: return inserimentoPiceaReset(state, action);
        default: return state
    }
};

export default reducer;

import React,{Component} from 'react';
import classes from './Footer.css';
import styled from 'styled-components'


const FooterComponent = styled.div`
  text-align: center;
  position: flex;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 90px !important;
  background: #c1ca80;
  flex-direction: column;
  margin-top:calc(10% + 60px);
`;

class Footer extends Component {

    render() {
        return (
            <FooterComponent>
                <div className={classes.Font}>
                    <h3><strong>CONTATTI</strong></h3>
                    <strong className={classes.Title}>Amministrazione</strong>: <a href="mailto:commerciale@smartphonecash.it" style={{textDecoration: "none", color: "black"}}>commerciale@smartphonecash.it</a>
                    <br />
                    <strong className={classes.Title}>Assistenza</strong>: <a href="mailto:supporto@smartphonecash.it" style={{textDecoration: "none", color: "black"}}>supporto@smartphonecash.it</a>
                </div>
            </FooterComponent>
        )
    }
}

export default Footer;

import * as actionTypes from '../actions/actionTypes'
import {updateObject} from "../utility";

const initialState = {
    value: 0,
    panels: [
        {label: "Acquisto", url: "/buy-device", value: 0},
        {label: "Spedizioni", url: "/spedizioni", value: 1},
        {label: "Distinte", url: "/distinte", value: 2},
        {label: "Magazzino", url: "/magazzino", value: 3},
        {label: "Ricevute", url: "/ricevute", value: 4},
        {label: "Coupon", url: "/coupon", value: 5}
    ]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default: return state
    }
};

export default reducer;

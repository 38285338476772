import React from 'react';
import classes from './Logo.css';
import burgerLogo from '../../assets/burger-logo.png';

const logo = (props) => (
    <div className={classes.Logo}>
        {props.logoSmall ? null : <p> {props.operatore} | {props.negozio}</p>}
        <img src={burgerLogo} alt="MyBurger" />
    </div>
);

export default logo;

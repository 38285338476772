import React, {Component} from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from "../../../components/UI/Button/Button";
import {DataGrid, GridApi, GridCellValue, GridToolbar} from '@material-ui/data-grid';
import Snackbar from "../../../components/UI/Snackbar/Snackbar";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import classes from "../Fatture.css";
import Icon from "../../../components/UI/CustomIcon/CustomIcon";
import instance from "../../../axios-spc";
import Modal from "../../../components/UI/Modal/Modal";
import Device from "../../Magazzino/Device/Device";

class FatturePagate extends Component{


    state = {
        fatture: [
        ],
        headers: [
            {field: 'id', headerName: 'ID Assoluto', flex: 1, hide: true},
            {field: 'id_fattura_su_anno', headerName: 'ID', flex: 1,},
            {field: 'ragione_sociale', headerName: 'Affiliato', flex: 1},
            {field: 'negozio', headerName: 'Negozio', flex: 1},
            {field: 'data_fattura', headerName: 'Data', flex: 1},
            {field: 'periodo_riferimento', headerName: 'Rif.', flex: 1},
            {field: 'oggetto', headerName: 'Oggetto', flex: 1},
            {field: 'importo_lordo', headerName: 'Lordo', flex: 1},
            {
                field: 'dettaglio',
                headerName: 'Dettaglio',
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.visualizzaFattura(thisRow["id"]);
                    };

                    return <Button clicked={onClick}>Visualizza</Button>;
                }
            },
        ],
        pageSize: 5,
        confirm: false,
        visualizzaDocumento: false,
        idFatturaDettaglio: null,
        expandedAccordion: false
    };


    componentDidMount() {
        var postData = {
            id_affiliato: null,
            id_negozio: null,
            date_start: null,
            date_end: null,
            only_pending: 1
        };

        instance.post('/getFattureLista', postData).then(resp => {
        // axios.post('http://localhost:8989/api/getFattureLista', postData, axiosConfig).then(resp => {
            let filteredResponse = resp.data.filter( distinta => distinta.id_fattura !== null);
            for (let i in filteredResponse) {
                filteredResponse[i].id = filteredResponse[i].id_fattura;
                filteredResponse[i].data_fattura = this.formatDate(filteredResponse[i].data_fattura);
            }
            this.setState({fatture: filteredResponse});
        });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return ;
        }
        this.setState({confirm: false});
    };


    visualizzaFattura = (id) => {
        this.setState({visualizzaScheda: true, idFatturaDettaglio: id});
        // window.open("https://www.smartphonecash.it/stampaFattura?id=" + id);
        window.open("http://smartphonecash.it/stampaFattura?id=" + id);
    };

    handleToogle() {
        let expanded = !this.state.expandedAccordion;
        this.setState({expandedAccordion: expanded})
    }

    confermaFatturaPagata = (id) => {
        var postData = {
            id_operatore: localStorage.getItem("userId"),
            id_fattura: id
        };
        instance.post('/setFatturaPagata', postData).then(resp => {
        // axios.post('http://localhost:8989/api/setFatturaPagata', postData, axiosConfig).then(resp => {
            if (resp.data[0].ret_code === 0) {
                let idx = this.state.fatture.findIndex( (el) => el.id === id );
                const updatedState = {...this.state};
                const updatedFatture = [...updatedState.fatture];
                updatedFatture.splice(idx, 1);
                this.setState({fatture: updatedFatture, confirm: true});
            }
        });
    };

    formatDate(value) {
        if (value) {
            let date = new Date(value);
            const day = date.toLocaleString('default', {day: '2-digit'});
            const month = date.toLocaleString('default', {month: '2-digit'});
            const year = date.toLocaleString('default', {year: 'numeric'});
            return day + '-' + month + '-' + year;
        }
    }

    render() {

        let confirmAlert = (
            <Snackbar open={this.state.confirm} closed={this.handleClose} severity="success">Fattura segnata come pagata</Snackbar>
        );

        let docCompleto = null;
        if (this.state.visualizzaDocumento) {
            docCompleto = (
                <Modal show={this.state.visualizzaDocumento} modalClosed={this.visualizzaSchedaCancelHandler} title={"Device " + this.state.idDeviceDettaglio} >
                    <Device id={this.state.idDeviceDettaglio} />
                </Modal>
            )
        }
        let sped = (
            <div style={{height: 'auto', width: '100%'}} >
                <DataGrid autoHeight={true} rows={this.state.fatture} columns={this.state.headers} pageSize={5} />
            </div>
        );

        return (
            <Auxiliary>
                {confirmAlert}
                {docCompleto}
                <Accordion >
                    <AccordionSummary className={classes.AccordionIcon} onClick={() => this.handleToogle()} expandIcon={this.state.expandedAccordion ? <Icon image="accordion_aperto"/> : <Icon image="accordion_chiuso"/>} style={{textAlign: 'center', display: 'inline-flex'}}>
                        <h2 className={classes.Title}>FATTURE PAGATE</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        {sped}
                    </AccordionDetails>
                </Accordion>
            </Auxiliary>
        );
    }
}



export default (FatturePagate);

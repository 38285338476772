import React, {Component} from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import classes from './../Spedizioni.css';
import Button from '../../../components/UI/Button/Button'
import {DataGrid, GridApi, GridCellValue, GridToolbar} from "@material-ui/data-grid";
import Modal from "../../../components/UI/Modal/Modal";
import ContenutoSpedizione from "../../Spedizioni/StoricoSpedizioni/ContenutoSpedizione/ContenutoSpedizione";
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import Icon from '../../../components/UI/CustomIcon/CustomIcon'
import instance from "../../../axios-spc";
import CustomToolbar from "../../../components/UI/CustomToolbar/CustomToolbar";

class StoricoSpedizioni extends Component{


    state = {
        spedizioni: [
        ],
        headers: [
            {field: 'id', headerName: 'ID', flex: 0.4},
            {field: 'data_richiesta_spedizione_magcent', headerName: 'Data', flex: 1},
            {field: 'motivo_spedizione_magcent', headerName: 'Motivo', flex: 1},
            {field: 'tracking_spedizione', headerName: 'Track', flex: 0.6, hide: true},
            {field: 'numero_devices', headerName: 'Num. Devices', flex: 0.6},
            {
                field: 'contenuto',
                headerName: 'Contenuto',
                sortable: false,
                flex: 0.7,
                renderCell: (params) => {
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.openModal(thisRow["id"]);
                    };
                    return <Button clicked={onClick}>Contenuto</Button>;
                }
            },
            {
                field: 'ddt',
                headerName: 'DDT',
                sortable: false,
                flex: 0.7,
                renderCell: (params) => {
                    let disabled = false;
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.openDdt(thisRow["id"]);
                    };
                    return <Button clicked={onClick} disabled={disabled}>D.D.T.</Button>;
                }
            },
            {
                field: 'tracking',
                headerName: 'Tracking',
                sortable: false,
                flex: 0.7,
                renderCell: (params) => {
                    let disabled = false;
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.openGLSTracking(thisRow["tracking_spedizione"]);
                    };
                    disabled = params.getValue("tracking_spedizione").length === 0;
                    return <Button clicked={onClick} disabled={disabled}>TRACKING</Button>;
                }
            },
        ],
        pageSize: 5,
        confirm: false,
        expandedAccordion: false
    };

    componentDidMount() {
        if (localStorage.getItem("role") == 2) {
            this.getSpedizioniMaster()
        } else {
            this.getSpedizioni();
        }
    }

    getSpedizioni = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio"),
            data_inizio: null,
            data_fine: null
        };

        instance.post('/getListaSpedizioni', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].data_richiesta_spedizione_magcent = new Date((resp.data[i].data_richiesta_spedizione_magcent)).toLocaleDateString();
                resp.data[i].id = resp.data[i].id_spedizione_magcent
            }
            this.setState({spedizioni: resp.data})
        });
    };

    getSpedizioniMaster = () => {
        var postData = {
            id_affiliato: localStorage.getItem("idAffiliato"),
            data_inizio: null,
            data_fine: null
        };

        instance.post('/getSpedizioniMaster', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].data_richiesta_spedizione_magcent = new Date((resp.data[i].data_richiesta_spedizione_magcent)).toLocaleDateString();
                resp.data[i].id = resp.data[i].id_spedizione_magcent
            }
            this.setState({spedizioni: resp.data})
        });
    };

    openModal = (id) => {
        this.setState({visualizzaScheda: true, idSpedizioneDettaglio: id});
    };

    openDdt = (id) => {
        window.open("https://www.smartphonecash.it/stampaDdt?id=" + id);
        // window.open("http://localhost:3000/stampaDdt?id=" + id);
    };

    openGLSTracking = (tracking) => {
        let trackingUrl = "https://wwwdr.gls-italy.com/XML/get_xml_track.php?locpartenza=M6&numrit=" + tracking.substring(2) + "&CodCli=2397";
        window.open(trackingUrl);
    };

    visualizzaSchedaCancelHandler = () => {
        this.setState({visualizzaScheda: false, idSpedizioneDettaglio: null})
    };

    handleToogle() {
        let expanded = !this.state.expandedAccordion;
        if (localStorage.getItem("role") == 2) {
            this.getSpedizioniMaster()
        } else {
            this.getSpedizioni();
        }
        this.setState({expandedAccordion: expanded})
    }

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    render() {

        let storico = (
            <div style={{height: 'auto', width: '100%'}} >
                <DataGrid autoHeight={true} rows={this.state.spedizioni} columns={this.state.headers} pageSize={5} rowsPerPageOptions={[5, 10, 20, 50]} components={{Toolbar: (localStorage.getItem("role") !== 4 ? CustomToolbar : null)}}/>
            </div>
        );

        let schedaCompleta = null;
        if (this.state.visualizzaScheda) {
            schedaCompleta = (
                <Modal show={this.state.visualizzaScheda} modalClosed={this.visualizzaSchedaCancelHandler} title={"Spedizione " + this.state.idSpedizioneDettaglio} >
                    <ContenutoSpedizione id={this.state.idSpedizioneDettaglio} />
                </Modal>
            )
        }

        return (
            <Auxiliary>
                {schedaCompleta}
                <Accordion>
                    <AccordionSummary className={classes.AccordionIcon} onClick={() => this.handleToogle()} expandIcon={this.state.expandedAccordion ? <Icon image="accordion_aperto"/> : <Icon image="accordion_chiuso"/>} style={{textAlign: 'center', display: 'inline-flex'}}>
                        <h2 className={classes.Title}>STORICO SPEDIZIONI</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        {storico}
                    </AccordionDetails>
                </Accordion>
            </Auxiliary>
        );
    }

}



export default (StoricoSpedizioni);

import React, {Component} from 'react';
import Input from '../../components/UI/Input/Input'
import Button from '../../components/UI/Button/Button'
import classes from '../Auth/Auth.css'
import * as actions from '../../store/actions/index'
import {connect} from "react-redux";
import Spinner from '../../components/UI/Spinner/Spinner'
import produce from "immer";
import instance from "../../axios-spc";
import {Redirect} from 'react-router-dom';
import TextField from "../../components/UI/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Auxiliary from '../../hoc/Auxiliary/Auxiliary'


class Credenziali extends Component {

    state = {
        formOperatoreEsistente: {
            controls: {
                negozio: {
                    elementType: 'select',
                    elementConfig: {
                        options: [],
                        placeholder: 'Negozio'
                    },
                    value: '0',
                    validation: {},
                    valid: false,
                    touched: false,
                },
                operatore: {
                    elementType: 'select',
                    elementConfig: {
                        options: [],
                        placeholder: 'Operatore'
                    },
                    value: '0',
                    validation: {},
                    valid: false,
                    touched: false,
                }
            }
        },
        formNuovoOperatore: {
            controls: {
                nome: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'input',
                        placeholder: 'Nome Cognome',
                    },
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false,
                    validationError: 'Inserire nome e cognome'
                },
                negozio: {
                    elementType: 'select',
                    elementConfig: {
                        options: [],
                        placeholder: 'Negozio'
                    },
                    value: '0',
                    validation: {},
                    valid: false,
                    touched: false,
                },
            }
        },
        nuovaAnagrafica: {
            cognome: {
                elementType: 'input',
                elementConfig: {
                    type: 'input',
                    placeholder: 'Cognome',
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            nome: {
                elementType: 'input',
                elementConfig: {
                    type: 'input',
                    placeholder: 'Nome',
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            }
        },
        formOperatoreEsistenteIsValid: false,
        formNuovoOperatoreIsValid: false,
        negozi: []
    };

    componentDidMount() {

        let negozi = [];
        const id_negozio = localStorage.getItem("idNegozio");
        const negozio = localStorage.getItem("negozio");
        let operatori = [];
        const negozioObj = {
            key: id_negozio,
            citta: negozio
        };
        negozi.push(negozioObj);
        const authData = {
            id_negozio: localStorage.getItem("idNegozio"),
            id_role: localStorage.getItem("role")
        };

        instance.post('/getOperatorDummy', authData)
        // axios.post(axios.baseUrl + 'getOperatorDummy', authData, axiosConfig)
        // axios.post('http://localhost:8989/api/getOperatorDummy', authData, axiosConfig)
            .then(response => {
                if (response.data.length > 0) {
                    for (let key in response.data) {
                        operatori.push({...response.data, id: response.data[key].id_operatore})
                    }
                }

                instance.post('/getListaNegoziSys', {id_affiliato: localStorage.getItem("idAffiliato")}).then(response => {
                    // axios.post('http://localhost:8989/api/getListaNegoziSys', authData, axiosConfig).then(response => {
                    if (response.data.length > 0) {
                        if (localStorage.getItem("role") == 2 || localStorage.getItem("role") == 4) {
                            for (let key in response.data) {
                                negozi.push({...response.data, id: response.data[key].id_negozio})
                            }
                        } else {
                            let negozio = [{
                                id_affiliato: localStorage.getItem("idAffiliato"),
                                id_negozio: localStorage.getItem("idNegozio"),
                                negozio: localStorage.getItem("nomeNegozio"),
                            }];
                            negozi.push({...negozio, id: localStorage.getItem("idNegozio")})
                        }
                    }
                    const formOpEsistente = this.generaFormHelper("formOperatoreEsistente", negozi, operatori);
                    const formNuovoOp = this.generaFormHelper("formNuovoOperatore", negozi, null);
                    this.setState({formOperatoreEsistente: formOpEsistente, formNuovoOperatore: formNuovoOp, });
                }).catch(error => {
                    console.log(error);
                })

            })
            .catch(error => {
                console.log(error);
            })
    }

    generaFormHelper (formName, negozi, operatori) {
        let negoziOption = negozi
            .filter(n => {
                return n.id > 0;
            }).map((n, index) => {
                return {key: n.id, value: (index + 1), displayValue: n[index].negozio, disabled: false}
            });
        let newOptions = {options: [{key: 0, value: 0, displayValue: "Seleziona negozio", disabled: true}], placeholder: 'Negozio'};
        for (let obj in negoziOption) {
            newOptions['options'].push(negoziOption[obj]);
        }

        let newOperatori = {
            options: [{key: 0, value: 0, displayValue: "Seleziona Operatore", disabled: true}],
            placeholder: 'Operatore'
        };
        if (operatori) {
            let operatoriOption = operatori
                .filter(n => {
                    return n.id > 0;
                })
                .map((n, index) => {
                    return {key: n.id, value: (index + 1), displayValue: n[index].operatore, disabled: false}
                });
            for (let obj in operatoriOption) {
                newOperatori['options'].push(operatoriOption[obj]);
            }
        }

        return produce(this.state[formName], draft => {
            draft.controls.negozio.elementConfig = newOptions;
            if (draft.controls.operatore)
                draft.controls.operatore.elementConfig = newOperatori;
        });
    };

    checkValidity(value, rules) {
        let isValid = true;
        if (rules) {
            if (rules.required) {
                isValid = value.trim() !== '' && isValid;
            }
            if (rules.minLength) {
                isValid = value.length >= rules.minLength && isValid
            }
            if (rules.maxLength) {
                isValid = value.length <= rules.maxLength && isValid
            }
        }
        return isValid
    }

    inputChangedOpEsistenteHandler = (event, inputIdentifier, formName) => {
        const updatedOpEsistenteFormObject = this.inputChangedHelper(event, inputIdentifier, formName);
        this.setState({formOperatoreEsistente: updatedOpEsistenteFormObject.updatedForm, formOperatoreEsistenteIsValid: updatedOpEsistenteFormObject.formIsValid})
    };

    inputChangedNuovoOpHandler = (event, inputIdentifier, formName) => {
        const updatedNuovoOpFormObject = this.inputChangedHelper(event, inputIdentifier, formName);
        this.setState({formNuovoOperatore: updatedNuovoOpFormObject.updatedForm, formNuovoOperatoreIsValid: updatedNuovoOpFormObject.formIsValid})
    };

    inputChangedHelper = (event, inputIdentifier, formName) => {
        const updatedForm = {
            ...this.state[formName]
        };
        const updatedControls = {
            ...updatedForm["controls"]
        };
        const updatedFormElement = {
            ...updatedControls[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedControls[inputIdentifier] = updatedFormElement;
        updatedForm["controls"] = updatedControls;

        let formIsValid = true;
        for (let inputId in updatedForm["controls"]) {
            formIsValid = updatedForm.controls[inputId].valid && formIsValid
        }
        return {
            updatedForm,
            formIsValid
        }
    };

    submitOperatoreEsistenteHandler = (event) => {
        const nomeNegozio = this.state.formOperatoreEsistente.controls.negozio.elementConfig.options[this.state.formOperatoreEsistente.controls.negozio.value];
        const nomeOperatore = this.state.formOperatoreEsistente.controls.operatore.elementConfig.options[this.state.formOperatoreEsistente.controls.operatore.value];
        localStorage.setItem("nomeOperatore", nomeOperatore.displayValue);
        localStorage.setItem("nomeNegozio", nomeNegozio.displayValue);
        this.props.onAuth(nomeNegozio.key, nomeOperatore.key);
        this.props.history.push('/buy-device');
    };

    handleChange = (event) => {
        console.log("changed");
        const value = event.target.value;
        const oldState = {...this.state};
        const oldForm = {...oldState.nuovaAnagrafica};
        oldForm[event.target.name].value = value;
        this.setState({nuovaAnagrafica: oldForm})
    };

    submitNuovoOperatoreHandler = (event) => {
        // const nomeNegozio = this.state.formNuovoOperatore.controls.negozio.elementConfig.options[this.state.formNuovoOperatore.controls.negozio.value];
        // const nomeOperatore = this.state.formNuovoOperatore.controls.operatore.elementConfig.options[this.state.formNuovoOperatore.controls.operatore.value];
        const dummy = {
            id_operatore: 0,
            id_affiliato: localStorage.getItem("idAffiliato"),
            id_negozio: localStorage.getItem("idNegozio"),
            nome: this.state.nuovaAnagrafica.nome.value,
            cognome: this.state.nuovaAnagrafica.cognome.value,
            is_visible: 1,
            id_role: localStorage.getItem("role"),
            id_external_op: 0,
            user_name: this.state.nuovaAnagrafica.nome.value + '.' + this.state.nuovaAnagrafica.cognome.value,
            user_psw: (Math.random() + 1).toString(36).substring(7),
            is_active: 1,
            is_blocked: 0,
            is_dummy: 1,
        };
        instance.post('/setUser', dummy)
            .then(response => {
                if (response.data.length > 0) {
                    localStorage.setItem("nomeOperatore", this.state.nuovaAnagrafica.nome.value);
                    // localStorage.setItem("nomeNegozio", this.state.formNuovoOperatore.controls.nome.value);
                    this.props.onAuth(this.state.formNuovoOperatore.controls.negozio.value, response.data[0].ret_code);
                    this.props.history.push('/buy-device');
                }
            })
            .catch(error => {
                console.log(error);
            });

    };


    render() {
        const formElementOperatoreEsistente = [];
        for (let key in this.state.formOperatoreEsistente.controls) {
            formElementOperatoreEsistente.push({
                id: key,
                config: this.state.formOperatoreEsistente.controls[key]
            })
        }
        const formElementArray = [];
        for (let key in this.state.nuovaAnagrafica) {
            formElementArray.push({
                id: key,
                config: this.state.nuovaAnagrafica[key]
            })
        }

        let formOperatoreEsistente = (
            <Auxiliary>
                <div>
                    <TextField
                        id="negozioFormEsistente"
                        elementType="select"
                        value={this.state.formOperatoreEsistente.controls.negozio.value}
                        label="Negozio"
                        options={this.state.formOperatoreEsistente.controls.negozio.elementConfig.options}
                        changed={(e) => this.inputChangedOpEsistenteHandler(e, "negozio", "formOperatoreEsistente")}
                    >
                    </TextField>
                </div>
                <div>

                    <TextField
                        id="operatoreFormEsistente"
                        elementType="select"
                        value={this.state.formOperatoreEsistente.controls.operatore.value}
                        label="Operatore"
                        changed={(e) => this.inputChangedOpEsistenteHandler(e, "operatore", "formOperatoreEsistente")}
                        options={this.state.formOperatoreEsistente.controls.operatore.elementConfig.options}
                    >
                    </TextField>
                </div>
            </Auxiliary>
        );

        const formElementNuovoOperatore = [];
        for (let key in this.state.formNuovoOperatore.controls) {
            formElementNuovoOperatore.push({
                id: key,
                config: this.state.formNuovoOperatore.controls[key]
            })
        }

        let formNuovoOperatore = (
            <Auxiliary>
                <div>
                    <TextField
                        id="negozioFormNuovo"
                        elementType="select"
                        value={this.state.formNuovoOperatore.controls.negozio.value}
                        label="Negozio"
                        options={this.state.formNuovoOperatore.controls.negozio.elementConfig.options}
                        changed={(e) => this.inputChangedNuovoOpHandler(e, "negozio", "formNuovoOperatore")}
                    >
                    </TextField>
                </div>
                <div>
                    {formElementArray.map(el => (
                        <TextField
                            name={el.id}
                            key={el.id}
                            label={el.config.elementConfig.placeholder}
                            variant="outlined"
                            value={el.config.value}
                            // fullWidth
                            type={el.id == "password" ? "password" : "input" }
                            style={{width: '50%', align: 'center', margin: 10 }}
                            error={!el.config.valid && el.config.touched}
                            helperText={el.config.validationError}
                            touched={el.config.touched}
                            changed={(e, id) => this.handleChange(e)}
                        />
                    ))}
                    {/*<TextField*/}
                    {/*    id="operatoreFormNuovo"*/}
                    {/*    elementType="input"*/}
                    {/*    value={this.state.formNuovoOperatore.controls.nome.value}*/}
                    {/*    label="Operatore"*/}
                    {/*    changed={(e) => this.inputChangedNuovoOpHandler(e, "nome", "formNuovoOperatore")}*/}
                    {/*>*/}
                    {/*</TextField>*/}
                </div>
            </Auxiliary>
        )

        if (this.props.loading) {
            formNuovoOperatore = <Spinner/>
        }

        let errorMessage = null;
        if (this.props.error) {
            errorMessage = (
                <p>{this.props.error.message}</p>
            )
        }

        let authRedirect = null;

        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }


        let role = localStorage.getItem("role");
        if (role == 3) {
            authRedirect = <Redirect to="/get-devices" />
        }
        if (role == 4) {
            authRedirect = <Redirect to="/get-devices" />
        }
        if (role == 1) {
            authRedirect = <Redirect to="/credentials" />
        }
        if (role == 2) {
            authRedirect = <Redirect to="/buy-device" />
        }
        if (localStorage.getItem("idAffiliato") == 364 && role < 3) {
            authRedirect = <Redirect to="buy-device"/>
        }

        return (
            <div className={classes.Auth}>
                {authRedirect}
                <h1>Credenziali</h1>
                {errorMessage}
                <form>
                    {formOperatoreEsistente}
                    <Button clicked={this.submitOperatoreEsistenteHandler} disabled={!this.state.formOperatoreEsistenteIsValid}>Accedi</Button>
                </form>
                <h5>oppure</h5>
                <form>
                    {formNuovoOperatore}
                    <Button clicked={this.submitNuovoOperatoreHandler} disabled={this.state.nuovaAnagrafica.cognome.value == ''}>Crea e Accedi</Button>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuth: state.auth.token !== null,
        operatore: state.auth.operatore,
        negozio: state.auth.negozio
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (negozio, operatore) => dispatch(actions.authSetOperatoreNegozio(negozio, operatore)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Credenziali);

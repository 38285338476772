import React, {Component} from "react";
import classes from './Fatture.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import FatturePending from "./FatturePending/FatturePending";
import FatturePagate from "./FatturePagate/FatturePagate";

class Fatture extends Component{

    componentDidMount() {
        this.props.onAuthCheckState();
    }

    render() {

        let authRedirect = null;
        console.log('[Fatture] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }

        return (
            <div className={classes.Fatture}>
                {authRedirect}
                <h5 className={classes.Title}>&nbsp;</h5>
                <FatturePending />
                <FatturePagate />
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/docs"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Fatture);

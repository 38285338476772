import React, {Component} from "react";
import classes from './Coupons.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import BurnCoupon from "../Coupons/BurnCoupon/BurnCoupon";
import Coupons from "./Coupons";

class CouponContainer extends Component{

    componentDidMount() {
        this.props.onAuthCheckState();
    }

    render() {

        let authRedirect = null;
        console.log('[Coupon] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login" />
        }

        return (
            <div className={classes.Coupon}>
                {authRedirect}
                <h5 className={classes.Title}>&nbsp;</h5>
                <BurnCoupon/>
                <Coupons />
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/coupon"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CouponContainer);

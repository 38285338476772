import React, {Component} from "react";
import classes from '../Fatture.css'
import {connect} from "react-redux";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import {Redirect} from "react-router-dom";
import * as actions from "../../../store/actions";
import queryString from "query-string";
import instance from "../../../axios-spc";

class Fattura extends Component{

    state = {
        fattura_header: null,
        fattura_body: null
    }

    componentDidMount() {
        // this.props.onAuthCheckState();
        let params = queryString.parse(this.props.location.search);
        let idFattura = params.id;
        var postData = {
            id_fattura: idFattura
        };
        instance.post('/getFatturaHeader', postData)
            .then(response => {
                this.setState({fattura_header: response.data[0]});
                instance.post('/getFatturaBody', postData).then(response =>{
                    this.setState({fattura_body: response.data});
                    this.compila(this.state.fattura_header, response.data[0]);
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    compila = (header, body) => {
        document.getElementById('dataFattura').innerHTML = this.formatDate(header.data_fattura, false);
        document.getElementById('numeroFattura').innerHTML = header.id_fattura_su_anno;
        document.getElementById('negozioRiferimento').innerHTML = header.negozio;
        document.getElementById('modalitaPagamento').innerHTML = header.modalita_pagamento;
        document.getElementById('scadenzaFattura').innerHTML = this.formatDate(header.data_fattura, true);
        document.getElementById('codiceCliente').innerHTML = header.cod_cli;
        document.getElementById('intestatario').innerHTML = header.ragione_sociale;
        document.getElementById('indirizzo').innerHTML = header.sede_legale;
        // document.getElementById('civico').innerHTML = $scope.cliente.civico;
        // document.getElementById('citta').innerHTML = $scope.cliente.comune;
        // document.getElementById('cap').innerHTML = $scope.cliente.cap;
        document.getElementById('partitaIva').innerHTML = header.partita_iva;
        // document.getElementById('codiceFiscale').innerHTML
        // document.getElementById('cellulare').innerHTML = $scope.cliente.telefono_contatto;
        // document.getElementById('mail').innerHTML = $scope.cliente.email_contatto;
        document.getElementById('codiceArticolo').innerHTML = 'N.D.';
        document.getElementById('descrizione').innerHTML = body.breve_descrizione;
        document.getElementById('quantitaArticolo').innerHTML = body.quantita;
        document.getElementById('prezzoUnitario').innerHTML = body.prezzo;
        document.getElementById('imponibile').innerHTML = header.imponibile;
        document.getElementById('iva').innerHTML = header.percentuale_iva;
        document.getElementById('note').innerHTML = header.nota;
        document.getElementById('totaleImponibile').innerHTML = header.imponibile.toFixed(2);
        document.getElementById('totaleIva').innerHTML = Math.round((header.imponibile * 0.22)*100) /100;
        document.getElementById('totaleFattura').innerHTML = (header.imponibile + (header.imponibile* 0.22)).toFixed(2);
    };

    formatDate(value, scadenza) {
        if (value) {
            let date = new Date(value);
            const day = date.toLocaleString('default', {day: '2-digit'});
            const month = date.toLocaleString('default', {month: '2-digit'});
            const year = date.toLocaleString('default', {year: 'numeric'});
            if (!scadenza) return day + '-' + month + '-' + year;
            if (scadenza) return day + '-' + (+month + 1) + '-' + year;
        }
    }

    render() {

        return (
            <Auxiliary>
                <div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
                                           id="fattura">
                                        <tr>
                                            <td>
                                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                    <tr>
                                                        <td width="50%">
                                                            <div align="center">
                                                                <table width="100%" border="0" cellSpacing="0"
                                                                       cellPadding="0">
                                                                    <tr>
                                                                        <td width="10">&nbsp;</td>
                                                                        <td height="20"><font size="1"
                                                                                              face="Arial, Helvetica, sans-serif"> Ragione
                                                                            Sociale : ECO STORE
                                                                            S.r.l.&nbsp;&nbsp;&nbsp; </font></td>
                                                                        <td width="5">&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                        <td height="20"><font size="1"
                                                                                              face="Arial, Helvetica, sans-serif"> Sede
                                                                            Legale:VIA LEGNANO 20, 20835 MUGGIO'
                                                                            (MB)</font></td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                        <td height="20"><font size="1"
                                                                                              face="Arial, Helvetica, sans-serif"> Sede
                                                                            operativa: VIA LEGNANO 20, 20835 MUGGIO'
                                                                            (MB)</font></td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                        <td height="20"><font size="1"
                                                                                              face="Arial, Helvetica, sans-serif"> Codice
                                                                            Fiscale e Partita IVA 13277380153
                                                                            Capitale Sociale: Euro 400.000,00
                                                                            interamente versato</font></td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                        <td height="20"><font size="1"
                                                                                              face="Arial, Helvetica, sans-serif">Registro
                                                                            Imprese di Milano n. 13277380153 R.E.A.
                                                                            di Milano n. 1638882</font></td>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </td>
                                                        <td valign="top">
                                                            <table width="100%" border="0" cellSpacing="0"
                                                                   cellPadding="0">
                                                                <tr>
                                                                    <td height="20">
                                                                        <div align="left">
                                                                            <font size="1"
                                                                                  face="Arial, Helvetica, sans-serif">
                                                                                Internet:
                                                                                www.ecostore.it
                                                                            </font>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td height="20">
                                                                        <div align="left">
                                                                            <font size="1"
                                                                                  face="Arial, Helvetica, sans-serif">
                                                                                Informazioni
                                                                                commerciali:
                                                                                infofranchising@ecostore.it
                                                                            </font>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td height="20">
                                                                        <div align="left"><font size="1"
                                                                                                face="Arial, Helvetica, sans-serif">Telefono
                                                                            039-3300955 Fax 039-3300956</font></div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td valign="baseline">
                                                                        <div align="right">
                                                                            <font color="#768828" size="2"
                                                                                  face="Arial, Helvetica, sans-serif">
                                                                                <strong><h2>
                                                                                    FATTURA</h2>
                                                                                </strong>
                                                                            </font>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top">
                                                <table width="100%" height="210" border="0" cellPadding="0"
                                                       cellSpacing="0">
                                                    <tr>
                                                        <td width="50%" height="210" valign="top">
                                                            <table width="100%" height="210" border="1"
                                                                   align="center" cellPadding="0" cellSpacing="0"
                                                                   bordercolor="#768828">
                                                                <tr>
                                                                    <td valign="top">
                                                                        <table width="100%" border="0"
                                                                               cellSpacing="0" cellPadding="0">
                                                                            <tr valign="top">
                                                                                <td height="30" colSpan="3">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5"
                                                                                                height="60">&nbsp;</td>
                                                                                            <td height="60"><font
                                                                                                size="3"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                <strong>
                                                                                                    <font
                                                                                                        size="2"> DATI
                                                                                                        FATTURA </font>
                                                                                                </strong> </font>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr valign="top">
                                                                                <td colSpan="3">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td height="20"><font
                                                                                                size="1"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                Data
                                                                                                Fattura: <strong>
                                                                                                    <span
                                                                                                        id="dataFattura"></span>
                                                                                            </strong> </font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr valign="top">
                                                                                <td colSpan="3">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td height="20"><font
                                                                                                size="1"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                Numero
                                                                                                Fattura: <strong>
                                                                                                    <span
                                                                                                        id="numeroFattura"></span>
                                                                                            </strong> </font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            {/*<tr>*/}
                                                                            {/*    <td colSpan="3">*/}
                                                                            {/*        <table width="100%" border="0"*/}
                                                                            {/*               cellSpacing="0"*/}
                                                                            {/*               cellPadding="0">*/}
                                                                            {/*            <tr>*/}
                                                                            {/*                <td width="5">&nbsp;</td>*/}
                                                                            {/*                <td height="20"><font*/}
                                                                            {/*                    size="1"*/}
                                                                            {/*                    face="Arial, Helvetica, sans-serif">*/}
                                                                            {/*                    Negozio Riferimento*/}
                                                                            {/*                    : <strong>*/}
                                                                            {/*                        <span*/}
                                                                            {/*                            id="negozioRiferimento"></span>*/}
                                                                            {/*                </strong> </font></td>*/}
                                                                            {/*            </tr>*/}
                                                                            {/*        </table>*/}
                                                                            {/*    </td>*/}
                                                                            {/*</tr>*/}
                                                                            <tr>
                                                                                <td colSpan="3">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td height="20"><font
                                                                                                size="1"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                Modalit &agrave; di
                                                                                                Pagamento: <strong>
                                                                                                    <span
                                                                                                        id="modalitaPagamento"></span>
                                                                                            </strong></font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="3">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td height="20"><font
                                                                                                size="1"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                Scadenza Pagamento
                                                                                                Fattura: <strong>
                                                                                                    <span
                                                                                                        id="scadenzaFattura"></span>
                                                                                            </strong> </font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="3">&nbsp;</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="3" style={{display: 'inherit'}}>
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td width="72">
                                                                                                <div align="center">
                                                                                                    <font size="2"
                                                                                                          face="Arial, Helvetica, sans-serif">
                                                                                                        <strong>NOTE</strong>
                                                                                                    </font></div>
                                                                                            </td>
                                                                                            <td height="60">
                                                                                                <table width="100%"
                                                                                                       border="0"
                                                                                                       cellSpacing="0"
                                                                                                       cellPadding="0">
                                                                                                    <tr>
                                                                                                        <td width="10">
                                                                                                            <font
                                                                                                                size="1"
                                                                                                                face="Verdana, Arial, Helvetica, sans-serif">&nbsp;</font>
                                                                                                        </td>
                                                                                                        <td><font
                                                                                                            size="1"
                                                                                                            face="Verdana, Arial, Helvetica, sans-serif">
                                                                                                            Q.ta:
                                                                                                            Quantit&agrave; </font>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td><font
                                                                                                            size="1"
                                                                                                            face="Verdana, Arial, Helvetica, sans-serif">&nbsp;</font>
                                                                                                        </td>
                                                                                                        <td><font
                                                                                                            size="1"
                                                                                                            face="Verdana, Arial, Helvetica, sans-serif">
                                                                                                            Esenzione
                                                                                                            iva:Nessuna </font>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>&nbsp;</td>
                                                                                                        <td><font
                                                                                                            size="1"
                                                                                                            face="Arial, Helvetica, sans-serif"><strong>Contributo
                                                                                                            CONAI
                                                                                                            assolto
                                                                                                            ove
                                                                                                            dovuto</strong></font>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td height="20" colSpan="3">
                                                                                    <div align="center"></div>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td width="2">&nbsp;</td>
                                                        <td width="50%" height="210" valign="top">
                                                            <table width="100%" height="250" border="1"
                                                                   align="center" cellPadding="0" cellSpacing="0"
                                                                   bordercolor="#768828">
                                                                <tr>
                                                                    <td valign="top">
                                                                        <table width="100%" border="0"
                                                                               cellSpacing="0" cellPadding="0">
                                                                            <tr valign="top">
                                                                                <td width="482" height="60"
                                                                                    colSpan="2">
                                                                                    <table width="100%" height="60"
                                                                                           border="0"
                                                                                           cellPadding="0"
                                                                                           cellSpacing="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td><font size="2"
                                                                                                      face="Arial, Helvetica, sans-serif">
                                                                                                <strong>ANAGRAFICA</strong>
                                                                                            </font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td height="20"><font
                                                                                                size="1"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                Codice
                                                                                                Cliente: <strong>
                                                                                                    <span
                                                                                                        id="codiceCliente"></span>
                                                                                            </strong> </font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td height="20"><font
                                                                                                size="1"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                Intestatario: <strong>
                                                                                                    <span
                                                                                                        id="intestatario"></span>
                                                                                            </strong> </font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="2">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td height="20"><font
                                                                                                size="1"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                Indirizzo: <strong>
                                                                                                    <span
                                                                                                        id="indirizzo"></span>
                                                                                            </strong>

                                                                                                 </font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td height="20" colSpan="2">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td height="20"><font
                                                                                                size="1"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                Partita iva /
                                                                                                C.F.: <strong>
                                                                                                    <span
                                                                                                        id="partitaIva"></span>
                                                                                            </strong> </font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            {/*<tr>*/}
                                                                            {/*    <td height="20" colSpan="2">*/}
                                                                            {/*        <table width="100%" border="0"*/}
                                                                            {/*               cellSpacing="0"*/}
                                                                            {/*               cellPadding="0">*/}
                                                                            {/*            <tr>*/}
                                                                            {/*                <td width="5">&nbsp;</td>*/}
                                                                            {/*                <td height="20"><font*/}
                                                                            {/*                    size="1"*/}
                                                                            {/*                    face="Arial, Helvetica, sans-serif">*/}
                                                                            {/*                    Tel.: <strong>*/}
                                                                            {/*                        <span*/}
                                                                            {/*                            id="telefono"></span>*/}
                                                                            {/*                </strong> Cell.: <strong>*/}
                                                                            {/*                        <span*/}
                                                                            {/*                            id="cellulare"></span>*/}
                                                                            {/*                </strong> Fax: <strong>*/}
                                                                            {/*                        <span*/}
                                                                            {/*                            id="fax"></span>*/}
                                                                            {/*                </strong> </font></td>*/}
                                                                            {/*            </tr>*/}
                                                                            {/*        </table>*/}
                                                                            {/*    </td>*/}
                                                                            {/*</tr>*/}
                                                                            {/*<tr>*/}
                                                                            {/*    <td height="20" colSpan="2">*/}
                                                                            {/*        <table width="100%" border="0"*/}
                                                                            {/*               cellSpacing="0"*/}
                                                                            {/*               cellPadding="0">*/}
                                                                            {/*            <tr>*/}
                                                                            {/*                <td width="5">&nbsp;</td>*/}
                                                                            {/*                <td height="20"><font*/}
                                                                            {/*                    size="1"*/}
                                                                            {/*                    face="Arial, Helvetica, sans-serif">*/}
                                                                            {/*                    Email: <strong>*/}
                                                                            {/*                        <span*/}
                                                                            {/*                            id="mail"></span>*/}
                                                                            {/*                </strong> </font></td>*/}
                                                                            {/*            </tr>*/}
                                                                            {/*        </table>*/}
                                                                            {/*    </td>*/}
                                                                            {/*</tr>*/}
                                                                            <tr>
                                                                                <td colSpan="3">
                                                                                    <table width="100%" border="0"
                                                                                           cellSpacing="0"
                                                                                           cellPadding="0">
                                                                                        <tr>
                                                                                            <td width="5">&nbsp;</td>
                                                                                            <td height="20"><font
                                                                                                size="1"
                                                                                                face="Arial, Helvetica, sans-serif">
                                                                                                Negozio Riferimento
                                                                                                : <strong>
                                                                                                    <span
                                                                                                        id="negozioRiferimento"></span>
                                                                                            </strong> </font></td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr><td>&nbsp;</td></tr>
                                                                            <tr><td>&nbsp;</td></tr>
                                                                            <tr><td>&nbsp;</td></tr>
                                                                            <tr><td>&nbsp;</td></tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td height="30">
                                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                    <tr>
                                                        <td height="20">&nbsp;</td>
                                                    </tr>
                                                </table>
                                                <table width="100%" border="2" align="center" cellPadding="1"
                                                       cellSpacing="0" bordercolor="#768828">
                                                    <tr>
                                                        <td>
                                                            <table width="100%" border="0" cellSpacing="0"
                                                                   cellPadding="0">
                                                                <tr valign="bottom">
                                                                    <td height="60" colSpan="7">
                                                                        <table width="100%" height="60" border="0"
                                                                               cellPadding="0" cellSpacing="0">
                                                                            <tr>
                                                                                <td width="5">&nbsp;</td>
                                                                                <td><font size="2"
                                                                                          face="Arial, Helvetica, sans-serif">
                                                                                    <strong> DESCRIZIONE
                                                                                        FATTURA </strong> </font>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div align="center"><font size="2"> <strong>
                                                                            <font
                                                                                face="Arial, Helvetica, sans-serif">
                                                                                Cod. art. </font> </strong> </font>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="2"> <strong>
                                                                            <font
                                                                                face="Arial, Helvetica, sans-serif">
                                                                                Descrizione beni </font> </strong>
                                                                        </font></div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="2"> <strong>
                                                                            <font
                                                                                face="Arial, Helvetica, sans-serif">Q.ta</font>
                                                                        </strong> </font></div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="2"> <strong>
                                                                            <font
                                                                                face="Arial, Helvetica, sans-serif">
                                                                                Prezzo unitario </font> </strong>
                                                                        </font></div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="2"> <strong>
                                                                            <font
                                                                                face="Arial, Helvetica, sans-serif">Tot.
                                                                                imponibile</font> </strong> </font>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="2"> <strong>
                                                                            <font
                                                                                face="Arial, Helvetica, sans-serif">
                                                                                Iva</font> </strong> </font></div>
                                                                    </td>
                                                                </tr>
                                                                <tr valign="middle">
                                                                    <td height="25">
                                                                        <div align="center"><font size="1"
                                                                                                  face="Arial, Helvetica, sans-serif">
                                                                        </font><span
                                                                            id="codiceArticolo"></span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="1"
                                                                                                  face="Arial, Helvetica, sans-serif">
                                                                        </font><span
                                                                            id="descrizione"></span></div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="1"
                                                                                                  face="Arial, Helvetica, sans-serif">
                                                                        </font><span
                                                                            id="quantitaArticolo"></span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="1"
                                                                                                  face="Arial, Helvetica, sans-serif">
                                                                        </font><span
                                                                                    id="prezzoUnitario"></span> &#8364;
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="1"
                                                                                                  face="Arial, Helvetica, sans-serif">
                                                                        </font><span
                                                                                    id="imponibile"></span> &#8364;
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div align="center"><font size="1"
                                                                                                  face="Arial, Helvetica, sans-serif">
                                                                        </font><span
                                                                                    id="iva"></span> %
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="7">
                                                                        <div align="center">
                                                                            <table width="98%" border="0"
                                                                                   align="center" cellPadding="0"
                                                                                   cellSpacing="0">
                                                                                <tr>
                                                                                    <td>&nbsp;</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><strong><font size="2"
                                                                                                      face="Verdana, Arial, Helvetica, sans-serif">NOTE:
                                                                                    </font></strong><font size="2"
                                                                                                          face="Verdana, Arial, Helvetica, sans-serif">
                                                                                        <strong><span id="note"></span>
                                                                                        </strong></font></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>&nbsp;</td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="7">
                                                                        <div align="center"></div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td height="20" colSpan="7">
                                                                        <table width="100%" border="0"
                                                                               cellSpacing="0" cellPadding="0">
                                                                            <tr>
                                                                                <td width="20%">&nbsp;</td>
                                                                                <td>&nbsp;</td>
                                                                                <td width="20%">&nbsp;</td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="7">&nbsp;</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="7">
                                                                        <table width="100%" border="0"
                                                                               cellSpacing="0" cellPadding="0">
                                                                            <tr>
                                                                                <td width="33%">
                                                                                    <div align="center"><font
                                                                                        size="2"
                                                                                        face="Arial, Helvetica, sans-serif">
                                                                                        Imponibile: <strong><span
                                                                                        id="totaleImponibile"></span> &#8364;
                                                                                        &nbsp; </strong> </font>
                                                                                    </div>
                                                                                </td>
                                                                                <td width="33%">
                                                                                    <div align="center"><font
                                                                                        size="2"
                                                                                        face="Arial, Helvetica, sans-serif">
                                                                                        Iva
                                                                                        % : <strong> <span
                                                                                        id="totaleIva"></span>&#8364;
                                                                                    </strong> </font></div>
                                                                                </td>
                                                                                <td colSpan="2">
                                                                                    <div align="center"><font
                                                                                        size="2"
                                                                                        face="Arial, Helvetica, sans-serif">
                                                                                        <strong> Totale
                                                                                            Fattura: <span
                                                                                                id="totaleFattura"></span> &#8364;
                                                                                        </strong> </font></div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td height="20"
                                                                                    colSpan="4">&nbsp;</td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/docs"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Fattura);

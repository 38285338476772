import React, {Component} from 'react'
import instance from "../../../../axios-spc";
import Auxiliary from "../../../../hoc/Auxiliary/Auxiliary";
import {DataGrid, GridApi, GridCellValue} from "@material-ui/data-grid";

class ContenutoSpedizione extends Component {

    state = {
        contenuto: [],
        headers: [
            {field: 'id', headerName: 'ID', flex: 1},
            {field: 'brand_name', headerName: 'Brand', flex: 1},
            {field: 'model_name', headerName: 'Model', flex: 1},
            {field: 'model_code', headerName: 'Code', flex: 1},
            {field: 'imei', headerName: 'IMEI', flex: 1},
            {field: 'valore_acquisto', headerName: 'Valore €', flex: 1},
        ],
        pageSize: 5,
    };

    componentDidMount() {
        // chiamata a picea
        var postData = {
            id_spedizione_magcent: this.props.id
        };
        instance.post('/getSpedizioniContenuto', postData).then(resp => {
        // axios.post('http://localhost:8989/api/getSpedizioniContenuto', postData, axiosConfig).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].serial_number
            }
            this.setState({contenuto: resp.data})
        });
    }

    render() {
        let contenuto = (
            <div style={{height: 'auto', width: '100%'}} >
                <DataGrid autoHeight={true} rows={this.state.contenuto} columns={this.state.headers} pageSize={5}/>
            </div>
        );
        return (
            <Auxiliary>
                {contenuto}
            </Auxiliary>
        );
    }

}

export default ContenutoSpedizione

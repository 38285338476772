import React, {Component} from "react";
import classes from './Magazzino.css'
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions";
import {
    DataGrid,
    GridApi,
    GridCellValue,
    GridToolbar,
    GridToolbarExport, GridToolbarContainer
} from "@material-ui/data-grid";
import Button from '../../components/UI/Button/Button'
import Modal from "../../components/UI/Modal/Modal";
import Device from "../Magazzino/Device/Device";
import Snackbar from "../../components/UI/Snackbar/Snackbar";
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import instance from "../../axios-spc";
import ICONS from "../../store/icons";
import {TextField, Toolbar} from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import CustomToolbar from "../../components/UI/CustomToolbar/CustomToolbar";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton";
class Magazzino extends Component {

    componentDidMount() {
        this.props.onAuthCheckState();
        if (localStorage.getItem("role") == 2) {
            this.getDevicesMaster()
        } else {
            this.getDevices();
        }
    }

    getDevicesMaster = () => {
        var postData = {
            id_color: localStorage.getItem("idAffiliato") == 0 ? null : localStorage.getItem("idAffiliato"),
            active_status: 1,
            id_negozio: null
        };

        instance.post('/getDeviceMaster', postData).then(resp => {
            // axios.post('http://localhost:8989/api/getDeviceMaster', postData, axiosConfig).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].serial_number;
                resp.data[i].data_acquisto = new Date((resp.data[i].data_acquisto)).toLocaleDateString();
            }
            this.setState({devices: resp.data, filteredDevices: resp.data})
        });
    };

    getDevices = () => {
        var postData = {
            id_negozio: localStorage.getItem("idNegozio") == 0 ? null : localStorage.getItem("idNegozio"),
            active_status: 1
        };

        instance.post('/getDevice', postData).then(resp => {
            for (let i in resp.data) {
                resp.data[i].id = resp.data[i].serial_number
                // resp.data[i].data_acquisto = new Date(this.formatDate(resp.data[i].data_acquisto));
                resp.data[i].data_acquisto = new Date((resp.data[i].data_acquisto)).toLocaleDateString();
            }
            this.setState({devices: resp.data, filteredDevices: resp.data})
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.visualizzaDocumento)
            console.log("[Magazzino.js] --> shouldComponentUpdate");
        return this.state !== nextState;
    }

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', {day: '2-digit'});
        const month = date.toLocaleString('default', {month: '2-digit'});
        const year = date.toLocaleString('default', {year: 'numeric'});
        return day + '-' + month + '-' + year;
    }

    openModalValutazione = (id) => {
        this.setState({isValutazioneDevice: true, idDeviceDettaglio: id});
        document.getElementById("ricercaImei").autofocus = false;
    };



    // state = {
    //     devices: [],
    //     bbkPackages: [],
    //     headers: [
    //         {field: 'data_acquisto', headerName: 'Data Acquisto', flex: 0.7},
    //         // {field: 'foto', headerName: 'Foto', flex: 1},
    //         {field: 'id', headerName: 'S.N.', flex: 1},
    //         // {
    //         //     field: 'device',
    //         //     headerName: 'Device',
    //         //     sortable: false,
    //         //     flex: 0.8,
    //         //     renderCell: (params) => {
    //         //         const onClick = (event) => {
    //         //             const api: GridApi = params.api;
    //         //             const fields = api.getAllColumns().map((c) => c.field);
    //         //             const thisRow: Record<string, GridCellValue> = {};
    //         //
    //         //             fields.forEach((f) => {
    //         //                 thisRow[f] = params.getValue(f);
    //         //             });
    //         //
    //         //             return <p>{params.getValue("brand_name") + " " + params.getValue("model_name")}</p>;
    //         //         };
    //         //         return <p>{params.getValue("brand_name") + " " + params.getValue("model_name")}</p>;
    //         //     }
    //         // },
    //         {field: 'brand_name', headerName: 'Brand', flex: 0.8},
    //         {field: 'model_name', headerName: 'Modello', flex: 0.9},
    //         {field: 'imei', headerName: 'Imei', flex: 1},
    //         {field: 'memory_gb', headerName: 'GB', flex: 0.5},
    //         {field: 'valore_acquisto', headerName: 'Valore', flex: 0.6},
    //         {field: 'negozio_acquisto', headerName: 'Negozio Acquisto', flex: 1},
    //         {field: 'stato_device', headerName: 'Stato', flex: 1, resizable: true},
    //         {field: 'id_stato_device', headerName: 'ID Stato', flex: 1, hide: true, disableExport: false},
    //         {field: 'report_id', headerName: 'Report ID', flex: 0.6, hide: true},
    //
    //         // {
    //         //     field: 'doc',
    //         //     headerName: 'Dettagli',
    //         //     sortable: false,
    //         //     flex: 1,
    //         //     renderCell: (params) => {
    //         //         const onClick = (event) => {
    //         //             const api: GridApi = params.api;
    //         //             const fields = api.getAllColumns().map((c) => c.field);
    //         //             const thisRow: Record<string, GridCellValue> = {};
    //         //
    //         //             fields.forEach((f) => {
    //         //                 thisRow[f] = params.getValue(f);
    //         //             });
    //         //
    //         //             return this.openModal(thisRow["id"]);
    //         //         };
    //         //
    //         //         return <Button clicked={onClick}>Apri</Button>;
    //         //     }
    //         // },
    //         {
    //             field: 'report_id',
    //             headerName: 'Report',
    //             sortable: false,
    //             flex: 0.6,
    //             disableExport: true,
    //             renderCell: (params) => {
    //                 const onClick = (event) => {
    //                     const api: GridApi = params.api;
    //                     const fields = api.getAllColumns().map((c) => c.field);
    //                     const thisRow: Record<string, GridCellValue> = {};
    //
    //                     fields.forEach((f) => {
    //                         thisRow[f] = params.getValue(f);
    //                     });
    //
    //                     return <a href={thisRow["report_id"]}>DOWNLOAD</a>;
    //                 };
    //                 return <a href={params.getValue("report_id")}><img src={ICONS.pdf} style={{
    //                     verticalAlign: 'middle',
    //                     maxWidth: '45px'
    //                 }}/></a>;
    //             }
    //         },
    //         {
    //             field: 'valore_accettazione',
    //             headerName: 'Valutazione',
    //             sortable: false,
    //             flex: 0.6,
    //             disableExport: true,
    //             renderCell: (params) => {
    //                 const onClick = (event) => {
    //                     const api: GridApi = params.api;
    //                     const fields = api.getAllColumns().map((c) => c.field);
    //                     const thisRow: Record<string, GridCellValue> = {};
    //
    //                     fields.forEach((f) => {
    //                         thisRow[f] = params.getValue(f);
    //                     });
    //                     if (params.getValue("id_stato_device") === 5 || params.getValue("id_stato_device") === 13) {
    //                         return (<Auxiliary> <a href={thisRow["report_valutazione"]}>DOWNLOAD</a> <p>{params.getValue('valore_accettazione') + " €"}</p></Auxiliary>);
    //                     } else return " ";
    //                 };
    //                 if (params.getValue("id_stato_device") === 5 || params.getValue("id_stato_device") === 13) {
    //                     return (<Auxiliary><a href={params.getValue("report_valutazione")}><img src={ICONS.pdf} style={{
    //                         verticalAlign: 'middle',
    //                         maxWidth: '45px'
    //                     }}/></a> <p>{params.getValue('valore_accettazione') + " €"}</p></Auxiliary>);
    //                 } else return " ";
    //             }
    //         },
    //         {
    //             field: 'bb',
    //             headerName: 'Azione',
    //             sortable: false,
    //             flex: 1,
    //             hide: localStorage.getItem("role") < 3,
    //             renderCell: (params) => {
    //                 let disabled = false;
    //                 let disabledValutazione = false;
    //                 const onClick = (event) => {
    //                     const api: GridApi = params.api;
    //                     const fields = api.getAllColumns().map((c) => c.field);
    //                     const thisRow: Record<string, GridCellValue> = {};
    //
    //                     fields.forEach((f) => {
    //                         thisRow[f] = params.getValue(f);
    //                     });
    //
    //                     return this.setDeviceArrivato(thisRow["id"]);
    //                 };
    //                 const onValutazione = (event) => {
    //                     const api: GridApi = params.api;
    //                     const fields = api.getAllColumns().map((c) => c.field);
    //                     const thisRow: Record<string, GridCellValue> = {};
    //
    //                     fields.forEach((f) => {
    //                         thisRow[f] = params.getValue(f);
    //                     });
    //
    //                     // return this.valutaDevice(thisRow["id"]);
    //                     return this.openModalValutazione(thisRow["id"]);
    //                 };
    //                 const onVenditaEk = (event) => {
    //                     const api: GridApi = params.api;
    //                     const fields = api.getAllColumns().map((c) => c.field);
    //                     const thisRow: Record<string, GridCellValue> = {};
    //
    //                     fields.forEach((f) => {
    //                         thisRow[f] = params.getValue(f);
    //                     });
    //
    //                     return this.onEkventi(thisRow["id"]);
    //                 };
    //                 disabled = params.getValue("id_stato_device") !== 3;
    //                 disabledValutazione = params.getValue("id_stato_device") !== 4;
    //                 if (params.getValue("id_stato_device") <= 3 && localStorage.getItem("role") >= 3) {
    //                     return <Button clicked={onClick} disabled={disabled}>Arrivato</Button>;
    //                 } else if (params.getValue("id_stato_device") == 5 && localStorage.getItem("role") >= 3) {
    //                     return <Button clicked={onVenditaEk}>Ekventi</Button>;
    //                 } else if (localStorage.getItem("role") >= 3) {
    //                     return <Button clicked={onValutazione} disabled={disabledValutazione}>Valuta</Button>;
    //                 }
    //
    //             }
    //         },
    //     ],
    //     pageSize: 5,
    //     idDeviceDettaglio: null,
    //     visualizzaDocumento: false,
    //     confirmBuyBack: false,
    //     isRichiestaBuyBack: false,
    //     isValutazioneDevice: false,
    //     valoreDevice: 0,
    //     noteDevice: null,
    //     confirm: false,
    //     confirmValutazione: false,
    //     image: null,
    //     reportValutazione: null
    //
    // };

    state = {
        devices: [],
        bbkPackages: [],
        headers: [
            {field: 'data_acquisto', headerName: 'Data Acquisto', width: 100},
            // {field: 'foto', headerName: 'Foto', flex: 1},
            {field: 'id', headerName: 'S.N.', width: 130},
            // {
            //     field: 'device',
            //     headerName: 'Device',
            //     sortable: false,
            //     flex: 0.8,
            //     renderCell: (params) => {
            //         const onClick = (event) => {
            //             const api: GridApi = params.api;
            //             const fields = api.getAllColumns().map((c) => c.field);
            //             const thisRow: Record<string, GridCellValue> = {};
            //
            //             fields.forEach((f) => {
            //                 thisRow[f] = params.getValue(f);
            //             });
            //
            //             return <p>{params.getValue("brand_name") + " " + params.getValue("model_name")}</p>;
            //         };
            //         return <p>{params.getValue("brand_name") + " " + params.getValue("model_name")}</p>;
            //     }
            // },
            {field: 'brand_name', headerName: 'Brand', width: 80},
            {field: 'model_name', headerName: 'Modello', width: 160},
            {field: 'imei', headerName: 'Imei', width: 120},
            {field: 'memory_gb', headerName: 'GB', width: 60},
            {field: 'valore_acquisto', headerName: 'Valore', width: 80},
            {field: 'negozio_acquisto', headerName: 'Negozio Acquisto', width: 180},
            {field: 'stato_device', headerName: 'Stato', resizable: true, width: 110},
            {field: 'id_stato_device', headerName: 'ID Stato', hide: true, disableExport: false},
            {field: 'report_id', headerName: 'Report ID', hide: true},


            {
                field: 'report_id',
                headerName: 'Report',
                sortable: false,
                disableExport: true,
                width: 80,
                renderCell: (params) => {
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return <a href={thisRow["report_id"]}>DOWNLOAD</a>;
                    };
                    return <a href={params.getValue("report_id")}><img src={ICONS.pdf} style={{
                        verticalAlign: 'middle',
                        maxWidth: '45px'
                    }}/></a>;
                }
            },
            {
                field: 'valore_accettazione',
                headerName: 'Valutazione',
                sortable: false,
                disableExport: true,
                width: 90,
                renderCell: (params) => {
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });
                        if (params.getValue("id_stato_device") === 5 || params.getValue("id_stato_device") === 13) {
                            return (<Auxiliary> <a href={thisRow["report_valutazione"]}>DOWNLOAD</a> <p>{params.getValue('valore_accettazione') + " €"}</p></Auxiliary>);
                        } else return " ";
                    };
                    if (params.getValue("id_stato_device") === 5 || params.getValue("id_stato_device") === 13) {
                        return (<Auxiliary><a href={params.getValue("report_valutazione")}><img src={ICONS.pdf} style={{
                            verticalAlign: 'middle',
                            maxWidth: '45px'
                        }}/></a> <p>{params.getValue('valore_accettazione') + " €"}</p></Auxiliary>);
                    } else return " ";
                }
            },
            {
                field: 'doc',
                headerName: 'Dettagli',
                sortable: false,
                flex: 1,
                hide: localStorage.getItem("role") >= 3,
                renderCell: (params) => {
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.openModal(thisRow["id"]);
                    };

                    return <Button clicked={onClick}>Apri</Button>;
                }
            },
            {
                field: 'bb',
                headerName: 'Azione',
                sortable: false,
                hide: localStorage.getItem("role") < 3,
                renderCell: (params) => {
                    let disabled = false;
                    let disabledValutazione = false;
                    const onClick = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.setDeviceArrivato(thisRow["id"]);
                    };
                    const onValutazione = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        // return this.valutaDevice(thisRow["id"]);
                        return this.openModalValutazione(thisRow["id"]);
                    };
                    const onLiquidato = (event) => {
                        const api: GridApi = params.api;
                        const fields = api.getAllColumns().map((c) => c.field);
                        const thisRow: Record<string, GridCellValue> = {};

                        fields.forEach((f) => {
                            thisRow[f] = params.getValue(f);
                        });

                        return this.liquidato(thisRow["id"]);
                    };
                    disabled = params.getValue("id_stato_device") !== 3;
                    disabledValutazione = params.getValue("id_stato_device") !== 4;
                    if (params.getValue("id_stato_device") <= 3 && localStorage.getItem("role") >= 3) {
                        return <Button clicked={onClick} disabled={disabled}>Arrivato</Button>;
                    } else if (params.getValue("id_stato_device") == 5 && localStorage.getItem("role") >= 3) {
                        return <Button clicked={onLiquidato}>Liquidato</Button>;
                    } else if (localStorage.getItem("role") >= 3) {
                        return <Button clicked={onValutazione} disabled={disabledValutazione}>Valuta</Button>;
                    }

                }
            },
        ],
        pageSize: 5,
        idDeviceDettaglio: null,
        visualizzaDocumento: false,
        confirmBuyBack: false,
        isRichiestaBuyBack: false,
        isValutazioneDevice: false,
        valoreDevice: 0,
        noteDevice: null,
        confirm: false,
        confirmValutazione: false,
        imageUno: null,
        imageDue: null,
        loadedImageUno: false,
        loadedImageDue: false,
        reportValutazione: null,
        filteredDevices: [],
        ricercaImei: ''
    };

    openModal = (id) => {
        this.setState({visualizzaDocumento: true, idDeviceDettaglio: id});
    };

    richiediBuyBack = (text) => {
        // let idx = this.state.devices.findIndex( (el) => el.id === text );
        // const updatedState = {...this.state};
        // const updatedDevices = [...updatedState.devices];
        // updatedDevices.splice(idx, 1);
        // this.setState({devices: updatedDevices, confirmBuyBack: true});
        this.setState({isRichiestaBuyBack: true, idDeviceDettaglio: text})
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({confirmBuyBack: false});
    };

    visualizzaSchedaCancelHandler = () => {
        this.setState({visualizzaDocumento: false, idDeviceDettaglio: null, isRichiestaBuyBack: false})
    };

    inputonChangeHandler = (event, id) => {
        this.setState({valoreDevice: event.target.value});
    };
    inputonChangeNoteHandler = (event, id) => {
        this.setState({noteDevice: event.target.value});
    };
    inputonChangeReportHandler = (event, id) => {
        this.setState({reportValutazione: event.target.value});
    };
    ricercaCancelHandler = () => {
        this.setState({isValutazioneDevice: false, valoreDevice: 0, noteDevice: null})
    };

    valutaDevice = (id) => {

        var postData = {
            serial_number: this.state.idDeviceDettaglio,
            id_operatore: localStorage.getItem("userId"),
            num_times: 1,
            valore_accettazione: this.state.valoreDevice,
            note_accettazione: this.state.noteDevice,
            report_valutazione: this.state.reportValutazione
        };
        instance.post('/setStatusValutato', postData).then(resp => {
            // axios.post('http://localhost:8989/api/setStatusValutato', postData, axiosConfig).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmValutazione: true});
                this.ricercaCancelHandler();
                this.getDevices();
                document.getElementById("ricercaImei").autofocus = true;
                // if (resp.data[0].stato === 13) {
                //     let data = {
                //         from: 'amministrazione@smartphonecash.it',
                //         to: 'shaba.roberto@gmail.com',
                //         // to: mailCliente,
                //         object: "Valutazione Device Differente",
                //         body: "Valutazione differente per il device " + resp.data[0].device + ". Si prega di accedere al portale e verificare la valutazione",
                //     };
                //     axios.post('https://econet-coll.ecostore.it/spc/spcBack/api/sendMail', data, axiosConfig).then(resp => {
                //         // axios.post('http://localhost:8989/api/sendMail', data, axiosConfig).then(resp => {
                //         console.log(resp);
                //     }).then(err => {
                //         console.log(err)
                //     });
                // }
            }
        });
    };
    liquidato = (id) => {
        var postData = {
            serial_number: id,
            id_operatore: localStorage.getItem("userId"),
            num_times: 1
        };
        instance.post('/setStatusLiquidato', postData).then(resp => {
            // axios.post('http://localhost:8989/api/setStatusValutato', postData, axiosConfig).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirmValutazione: true});
                this.ricercaCancelHandler();
                this.getDevices();
            }
        });
    };

    handleFileInputOne = (e) => {
        let that = this;
        if (e.target.files[0]) {
            const formData = new FormData();
            //FILE INFO NAME WILL BE "my-image-file"
            formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onload = function () {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(e.target.files[0]);
            }).then(function (result) {
                that.setState({imageUno: result})
            });
        }
    };

    handleFileInputTwo = (e) => {
        let that = this;
        if (e.target.files[0]) {
            const formData = new FormData();
            //FILE INFO NAME WILL BE "my-image-file"
            formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onload = function () {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(e.target.files[0]);
            }).then(function (result) {
                that.setState({imageDue: result})
            });
        }
    };

    handleClick = (id) => {
        console.log(this.state.imageUno);
        // this.setState({firmaUno: fileFirma});
        let payloadImg = id === 1 ? this.state.imageUno : this.state.imageDue;
        let nomeFile =  id === 1 ? "reclamo_uno_" + this.state.idDeviceDettaglio : "reclamo_due_" + this.state.idDeviceDettaglio;
        let postData = {
            image: payloadImg,
            fileName: nomeFile
        } ;
        instance.post('/uploadFile', postData)
        // axios.post('http://localhost:8989/api/uploadFile', postData, axiosConfig)
            .then(response => {
                console.log("ok");
                if (id === 1) this.setState({loadedImageUno: true});
                if (id === 2) this.setState({loadedImageDue: true});
            })
            .catch(error => {
                console.log(error);
            });
    };

    setDeviceArrivato = (id) => {
        var postData = {
            serial_number: id,
            id_operatore: localStorage.getItem("userId"),
            num_times: 1
        };
        instance.post('/setStatusArrivato', postData).then(resp => {
            // axios.post('http://localhost:8989/api/setStatusArrivato', postData, axiosConfig).then(resp => {
            if (resp.data[0].ret_code === 0) {
                this.setState({confirm: true});
                this.getDevices();
            }
        });
    };

    filterArray = (e) => {
        this.setState({ricercaImei: e.target.value});

        let allDevices = [...this.state.devices];
        if (e.target.value.length > 0) {
            let filtered = allDevices.filter(item => {
                return e.target.value.length > 0 ? item.imei.indexOf(e.target.value) > -1 : item;
            });
            this.setState({filteredDevices: filtered})
        } else {
            this.setState({filteredDevices: allDevices})
        }
        document.getElementById("ricercaImei").focus();
    };


    render() {
        let modal = null;
        let confirmAlert = (
            <Snackbar open={this.state.confirmBuyBack} closed={this.handleClose} severity="success">Buyback effettuato
                correttamente!</Snackbar>
        );

        let authRedirect = null;
        console.log('[Magazzino] isAuth? ' + this.props.isAuth);
        if (!this.props.isAuth) {
            authRedirect = <Redirect to="/login"/>
        }

        let docCompleto = null;
        if (this.state.visualizzaDocumento) {
            docCompleto = (
                <Modal show={this.state.visualizzaDocumento} modalClosed={this.visualizzaSchedaCancelHandler}
                       title={"Device " + this.state.idDeviceDettaglio}>
                    <Device id={this.state.idDeviceDettaglio}/>
                </Modal>
            )
        }

        let loadForm1 = (<p>Prima immagine di reclamo caricata</p>);
        let loadForm2 = (<p>Seconda immagine di reclamo caricata</p>);
        if (!this.state.loadedImageUno) {
            loadForm1 = (
                <div>
                    <Button clicked={() => this.handleClick(1)}>Carica</Button>
                    <input type="file" onChange={this.handleFileInputOne}/>
                </div>
            )
        }
        if (!this.state.loadedImageDue) {
            loadForm2 = (
                <div>
                    <Button clicked={() => this.handleClick(2)}>Carica</Button>
                    <input type="file" onChange={this.handleFileInputOne}/>
                </div>
            )
        }

        if (this.state.isValutazioneDevice) {

            modal = (
                <Modal show={this.state.isValutazioneDevice} modalClosed={this.ricercaCancelHandler}
                       title={"Valutazione Device"}>
                    <TextField
                        id="valutazione_device"
                        label="Inserisci valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        value={this.state.valoreDevice}
                        onChange={(e) => this.inputonChangeHandler(e, "valoreDevice")}
                    />
                    <TextField
                        id="report_valutazione_device"
                        label="Inserisci Report valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        fullWidth
                        value={this.state.reportValutazione}
                        onChange={(e) => this.inputonChangeReportHandler(e, "reportValutazione")}
                    />
                    <TextField
                        id="note_valutazione_device"
                        label="Inserisci Note valutazione"
                        variant="outlined"
                        style={{margin: 10}}
                        fullWidth
                        value={this.state.noteDevice}
                        onChange={(e) => this.inputonChangeNoteHandler(e, "noteDevice")}
                    />
                    <br/>
                    <div style={{columns: 2}}>
                        {loadForm1}
                        {loadForm2}
                    </div>
                    <Button clicked={this.valutaDevice}>Valuta Device</Button>
                </Modal>
            )
        }

        const NewToolbar = () => {
            return (
                <React.Fragment>
                    <GridToolbar style={{display: "inline-block", float: "left"}}/>
                    <input  id="ricercaImei" type="text" onChange={this.filterArray} style={{float: 'right', margin: 10, display: 'inline-block' }} value={this.state.ricercaImei} placeholder="Ricerca IMEI..."/>
                </React.Fragment>
            );
        };

        let modaleAcquisto = null;
        if (this.state.isRichiestaBuyBack) {
            modaleAcquisto = (
                <Modal show={this.state.isRichiestaBuyBack} modalClosed={this.visualizzaSchedaCancelHandler}
                       title={"BuyBack " + this.state.idDeviceDettaglio}>
                    <Button clicked={this.eseguiBuyback}>Esegui BuyBack</Button>
                </Modal>
            )
        }

        return (
            <Auxiliary>

                {confirmAlert}
                {modal}
                <div className={classes.Magazzino}>
                    {authRedirect}
                    {docCompleto}
                    {modaleAcquisto}

                    <h1 className={classes.Title}>MAGAZZINO</h1>
                    <div style={{height: 'auto', width: '100%'}}>
                        <DataGrid sx={{overflowX: 'scroll'}} autoHeight={true} rows={this.state.filteredDevices} columns={this.state.headers} pageSize={5} rowsPerPageOptions={[5, 10, 20, 50]}
                                  hideFooterSelectedRowCount={true} components={{Toolbar:  NewToolbar}} />
                    </div>
                </div>
            </Auxiliary>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthCheckState: () => dispatch(actions.authCheckState())
    }
};


const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        authRedirectPath: "/magazzino"
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Magazzino);


